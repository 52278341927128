/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useMemo} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_fds/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {getLocationByLocationId} from './core/_requests'
import {useAuth} from '../auth/core/Auth'
import LaborScheduleActualContext from './context/LaborScheduleActualContext'
import {SchedulerDropdown} from './components/SchedulerDropdown'
import {
  filterEventsBetween,
  getAllowableValues,
  getBusinessDay,
  getHoursOfOperationOnDay,
  toTitle,
} from './helpers/generals'
import {useStore} from './store'
import WeeklySummaryContext from './context/WeeklySummaryContext'
import {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  endOfDay,
  format,
  startOfDay,
  startOfWeek,
} from 'date-fns'
import {Dictionary} from 'lodash'
import {ScheduleCompletionContainer} from './components/common/ScheduleCompletionContainer'
import {LaborSchedulerHeaderTest} from './LaborSchedulerHeaderTest'

const SchedulingEnabledWarning = () => {
  return (
    <div className='alert alert-warning bg-light-warning d-flex align-items-center p-5 mb-10'>
      <span className='svg-icon svg-icon-2hx svg-icon-warning me-5'>
        <KTSVG
          path='/media/icons/duotune/general/gen044.svg'
          className='svg-icon svg-icon-2hx svg-icon-warning me-5'
        />
      </span>

      <div className='d-flex flex-column'>
        <h5 className='mb-1'>The week you are displaying can not be scheduled at this time.</h5>
        <span>
          You can schedule upcoming 4 weeks. <br />
          OR <br />
          Schedule is awaiting approval from DM/Admin user or already approved and only admin user
          can change schedule.
        </span>
      </div>
      <button
        type='button'
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
        data-bs-dismiss='alert'
      >
        <span className='svg-icon svg-icon-2x svg-icon-light'>
          <KTSVG
            path='/media/icons/duotune/arrows/arr011.svg'
            className='svg-icon svg-icon-2hx me-5'
          />
        </span>
      </button>
    </div>
  )
}
const LaborSchedulerHeader: React.FC = () => {
  const location = useLocation()
  const weeklySummaryContext = useContext(WeeklySummaryContext)
  const {laborScheduleActual} = useContext(LaborScheduleActualContext)
  const {
    selectedLocationId,
    handleState,
    weatherForecast,
    selectedWeekStart,
    view,
    dailyLaborCost,
    selectedDate,
    events,
    allowableHoursDaily,
    selectedPosition,
    hoursOfOperations,
    availablePositions,
    resources,
    week,
    draggable,
  } = useStore()

  const {weekStartOn, weekDays, step} = week!
  const _weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
  const daysList = weekDays.map((d) => addDays(_weekStart, d))

  const getScheduleCompletionofDay = (dayDate: Date) => {
    const [dayStart, dayEnd] = getHoursOfOperationOnDay(dayDate, hoursOfOperations)
    const dayEvents = events.filter(
      (x) => getBusinessDay(x.start, hoursOfOperations).getDate() === dayDate.getDate()
    )
    const positionEvents =
      selectedPosition === 'all'
        ? dayEvents
        : dayEvents.filter((e) =>
            resources
              .filter((emp) => emp.position === selectedPosition)
              .some((emp) => emp.employee_id === e.employee_id)
          )

    const START_TIME = dayStart
    const END_TIME = addMinutes(dayEnd, -30)
    const hours = eachMinuteOfInterval(
      {
        start: START_TIME,
        end: END_TIME,
      },
      {step: 30}
    )
    const headcount_used = hours.map((h, i) => {
      const headCountsUsed = filterEventsBetween(
        positionEvents,
        h,
        addMinutes(h, step),
        undefined,
        true,
        availablePositions
      ).length
      return headCountsUsed
    })

    const allowableValues = getAllowableValues(
      allowableHoursDaily,
      dayDate,
      selectedPosition,
      hours
    )

    if (allowableValues.length !== 0) {
      const done = headcount_used.reduce((acc, currentValue, index) => {
        if (currentValue === allowableValues[index].number_of_employees) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      return [headcount_used.length, done]
    } else {
      return [0, 0]
    }
  }

  const getScheduleCompletion = () => {
    if (hoursOfOperations) {
      var totalSlots: number = 0
      var totalDone: number = 0
      var completionRates: Map<string, number> = new Map()
      if (view === 'week') {
        for (var i = 0; i < daysList.length; i++) {
          const [daySlots, dayDone] = getScheduleCompletionofDay(daysList[i])
          completionRates.set(format(daysList[i], 'EEEE'), totalDone / totalSlots || 0)
          totalSlots += daySlots
          totalDone += dayDone
        }
      } else {
        const [daySlots, dayDone] = getScheduleCompletionofDay(selectedDate)
        totalSlots += daySlots
        totalDone += dayDone
      }
      return Math.round((totalDone / totalSlots) * 100)
    }
    return 0
  }

  const weeklySummaryKey = view === 'week' ? 'week' : format(selectedDate, 'yyyy-MM-dd')
  const completionRate = getScheduleCompletion()
  var rateColor = 'danger'
  if (completionRate > 85) {
    rateColor = 'success'
  } else if (completionRate > 50) {
    rateColor = 'warning'
  }

  useEffect(() => {
    const _setLocations = async () => {
      if (!location) return
      const selectedLocation = await getLocationByLocationId(selectedLocationId)

      handleState(selectedLocation, 'selectedLocation')
    }

    _setLocations()
  }, [selectedLocationId, laborScheduleActual])
  const projectedLabor =
    weeklySummaryContext &&
    weeklySummaryContext.weekly_summary[weeklySummaryKey] &&
    weeklySummaryContext.weekly_summary[weeklySummaryKey].forecasted_value
      ? ((dailyLaborCost.get(weeklySummaryKey) || 0) /
          weeklySummaryContext.weekly_summary[weeklySummaryKey].forecasted_value) *
        100
      : 0

  const header = useMemo(() => {
    return (
      <>
        {!draggable && <SchedulingEnabledWarning />}
        <div
          className='card h-88px mb-2 mb-xl-2'
          style={{
            position: 'sticky',
            top: 'calc(var(--bs-app-header-height) + var(--bs-app-toolbar-height))',
            zIndex: 15,
          }}
        >
          <div
            className='card-body h-88px px-8 py-0'
            style={{backgroundColor: 'var(--bs-app-toolbar-base-bg-color)'}}
          >
            <div className='d-flex justify-content-between'>
              <ul className='nav nav-stretch nav-line-tabs border-transparent fs-5 fw-semibold'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (window.location.pathname === '/labor-scheduling/scheduling/overview' &&
                        'active')
                    }
                    to='/labor-scheduling/scheduling/overview'
                  >
                    Overview
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (window.location.pathname === '/labor-scheduling/scheduling/schedule' &&
                        'active')
                    }
                    to='/labor-scheduling/scheduling/schedule'
                  >
                    Schedule
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (window.location.pathname === '/labor-scheduling/scheduling/team_members' &&
                        'active')
                    }
                    to='/labor-scheduling/scheduling/team_members'
                  >
                    Team Members
                  </Link>
                </li>
              </ul>
              <div className='d-flex flex-wrap align-items-center '>
                <ScheduleCompletionContainer />

                {/* <div className='vr me-4 my-3'></div>
                <div className='me-2'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-fds-menu-trigger='click'
                    data-fds-menu-placement='bottom-end'
                    data-fds-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  <SchedulerDropdown />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }, [
    weeklySummaryContext,
    weeklySummaryKey,
    projectedLabor,
    completionRate,
    rateColor,
    window.location.pathname,
    weatherForecast,
    view,
    selectedWeekStart,
    laborScheduleActual,
  ])

  return header
}

export {LaborSchedulerHeader}
