import {useEffect, useCallback, Fragment, useMemo, useRef} from 'react'
import {
  startOfWeek,
  addDays,
  format,
  eachMinuteOfInterval,
  isSameDay,
  isBefore,
  isToday,
  setMinutes,
  setHours,
  isWithinInterval,
  isAfter,
  endOfDay,
  startOfDay,
  addMinutes,
  isEqual,
} from 'date-fns'
import TodayTypo from '../common/TodayTypo'
import EventItem from '../events/EventItem'
import {CellRenderedProps, DayHours, DefaultRecourse, ProcessedEvent} from '../../types'
import {
  calcCellHeight,
  calcMinuteHeight,
  differenceInDaysOmitTime,
  filterMultiDaySlot,
  filterTodayEvents,
  getOverTimeHours,
  getResourcedEvents,
  getTimeZonedDate,
  WeekDays,
} from '../../helpers/generals'
import {WithResources} from '../common/WithResources'
import Cell from '../common/Cell'
import TodayEvents from '../events/TodayEvents'
import {TableGrid} from '../../styles/styles'
import {MULTI_DAY_EVENT_HEIGHT} from '../../helpers/constants'
import {useStore} from '../../store'
import useSyncScroll from '../../hooks/useSyncScroll'
import {useState} from 'react'
import {alpha, Avatar, useTheme} from '@mui/material'
import {Nav, Tab} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_fds/helpers'

export interface WeekProps {
  weekDays: WeekDays[]
  weekStartOn: WeekDays
  startHour: DayHours
  endHour: DayHours
  step: number
  cellRenderer?(props: CellRenderedProps): JSX.Element
  navigation?: boolean
  disableGoToDay?: boolean
}

const Week = () => {
  const {
    week,
    selectedDate,
    height,
    events,
    handleGotoDay,
    getRemoteEvents,
    triggerLoading,
    handleState,
    resources,
    selectedPosition,
    resourceFields,
    resourceViewMode,
    fields,
    direction,
    locale,
    hourFormat,
    hoursOfOperations,
    draggable,
    timeZone,
  } = useStore()

  const {weekStartOn, weekDays, startHour, endHour, step, cellRenderer, disableGoToDay} = week!
  const _weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
  const daysList = weekDays.map((d) => addDays(_weekStart, d))
  const weekStart = startOfDay(daysList[0])
  const weekEnd = endOfDay(daysList[daysList.length - 1])

  // use integer part of startHour for hour and decimal part for minutes
  // get decimal part of startHour
  const startHourDecimal = startHour - Math.floor(startHour)
  const START_TIME = setMinutes(setHours(selectedDate, startHour), startHourDecimal * 60)
  // use integer part of endHour for hour and decimal part for minutes
  // get decimal part of endHour
  const endHourDecimal = endHour - Math.floor(endHour)
  const END_TIME = addMinutes(setMinutes(setHours(selectedDate, endHour), endHourDecimal * 60), -30)
  // if END_TIME is before START_TIME, it means that the day is over midnight
  // so we need to add 24 hours to the end time
  if (isBefore(END_TIME, START_TIME) || isEqual(END_TIME, START_TIME)) {
    END_TIME.setHours(END_TIME.getHours() + 24)
  }

  const hours = eachMinuteOfInterval(
    {
      start: START_TIME,
      end: END_TIME,
    },
    {step: step}
  )
  const CELL_HEIGHT = Math.min(25, calcCellHeight(height, hours.length))
  const MINUTE_HEIGHT = calcMinuteHeight(CELL_HEIGHT, step)
  const MULTI_SPACE = 0 //MULTI_DAY_EVENT_HEIGHT
  const hFormat = hourFormat === '12' ? 'hh:mm a' : 'HH:mm'
  const {headersRef, bodyRef} = useSyncScroll()
  const {triggerDialog, onDrop} = useStore()

  // const [cellMouseDown, setCellMouseDown] = useState<HTMLElement | null>(null)
  // const [cellMouseUp, setCellMouseUp] = useState<HTMLElement | null>(null)
  // const [selectedStartCell, setSelectedStartCell] = useState<HTMLElement | null>(null)
  // const [selectedEndCell, setSelectedEndCell] = useState<HTMLElement | null>(null)

  const cellMouseDownRef = useRef<any>(null)
  const cellMouseUpRef = useRef<any>(null)
  const selectedStartCellRef = useRef<HTMLElement | null>(null)
  const selectedEndCellRef = useRef<HTMLElement | null>(null)

  const colorCells = (cellsToColor: Array<HTMLElement>, colorValue: string) => {
    cellsToColor.forEach((cell) => {
      if (!cell.hasAttribute('disabled')) {
        cell.style.backgroundColor = colorValue
      }
    })
  }

  const calculateCellsToSelect = (
    cells: Array<HTMLElement>,
    startCell: HTMLElement,
    endCell: HTMLElement
  ) => {
    const startCellIndex = cells.indexOf(startCell)
    const endCellIndex = cells.indexOf(endCell)

    // get all cells between selectedStartCell and selectedEndCell in the same column where it needs to be incremented by 7
    const cellsToSelect = cells.filter((cell: HTMLElement, index: number) => {
      if (startCellIndex < endCellIndex) {
        return (
          index >= startCellIndex && index <= endCellIndex && (index - startCellIndex) % 7 === 0
        )
      } else {
        return (
          index <= startCellIndex && index >= endCellIndex && (index - startCellIndex) % 7 === 0
        )
      }
    })
    return cellsToSelect
  }

  // useEffect(() => {
  const colorSelectedCells = (clear?: boolean) => {
    const cells = document.querySelectorAll<HTMLElement>('.rs__cell > button')
    const cellsArray = Array.from(cells)
    if (selectedStartCellRef.current === null || clear) {
      colorCells(cellsArray, '')
      return
    }
    const endCell = selectedEndCellRef.current || selectedStartCellRef.current

    const cellsToSelect = calculateCellsToSelect(cellsArray, selectedStartCellRef.current, endCell)

    colorCells(cellsToSelect, alpha(theme.palette.secondary.main, 0.3))

    const cellsToDeselect = cellsArray.filter((cell, index) => {
      return !cellsToSelect.includes(cell) && !cell.hasAttribute('disabled')
    })
    colorCells(cellsToDeselect, '')
  } //, [selectedStartCellRef.current, selectedEndCellRef.current])

  const theme = useTheme()

  const handleMouseLeave = (e: any, props: any) => {
    if (e.button !== 0) return
  }

  const handleMouseDown = (e: any, props: any) => {
    if (e.button !== 0) return
    // setCellMouseDown(props)
    // setSelectedStartCell(e.currentTarget)
    // setSelectedEndCell(e.currentTarget)
    cellMouseDownRef.current = props
    selectedStartCellRef.current = e.currentTarget
    selectedEndCellRef.current = e.currentTarget
    colorSelectedCells()
  }

  const handleMouseUp = (e: any, props: any) => {
    if (e.button !== 0) return

    if (cellMouseDownRef.current && selectedEndCellRef.current) {
      // setCellMouseUp(props)
      cellMouseUpRef.current = props
      const cells = document.querySelectorAll<HTMLElement>('.rs__cell > button')
      const cellsArray = Array.from(cells)

      const minStart = new Date(Math.min(cellMouseDownRef.current.start, props.start))
      const maxEnd = new Date(Math.max(cellMouseDownRef.current.end, props.end))
      const cellMouseTemp = {
        start: minStart,
        end: maxEnd,
        businessDay: cellMouseDownRef.current.day,
        [cellMouseDownRef.current.resourceKey]: cellMouseDownRef.current.resourceVal,
        [cellMouseDownRef.current.positionKey]: cellMouseDownRef.current.positionVal,
      }
      // colorCells(cellsArray, '')
      cellMouseDownRef.current = null
      cellMouseUpRef.current = null

      colorSelectedCells(true)
      triggerDialog(true, cellMouseTemp)
      // setCellMouseDown(null)
      // setCellMouseUp(null)
    } else {
      // setSelectedStartCell(null)
      selectedStartCellRef.current = null
      colorSelectedCells(true)
    }
  }

  const handleMouseEnter = (e: any, props: any) => {
    if (e.button !== 0) return

    if (cellMouseDownRef.current) {
      if (
        cellMouseDownRef.current.resourceVal !== props.resourceVal ||
        cellMouseDownRef.current.day.getDate() !== props.day.getDate()
      ) {
        // setSelectedEndCell(null)
        selectedEndCellRef.current = null

        colorSelectedCells(true)
      } else {
        // setSelectedEndCell(e.currentTarget)
        selectedEndCellRef.current = e.currentTarget
        colorSelectedCells(false)
      }
    }
  }

  const multiSelectProps = {
    handleMouseDown,
    handleMouseUp,
    handleMouseEnter,
    handleMouseLeave,
  }

  const renderTable = (resource?: DefaultRecourse) => {
    let recousedEvents = events
    if (resource) {
      recousedEvents = getResourcedEvents(events, resource, resourceFields, fields)
    }

    // Equalizing multi-day section height except in resource/tabs mode
    const shouldEqualize = resources.length && resourceViewMode !== 'tabs'
    const allWeekMulti = filterMultiDaySlot(
      shouldEqualize ? events : recousedEvents,
      daysList,
      timeZone
    )
    const headerHeight = MULTI_SPACE * allWeekMulti.length + 45
    const otHours = getOverTimeHours(events)
    // get sum of values in object
    const totalOtHours = Object.values(otHours).reduce((a, b) => a + b, 0)
    // get count of the keys in object
    const totalOtEmp = Object.keys(otHours).length
    return (
      <>
        {/* Header days */}
        <TableGrid days={daysList.length} ref={headersRef} sticky='1'>
          {totalOtHours ? (
            <span className='rs__cell rs__time bg-light text-gray-900'>
              OT: {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(totalOtHours)}{' '}
              hrs <br />
              OT Emp: {totalOtEmp}
            </span>
          ) : (
            <span className='rs__cell rs__time bg-light text-gray-900'></span>
          )}

          {daysList.map((date, i) => (
            <span
              key={i}
              className={`rs__cell rs__header bg-light text-gray-900 ${
                isToday(date) ? 'rs__today_cell' : ''
              }`}
              style={{height: headerHeight}}
            >
              <TodayTypo
                date={date}
                onClick={!disableGoToDay ? handleGotoDay : undefined}
                locale={locale}
              />
              {/* {renderMultiDayEvents(recousedEvents, date)} */}
            </span>
          ))}
        </TableGrid>
        {/* Time Cells */}
        <TableGrid days={daysList.length} ref={bodyRef}>
          {hours.map((h, i) => (
            <Fragment key={i}>
              <span style={{height: CELL_HEIGHT}} className='rs__cell rs__header rs__time bg-light'>
                <span className='fs-7 m-0 text-gray-900'>{format(h, hFormat, {locale})}</span>
              </span>
              {daysList.map((date, ii) => {
                const start = new Date(`${format(date, 'yyyy/MM/dd')} ${format(h, hFormat)}`)
                const end = new Date(
                  `${format(date, 'yyyy/MM/dd')} ${format(addMinutes(h, step), hFormat)}`
                )
                // if hour of start is before the hour of the first element in hours add 24 hours to start
                if (h.getHours() < hours[0].getHours()) {
                  start.setDate(start.getDate() + 1)
                }
                // if hour of end is before the hour of the first element in hours add 24 hours to end
                if (addMinutes(h, step).getHours() < hours[0].getHours()) {
                  end.setDate(end.getDate() + 1)
                }

                const field = resourceFields.idField
                const positionField = 'position'

                return (
                  <div key={ii} className={`rs__cell ${isToday(date) ? 'rs__today_cell' : ''}`}>
                    {/* Events of each day - run once on the top hour column */}
                    {i === 0 && (
                      <TodayEvents
                        todayEvents={filterTodayEvents(recousedEvents, date, timeZone)}
                        today={date}
                        minuteHeight={MINUTE_HEIGHT}
                        startHour={startHour}
                        step={step}
                        direction={direction}
                      />
                    )}
                    <Cell
                      start={start}
                      end={end}
                      day={date}
                      hoursOfOperations={hoursOfOperations}
                      draggable={draggable || true}
                      height={CELL_HEIGHT}
                      resourceKey={field}
                      resourceVal={resource ? resource[field] : null}
                      positionKey={positionField}
                      positionVal={resource ? resource[positionField] : null}
                      cellRenderer={cellRenderer}
                      {...multiSelectProps}
                    />
                  </div>
                )
              })}
            </Fragment>
          ))}
        </TableGrid>
      </>
    )
  }
  const tableContent = useMemo(() => {
    return resources.length ? <WithResources renderChildren={renderTable} /> : renderTable()
  }, [events, resources, selectedPosition])

  return tableContent
}

export {Week}
