import {useEffect, useCallback, Fragment, useMemo, useRef} from 'react'
import {
  startOfWeek,
  addDays,
  format,
  eachMinuteOfInterval,
  isSameDay,
  isBefore,
  isToday,
  setMinutes,
  setHours,
  isWithinInterval,
  isAfter,
  endOfDay,
  startOfDay,
  addMinutes,
  isEqual,
} from 'date-fns'
import TodayTypo from '../common/TodayTypo'
import EventItem from '../events/EventItem'
import {CellRenderedProps, DayHours, DefaultRecourse, ProcessedEvent} from '../../types'
import {
  calcCellHeight,
  calcMinuteHeight,
  differenceInDaysOmitTime,
  filterMultiDaySlot,
  filterTodayEvents,
  filterTodayEventsForResource,
  getHoursOfOperationOnDay,
  getOverTimeHours,
  getResourcedEvents,
  getTimeZonedDate,
  WeekDays,
} from '../../helpers/generals'
import {WithResources} from '../common/WithResources'
import Cell from '../common/Cell'
import TodayEvents from '../events/TodayEvents'
import {TableGrid, TableGridTimeline} from '../../styles/styles'
import {MULTI_DAY_EVENT_HEIGHT} from '../../helpers/constants'
import {useStore} from '../../store'
import useSyncScroll from '../../hooks/useSyncScroll'
import {useState} from 'react'
import {alpha, Avatar, useTheme} from '@mui/material'
import {Nav, Tab} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_fds/helpers'

export interface EmployeesWeekProps {
  weekDays: WeekDays[]
  weekStartOn: WeekDays
  startHour: DayHours
  endHour: DayHours
  step: number
  cellRenderer?(props: CellRenderedProps): JSX.Element
  navigation?: boolean
  disableGoToDay?: boolean
}

const EmployeesWeek = () => {
  const {
    week,
    selectedDate,
    height,
    events,
    handleGotoDay,
    handleState,
    resources,
    resourceFields,
    fields,
    direction,
    locale,
    hourFormat,
    hoursOfOperations,
    draggable,
    timeZone,
    employeeHours,
    selectedPosition,
  } = useStore()

  const {weekStartOn, weekDays, startHour, endHour, step, cellRenderer, disableGoToDay} = week!
  const _weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
  const daysList = weekDays.map((d) => addDays(_weekStart, d))

  // use integer part of startHour for hour and decimal part for minutes
  // get decimal part of startHour
  const startHourDecimal = startHour - Math.floor(startHour)
  const START_TIME = setMinutes(setHours(selectedDate, startHour), startHourDecimal * 60)
  // use integer part of endHour for hour and decimal part for minutes
  // get decimal part of endHour
  const endHourDecimal = endHour - Math.floor(endHour)
  const END_TIME = addMinutes(setMinutes(setHours(selectedDate, endHour), endHourDecimal * 60), -30)
  // if END_TIME is before START_TIME, it means that the day is over midnight
  // so we need to add 24 hours to the end time
  if (isBefore(END_TIME, START_TIME) || isEqual(END_TIME, START_TIME)) {
    END_TIME.setHours(END_TIME.getHours() + 24)
  }

  const hours = eachMinuteOfInterval(
    {
      start: START_TIME,
      end: END_TIME,
    },
    {step: step}
  )
  const CELL_HEIGHT = calcCellHeight(height, hours.length)
  const MINUTE_HEIGHT = calcMinuteHeight(CELL_HEIGHT, step)
  const MULTI_SPACE = 0 //MULTI_DAY_EVENT_HEIGHT
  const hFormat = hourFormat === '12' ? 'hh:mm a' : 'HH:mm'
  const {headersRef, bodyRef} = useSyncScroll()

  const renderTable = (resource?: DefaultRecourse) => {
    // console.log("RENDERING EMPLOYEES WEEK'S TABLE WITH RESOURCES", resources)
    const sortedResources = resources.sort((a, b) => {
      const employeeIdA = a.employee_id
      const employeeIdB = b.employee_id
      const occurrenceA = employeeHours.get(employeeIdA) || 0
      const occurrenceB = employeeHours.get(employeeIdB) || 0

      if (occurrenceA === occurrenceB) {
        if (a.position === 'rgm' && b.position !== 'rgm') {
          return -1
        } else if (a.position !== 'rgm' && b.position === 'rgm') {
          return 1
        } else if (a.position === 'sl' && b.position !== 'sl') {
          return -1
        } else if (a.position !== 'sl' && b.position === 'sl') {
          return 1
        } else if (a.position < b.position) {
          return -1
        } else if (a.position > b.position) {
          return 1
        } else if (a.first_name < b.first_name) {
          return -1
        } else if (a.first_name > b.first_name) {
          return 1
        } else if (a.last_name < b.last_name) {
          return -1
        } else if (a.last_name > b.last_name) {
          return 1
        } else {
          return 0
        }
      }
      return occurrenceB - occurrenceA
    })
    const headerHeight = MULTI_SPACE + 45
    const otHours = getOverTimeHours(events)
    // get sum of values in object
    const totalOtHours = Object.values(otHours).reduce((a, b) => a + b, 0)
    // get count of the keys in object
    const totalOtEmp = Object.keys(otHours).length
    return (
      <>
        {/* Header days */}
        <TableGridTimeline hours={daysList.length} ref={headersRef} sticky='1'>
          {totalOtHours ? (
            <span className='rs__cell rs__time bg-light text-gray-900'>
              OT: {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(totalOtHours)}{' '}
              hrs <br />
              OT Emp: {totalOtEmp}
            </span>
          ) : (
            <span className='rs__cell rs__time bg-light text-gray-900'></span>
          )}

          {daysList.map((date, i) => (
            <span
              key={i}
              className={`rs__cell rs__header rs__time bg-light text-gray-900 ${
                isToday(date) ? 'rs__today_cell' : ''
              }`}
              style={{height: headerHeight}}
            >
              <TodayTypo
                date={date}
                onClick={!disableGoToDay ? handleGotoDay : undefined}
                locale={locale}
              />
              {/* {renderMultiDayEvents(recousedEvents, date)} */}
            </span>
          ))}
        </TableGridTimeline>
        {/* Time Cells */}
        <TableGridTimeline hours={daysList.length} ref={bodyRef}>
          {sortedResources.map((res, i) => {
            const text = res[resourceFields.textField]
            const subtext = res[resourceFields.subTextField || '']
            const avatar = res[resourceFields.avatarField || '']
            const color = res[resourceFields.colorField || '']
            const positionField = 'position'
            return (
              <Fragment key={i}>
                <span
                  style={{height: CELL_HEIGHT}}
                  className='rs__cell rs__header rs__resource bg-light'
                >
                  <span
                    className='fs-7 m-0 text-gray-900 rs__hover__op'
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                  >
                    <span
                      className='fs-7 m-0 text-gray-900'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          marginRight: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleState('week', 'view')
                          handleState(res[resourceFields.idField], 'selectedResource')
                        }}
                      >
                        {text.toUpperCase()}
                        {employeeHours.get(res[resourceFields.idField]) &&
                          ` (` +
                            new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(
                              employeeHours.get(res[resourceFields.idField]) || 0
                            ) +
                            `h)`}
                      </span>
                      <span
                        className='fw-bold'
                        style={{
                          marginRight: 'auto',
                          color: color,
                        }}
                      >
                        {subtext.toUpperCase()}
                      </span>
                    </span>
                  </span>
                </span>
                {daysList.map((date, ii) => {
                  const [start, end] = getHoursOfOperationOnDay(date, hoursOfOperations)

                  const field = resourceFields.idField
                  const positionField = 'position'
                  let recousedEvents = events
                  if (res) {
                    recousedEvents = getResourcedEvents(events, res, resourceFields, fields)
                  }
                  const daysEvents = filterTodayEventsForResource(
                    recousedEvents,
                    date,
                    res,
                    resourceFields,
                    fields,
                    timeZone
                  )
                  return (
                    <div
                      key={ii}
                      className={`rs__cell rs__header rs__time ${
                        isToday(date) ? 'rs__today_cell' : ''
                      }`}
                    >
                      {/* Events of each day - run once on the top hour column */}
                      {/* {daysEvents[0] && (
                        <TodayEvents
                          todayEvents={filterTodayEvents(recousedEvents, date, timeZone)}
                          today={date}
                          minuteHeight={MINUTE_HEIGHT}
                          startHour={startHour}
                          step={step}
                          direction={direction}
                          view='timeline'
                        />
                      )} */}
                      <Cell
                        start={start}
                        end={addMinutes(start, step)}
                        day={date}
                        hoursOfOperations={hoursOfOperations}
                        draggable={draggable || true}
                        height={CELL_HEIGHT}
                        resourceKey={field}
                        resourceVal={res ? res[field] : null}
                        positionKey={positionField}
                        positionVal={res ? res[positionField] : null}
                        cellRenderer={cellRenderer}
                        children={
                          <TodayEvents
                            todayEvents={daysEvents}
                            today={date}
                            minuteHeight={MINUTE_HEIGHT}
                            startHour={startHour}
                            step={step}
                            direction={direction}
                            view='employeesWeek'
                            handlePlusButton={(e: any) => {
                              e.preventDefault()
                              e.stopPropagation()
                              handleState('week', 'view')
                              handleState(res[resourceFields.idField], 'selectedResource')
                            }}
                          />
                        }
                      />
                    </div>
                  )
                })}
              </Fragment>
            )
          })}
        </TableGridTimeline>
      </>
    )
  }
  const tableContent = useMemo(() => {
    // console.log('RENDERING EMPLOYEES WEEK')
    return renderTable()
  }, [resources, selectedPosition, employeeHours])

  return tableContent
}

export {EmployeesWeek}
