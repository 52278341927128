import {useEffect} from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
import {useAuth} from './core/Auth'
import {getJwtToken, getUserByToken} from './core/_requests'

interface SingleSignOnProps {
  sso: string
}

export const SingleSignOn: React.FC<SingleSignOnProps> = ({sso}: SingleSignOnProps) => {
  const {setCurrentUser, saveAuth, currentUser, logout} = useAuth()

  useEffect(() => {
    const _getJWTToken = async () => {
      // if (currentUser === undefined && sso !== null) {
      if (sso !== null) {
        const {data: auth} = await getJwtToken(sso)
        // debugger
        if (auth !== null && auth.access_token !== null) {
          saveAuth(auth)
          const {data: user} = await getUserByToken(auth.access_token)
          setCurrentUser(user)
          window.location.replace('/labor-scheduling/scheduling/overview')
        } else {
          window.location.replace(process.env.REACT_APP_OLD_FLASK_APP_URL || '/auth/login')
        }
      } else {
        window.location.replace(process.env.REACT_APP_OLD_FLASK_APP_URL || '/auth/login')
      }
    }
    _getJWTToken()
  }, [])

  return <div>Loading...</div>
}
