import {format, isToday} from 'date-fns'

interface TodayTypoProps {
  date: Date
  onClick?(day: Date): void
  locale: Locale
}

const TodayTypo = ({date, onClick, locale}: TodayTypoProps) => {
  return (
    <div
      className={onClick ? 'rs__hover__op' : ''}
      onClick={(e) => {
        e.stopPropagation()
        if (onClick) onClick(date)
      }}
    >
      <div
        className={`fs-7 m-0 ${isToday(date) ? 'text-primary fw-bold' : 'text-gray-900'} ${
          onClick ? 'rs__hover__op' : ''
        }`}
      >
        {format(date, 'M/d', {locale})}
      </div>
      <div
        className={`fs-9 m-0 ${isToday(date) ? 'text-primary fw-bold' : 'text-gray-800'} ${
          onClick ? 'rs__hover__op' : ''
        }`}
      >
        {format(date, 'eee', {locale})}
      </div>
    </div>
  )
}

export default TodayTypo
