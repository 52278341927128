import {FC, useMemo} from 'react'
import {ScheduleCompletion} from './ScheduleCompletion'
import {useStore} from '../../store'
import {
  filterEventsBetween,
  getAllowableValues,
  getBusinessDay,
  getHoursOfOperationOnDay,
} from '../../helpers/generals'
import {addDays, addMinutes, eachMinuteOfInterval, format, startOfWeek} from 'date-fns'
import {useLocation, useNavigate} from 'react-router-dom'
import {string} from 'yup'

const ScheduleCompletionContainer: FC = () => {
  const {
    week,
    events,
    handleState,
    handleMultipleStates,
    resources,
    selectedDate,
    selectedPosition,
    availablePositions,
    hoursOfOperations,
    allowableHoursDaily,
    view,
  } = useStore()

  const navigate = useNavigate()
  const location = useLocation()
  const {weekStartOn, weekDays, step} = week!
  const _weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
  const daysList = weekDays.map((d) => addDays(_weekStart, d))

  const getScheduleCompletionofDay = (dayDate: Date): [number, number, boolean] => {
    const [dayStart, dayEnd] = getHoursOfOperationOnDay(dayDate, hoursOfOperations)
    const dayEvents = events.filter(
      (x) => getBusinessDay(x.start, hoursOfOperations).getDate() === dayDate.getDate()
    )
    const positionEvents =
      selectedPosition === 'all'
        ? dayEvents
        : dayEvents.filter((e) =>
            resources
              .filter((emp) => emp.position === selectedPosition)
              .some((emp) => emp.employee_id === e.employee_id)
          )

    const START_TIME = dayStart
    const END_TIME = addMinutes(dayEnd, -30)
    const hours = eachMinuteOfInterval(
      {
        start: START_TIME,
        end: END_TIME,
      },
      {step: 30}
    )

    const allowableValues = getAllowableValues(
      allowableHoursDaily,
      dayDate,
      selectedPosition,
      hours
    )

    const headcount_used = hours.map((h, i) => {
      const headCountsUsed = filterEventsBetween(
        positionEvents,
        h,
        addMinutes(h, step),
        undefined,
        true,
        availablePositions
      ).length

      if (allowableValues.length === 0) {
        return {headCountsUsed, tempStriclyWrongSchedule: false}
      }

      const maxHeadCounts = allowableValues[i].number_of_employees
      const minHeadCounts = allowableValues[i].number_of_employees
      var tempStriclyWrongSchedule = false
      if (
        (headCountsUsed > maxHeadCounts || headCountsUsed < minHeadCounts) &&
        allowableValues[i].is_strict
      ) {
        tempStriclyWrongSchedule = true
      }
      return {headCountsUsed, tempStriclyWrongSchedule}
    })

    if (allowableValues.length !== 0) {
      const done = headcount_used.reduce((acc, currentValue, index) => {
        if (currentValue.headCountsUsed === allowableValues[index].number_of_employees) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      return [headcount_used.length, done, headcount_used.some((x) => x.tempStriclyWrongSchedule)]
    } else {
      return [0, 0, false]
    }
  }

  const getScheduleCompletion = (): {completionRates: any[]; strictlyUnacceptables: boolean[]} => {
    var completionRates: any[] = []
    var strictlyUnacceptables: boolean[] = []
    if (hoursOfOperations) {
      var totalSlots: number = 0
      var totalDone: number = 0
      for (var i = 0; i < daysList.length; i++) {
        const [daySlots, dayDone, dayStrictlyUnacceptable] = getScheduleCompletionofDay(daysList[i])
        completionRates.push({
          dayDate: daysList[i],
          daySlots: daySlots,
          dayDone: dayDone,
          completion: Math.round((dayDone / daySlots) * 100) || 0,
        })
        strictlyUnacceptables.push(dayStrictlyUnacceptable)
        totalSlots += daySlots
        totalDone += dayDone
      }

      return {completionRates, strictlyUnacceptables}
    } else {
      for (var i = 0; i < daysList.length; i++) {
        completionRates.push({
          dayDate: daysList[i],
          daySlots: 0,
          dayDone: 0,
          completion: 0,
        })
        strictlyUnacceptables.push(false)
      }
    }
    return {completionRates, strictlyUnacceptables}
  }

  const completions: {completionRates: any[]; strictlyUnacceptables: boolean[]} = useMemo(() => {
    // console.log('events.length', events.length)
    return getScheduleCompletion() || {completionRates: [], strictlyUnacceptables: []}
  }, [events, hoursOfOperations])

  const weekCompletion =
    Math.round(
      (completions.completionRates.reduce((acc, currentValue) => {
        return acc + currentValue.dayDone
      }, 0) /
        completions.completionRates.reduce((acc, currentValue) => {
          return acc + currentValue.daySlots
        }, 0)) *
        100
    ) || 0

  const handleGaugeClick = (dayDate: Date) => {
    // handleState(dayDate, 'selectedDate')
    // handleState('timeline', 'view')
    const states = new Map<string, any>([
      ['selectedDate', dayDate],
      ['view', 'timeline'],
    ])
    handleMultipleStates(states)
    if (window.location.pathname !== '/labor-scheduling/scheduling/schedule') {
      navigate('/labor-scheduling/scheduling/schedule')
    }
  }

  const handleWeekClick = () => {
    if (selectedDate !== _weekStart || view !== 'week') {
      // handleState(_weekStart, 'selectedDate')
      // handleState('employeesWeek', 'view')

      const states = new Map<string, any>([
        ['selectedDate', _weekStart],
        ['view', 'employeesWeek'],
      ])
      handleMultipleStates(states)
    }
    if (window.location.pathname !== '/labor-scheduling/scheduling/schedule') {
      navigate('/labor-scheduling/scheduling/schedule')
    }
  }
  const wrapper = useMemo(() => {
    // console.log('RENDERING CONTAINERRRRRRRRRRRRR')
    // console.log('hoursOfOperations', hoursOfOperations)
    // console.log('daysList', daysList)

    return (
      <div className='d-flex flex-wrap align-items-center'>
        {completions.completionRates.map((c, i) => {
          return (
            <a
              key={format(c.dayDate, 'EE')}
              onClick={(e) => handleGaugeClick(c.dayDate)}
              style={{cursor: 'pointer'}}
            >
              <ScheduleCompletion
                chartKey={format(c.dayDate, 'EE')}
                day={format(c.dayDate, 'EE')}
                completion={c.completion}
                strictlyUnacceptable={completions.strictlyUnacceptables[i]}
                currentDay={
                  view === 'timeline' && format(c.dayDate, 'EE') === format(selectedDate, 'EE')
                }
              />
            </a>
          )
        })}

        <div className='vr mx-4 my-3'></div>
        <a key='week' onClick={(e) => handleWeekClick()} style={{cursor: 'pointer'}}>
          <ScheduleCompletion
            chartKey='week'
            day='Week'
            strictlyUnacceptable={false}
            completion={weekCompletion}
            currentDay={view === 'employeesWeek'}
          />
        </a>
      </div>
    )
  }, [view, selectedDate, completions])
  return wrapper
}

export {ScheduleCompletionContainer}
