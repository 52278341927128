import {ProcessedEvent} from '../../types'
import EventItem from './EventItem'
import {differenceInMinutes, setHours, isToday} from 'date-fns'
import {traversCrossingEvents} from '../../helpers/generals'
import {BORDER_HEIGHT} from '../../helpers/constants'
import {Fragment} from 'react'
import CurrentTimeBar from './CurrentTimeBar'
import {View} from '../nav/Navigation'

interface TodayEventsProps {
  todayEvents: ProcessedEvent[]
  today: Date
  startHour: number
  step: number
  minuteHeight: number
  height?: number
  direction: 'rtl' | 'ltr'
  view?: View
  handlePlusButton?: any
}
const TodayEvents = ({
  todayEvents,
  today,
  startHour,
  view,
  height,
  step,
  minuteHeight,
  direction,
  handlePlusButton,
}: TodayEventsProps) => {
  const crossingIds: Array<number | string> = []
  if (view === 'timeline') {
    return (
      <Fragment>
        {/* {isToday(today) && (
          <CurrentTimeBar
            today={today}
            startHour={startHour}
            step={step}
            minuteHeight={minuteHeight}
          />
        )} */}

        {todayEvents.map((event) => {
          const width = differenceInMinutes(event.end, event.start) * minuteHeight
          const minituesFromTop = differenceInMinutes(event.start, setHours(today, startHour))
          const topSpace = minituesFromTop * minuteHeight
          /**
           * Add border height since grid has a 1px border
           */
          const slotsFromTop = minituesFromTop / step

          const borderFactor = slotsFromTop + BORDER_HEIGHT
          const top = topSpace //+ borderFactor
          if (top < 0) {
            return null
          }

          const crossingEvents = traversCrossingEvents(todayEvents, event)
          const alreadyRendered = crossingEvents.filter((e) => crossingIds.includes(e.event_id))
          crossingIds.push(event.event_id)
          return (
            <div
              key={event.event_id}
              className='rs__event__item mx-auto my-auto'
              style={{
                width: width,
                left: top,
                height: crossingEvents.length ? `${100 / (crossingEvents.length + 1)}%` : '92%', //Leave some space to click cell
              }}
            >
              <EventItem
                minuteHeight={minuteHeight}
                width={width}
                height={height}
                resize='horizontal'
                event={event}
                step={step}
              />
            </div>
          )
        })}
      </Fragment>
    )
  } else if (view === 'employeesWeek') {
    const displayedEvents = todayEvents.length <= 3 ? todayEvents : todayEvents.slice(0, 2)
    return (
      <Fragment>
        {/* {isToday(today) && (
        <CurrentTimeBar
          today={today}
          startHour={startHour}
          step={step}
          minuteHeight={minuteHeight}
        />
      )} */}

        {displayedEvents.map((event) => {
          const height = differenceInMinutes(event.end, event.start) * minuteHeight
          const minituesFromTop = differenceInMinutes(event.start, setHours(today, startHour))
          const topSpace = minituesFromTop * minuteHeight //+ headerHeight;
          /**
           * Add border height since grid has a 1px border
           */
          const slotsFromTop = minituesFromTop / step

          const borderFactor = slotsFromTop + BORDER_HEIGHT
          const top = topSpace //+ borderFactor

          if (top < 0) {
            return null
          }

          const crossingEvents = displayedEvents
          const alreadyRendered = crossingEvents.filter((e) => crossingIds.includes(e.event_id))
          crossingIds.push(event.event_id)

          return (
            <div
              key={event.event_id}
              className='rs__event__item mx-auto my-auto'
              style={{
                height: '100%',
                top: 0,
                width: crossingEvents.length
                  ? `${100 / (crossingEvents.length + (todayEvents.length > 3 ? 0.3 : 0))}%`
                  : '100%', //Leave some space to click cell
                [direction === 'rtl' ? 'right' : 'left']:
                  alreadyRendered.length > 0
                    ? `calc((95%/${displayedEvents.length + (todayEvents.length > 3 ? 0.3 : 0)})*${
                        alreadyRendered.length
                      } + 4%)`
                    : '4%',
              }}
            >
              <EventItem
                minuteHeight={minuteHeight}
                height='100%'
                width='92%'
                resize={false}
                event={event}
                step={step}
              />
            </div>
          )
        })}

        {todayEvents.length > 3 && (
          <div
            className='rs__event__item mx-auto my-auto'
            style={{
              bottom: 0,
              [direction === 'rtl' ? 'left' : 'right']: 0,
            }}
          >
            <div onClick={(e) => handlePlusButton(e)} className='symbol symbol-25px symbol-circle'>
              <span className='symbol-label fs-8 fw-bold bg-info text-inverse-info text-hover-primary'>
                +{todayEvents.length - displayedEvents.length}
              </span>
            </div>
          </div>
        )}
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        {/* {isToday(today) && (
        <CurrentTimeBar
          today={today}
          startHour={startHour}
          step={step}
          minuteHeight={minuteHeight}
        />
      )} */}

        {todayEvents.map((event) => {
          const height = differenceInMinutes(event.end, event.start) * minuteHeight
          const minituesFromTop = differenceInMinutes(event.start, setHours(today, startHour))
          const topSpace = minituesFromTop * minuteHeight //+ headerHeight;
          /**
           * Add border height since grid has a 1px border
           */
          const slotsFromTop = minituesFromTop / step

          const borderFactor = slotsFromTop + BORDER_HEIGHT
          const top = topSpace //+ borderFactor

          if (top < 0) {
            return null
          }

          const crossingEvents = traversCrossingEvents(todayEvents, event)
          const alreadyRendered = crossingEvents.filter((e) => crossingIds.includes(e.event_id))
          crossingIds.push(event.event_id)

          return (
            <div
              key={event.event_id}
              className='rs__event__item mx-auto my-auto'
              style={{
                height: height,
                top: top,
                width: crossingEvents.length ? `${100 / (crossingEvents.length + 1)}%` : '92%', //Leave some space to click cell
                [direction === 'rtl' ? 'right' : 'left']:
                  alreadyRendered.length > 0 ? `calc(92%/${alreadyRendered.length + 1})` : '4%',
              }}
            >
              <EventItem
                minuteHeight={minuteHeight}
                height={height}
                width='100%'
                resize='vertical'
                event={event}
                step={step}
              />
            </div>
          )
        })}
      </Fragment>
    )
  }
}

export default TodayEvents
