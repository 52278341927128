import {useState, useEffect, Fragment} from 'react'
import {TextField, Typography} from '@mui/material'
import {FloatingLabel, Form} from 'react-bootstrap'
import {FdsTextInput} from '../../../../../_fds/partials/form/FdsTextInput'
import {FdsSelectBox} from '../../../../../_fds/partials/form/FdsSelectBox'

interface EditorInputProps {
  variant?: 'standard' | 'filled' | 'outlined'
  label?: string
  placeholder?: string
  required?: boolean
  min?: number
  max?: number
  email?: boolean
  decimal?: boolean
  disabled?: boolean
  multiline?: boolean
  rows?: number
  value: string
  type?: string
  name: string
  onChange(name: string, value: string, isValid: boolean): void
  touched?: boolean
}

const EditorInput = ({
  variant,
  label,
  placeholder,
  value,
  name,
  required,
  type,
  min,
  max,
  email,
  decimal,
  onChange,
  disabled,
  multiline,
  rows,
  touched,
}: EditorInputProps) => {
  const [state, setState] = useState({
    touched: false,
    valid: false,
    errorMsg: '',
  })

  useEffect(() => {
    if (touched) {
      handleChange(value)
    }
    // eslint-disable-next-line
  }, [touched])
  const handleChange = (value: string) => {
    const val = value
    let isValid = true
    let errorMsg = ''
    if (email) {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      isValid = reg.test(val) && isValid
      errorMsg = 'Invalid Email'
    }
    if (decimal) {
      const reg = /^[0-9]+(\.[0-9]*)?$/
      isValid = reg.test(val) && isValid
      errorMsg = 'Only Numbers Allowed'
    }
    if (min && `${val}`.trim().length < min) {
      isValid = false
      errorMsg = `Minimum ${min} letters`
    }
    if (max && `${val}`.trim().length > max) {
      isValid = false
      errorMsg = `Maximum ${max} letters`
    }
    if (required && `${val}`.trim().length <= 0) {
      isValid = false
      errorMsg = 'Required'
    }
    setState({touched: true, valid: isValid, errorMsg: errorMsg})
    onChange(name, val, isValid)
  }

  return (
    <Fragment>
      <FdsTextInput
        value={value}
        type={type}
        label={`${label} ${required ? '*' : ''}`}
        controlId={name}
        // name={name}
        // className='form-control form-control-solid'
        onChange={(e: any) => handleChange(e.target.value)}
        disabled={disabled}
        rows={10 || 1}
        style={{resize: 'none', width: '100%'}}
        placeholder={placeholder || `${label} ${required ? '*' : ''}`}
      />
    </Fragment>
  )
}

EditorInput.defaultProps = {
  variant: 'outlined',
}
export {EditorInput}
