import {alpha, useTheme} from '@mui/material'
import {DragEvent} from 'react'
import {useStore} from '../store'

interface Props {
  start: Date
  end: Date
  day: Date
  resourceKey: string
  resourceVal: string | number
  positionKey: string
  positionVal: string | number
}
export const useDropAttributes = ({
  start,
  end,
  day,
  resourceKey,
  resourceVal,
  positionKey,
  positionVal,
}: Props) => {
  const {triggerDialog, onDrop} = useStore()
  const theme = useTheme()
  return {
    onClick: () => {
      triggerDialog(true, {
        start,
        end,
        businessDay: day,
        [resourceKey]: resourceVal,
        [positionKey]: positionVal,
      })
    },
    onDragOver: (e: DragEvent<HTMLButtonElement>) => {
      e.currentTarget.style.backgroundColor = alpha(theme.palette.secondary.main, 0.3)
      e.preventDefault()
    },
    onDragEnter: (e: DragEvent<HTMLButtonElement>) => {
      e.currentTarget.style.backgroundColor = alpha(theme.palette.secondary.main, 0.3)
    },
    onDragLeave: (e: DragEvent<HTMLButtonElement>) => {
      e.currentTarget.style.backgroundColor = ''
    },
    onDrop: (e: DragEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.currentTarget.style.backgroundColor = ''
      const eventId = e.dataTransfer.getData('text')
      onDrop(eventId, start, day, resourceKey, resourceVal)
    },
    [resourceKey]: resourceVal,
    [positionKey]: positionVal,
  }
}
