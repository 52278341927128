/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_fds/helpers'
import {CardTeamMember} from './CardTeamMember'
import {BaseEmployeeModel} from '../core/_models'
import {convertBaseEmployeesToResources, getPositionColor, toTitleCase} from '../helpers/generals'
import {useStore} from '../store'

export function TeamMembers() {
  const [filteredEmployees, setFilteredEmployees] = useState<Array<BaseEmployeeModel>>([])

  const {
    handleState,
    employeeHours,
    availablePositions,
    selectedPosition,
    employees,
    searchString,
  } = useStore()

  useEffect(() => {
    const filteredByPositionEmployees = employees.filter((employee: BaseEmployeeModel) => {
      if (selectedPosition === 'all') {
        return true
      }
      return employee.position === selectedPosition
    })

    if (searchString === '') {
      // console.log('filteredByPositionEmployees', filteredByPositionEmployees)
      setFilteredEmployees(filteredByPositionEmployees)
      handleState(convertBaseEmployeesToResources(filteredByPositionEmployees) || [], 'resources')
      return
    }
    const filteredEmployees = filteredByPositionEmployees.filter((employee: BaseEmployeeModel) => {
      const fullName = `${employee.first_name.toLowerCase()} ${employee.last_name.toLowerCase()}`
      return fullName.toLowerCase().includes(searchString.toLowerCase())
    })
    setFilteredEmployees(filteredEmployees)
    handleState(convertBaseEmployeesToResources(filteredEmployees) || [], 'resources')
  }, [searchString, employees, selectedPosition])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-semibold my-2'>
          Team Members
          <span className='fs-6 text-gray-400 fw-bold ms-1'>
            ({searchString ? `${filteredEmployees.length}/${employees.length}` : employees.length})
          </span>
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-3 position-absolute ms-3'
            />
            <input
              type='text'
              id='fds_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Search member'
              value={searchString}
              onChange={(e) => handleState(e.target.value, 'searchString')}
            />
          </div>

          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm w-125px'
            value={selectedPosition}
            onChange={(e) => handleState(e.target.value, 'selectedPosition')}
          >
            <option key='all' value='all'>
              All
            </option>
            {availablePositions
              .filter((pos) => pos.position !== null && pos.position !== '')
              .map((pos) => (
                <option key={pos.position} value={pos.position}>
                  {toTitleCase(pos.position)}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className='row g-6 g-xl-9'>
        {filteredEmployees.map((employee) => (
          <div className='col-md-6 col-xxl-4' key={employee.id}>
            <CardTeamMember
              key={employee.id}
              employee_id={employee.id}
              default_position={employee.position}
              color={getPositionColor(employee.position)}
              firstname={employee.first_name}
              lastname={employee.last_name}
              hide_in_schedule={employee.hide_in_schedule}
              hours={employeeHours.get(employee.id) || 0}
              job={toTitleCase(employee.position)}
              pay_rate={employee.pay_amount}
            />
          </div>
        ))}
      </div>
    </>
  )
}
