import {Grow, Slide} from '@mui/material'
import {useMemo, useState} from 'react'
import {EventActions as Actions} from '../../styles/styles'
import {ProcessedEvent} from '../../types'
import {useStore} from '../../store'
import {Button} from 'react-bootstrap'
import {useAuth} from '../../../auth'

interface Props {
  event: ProcessedEvent
  onDelete(): void
  onEdit(): void
  direction: 'rtl' | 'ltr'
  editable?: boolean
  deletable?: boolean
}

const EventActions = ({event, onDelete, onEdit, direction, editable, deletable}: Props) => {
  const {translations} = useStore()
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const handleDelete = () => {
    if (!deleteConfirm) {
      return setDeleteConfirm(true)
    }
    onDelete()
  }

  const {currentUser} = useAuth()

  const canDelete = useMemo(() => {
    // Priority control to event specific deletable value
    if (
      event.start < new Date() &&
      !['Creator', 'Admin', 'DM'].includes(currentUser?.role?.name || '')
    ) {
      return false
    }

    if (typeof event.deletable !== 'undefined') {
      return event.deletable
    }
    return deletable
  }, [deletable, event.deletable])

  const canEdit = useMemo(() => {
    // Priority control to event specific deletable value
    if (
      event.start < new Date() &&
      !['Creator', 'Admin', 'DM'].includes(currentUser?.role?.name || '')
    ) {
      return false
    }

    if (typeof event.editable !== 'undefined') {
      return event.editable
    }
    return editable
  }, [editable, event.editable])

  return (
    <Actions>
      <Grow in={!deleteConfirm} exit={false} timeout={400} unmountOnExit>
        <span>
          {canEdit && (
            <span onClick={onEdit} className='btn btn-sm btn-icon fs-10'>
              <i className='bi bi-pencil-fill fs-6 text-hover-gray-700 text-gray-900'></i>
            </span>
          )}
          {canDelete && (
            <span onClick={handleDelete} className='btn btn-sm btn-icon fs-10'>
              <i className='bi bi-trash3-fill fs-6 text-hover-gray-700 text-gray-900'></i>
            </span>
          )}
        </span>
      </Grow>
      <Slide
        in={deleteConfirm}
        direction={direction === 'rtl' ? 'right' : 'left'}
        unmountOnExit
        timeout={400}
        exit={false}
      >
        <div>
          <span className='delete btn btn-sm btn-danger me-2 fs-9 fw-bold' onClick={handleDelete}>
            {translations.form.delete.toUpperCase()}
          </span>
          <span
            className='cancel btn btn-sm btn-primary me-2 fs-9'
            onClick={() => setDeleteConfirm(false)}
          >
            {translations.form.cancel.toUpperCase()}
          </span>
        </div>
      </Slide>
    </Actions>
  )
}

export default EventActions
