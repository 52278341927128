/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useContext, useRef, useMemo} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_fds/partials'
import {getCSS, getCSSVariableValue} from '../../../../../_fds/assets/ts/_utils'

interface ScheduleCompletionProps {
  chartKey: string
  completion: number
  strictlyUnacceptable: boolean
  day: string
  currentDay?: boolean
}

const ScheduleCompletion: React.FC<ScheduleCompletionProps> = ({
  chartKey,
  day,
  completion,
  currentDay,
  strictlyUnacceptable,
}: ScheduleCompletionProps) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  // console.log(day, strictlyUnacceptable)

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, day, completion, currentDay, strictlyUnacceptable])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(completion, day, currentDay, strictlyUnacceptable)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  const renderChat = () => {
    return (
      <div
        key={chartKey}
        ref={chartRef}
        id='daily-adherence-chart'
        className='px-1'
        style={{cursor: 'pointer'}}
      />
    )
  }
  const chart = useMemo(() => {
    return renderChat()
  }, [chartRef])
  return chart
}

export {ScheduleCompletion}

function getChartOptions(
  completion: number,
  day: string,
  currentDay?: boolean,
  strictlyUnacceptable?: boolean
): ApexOptions {
  const labelColor = currentDay
    ? getCSSVariableValue('--bs-primary')
    : getCSSVariableValue('--bs-gray-800')
  var fillColor = getCSSVariableValue('--bs-danger')
  if (completion > 80) {
    fillColor = getCSSVariableValue('--bs-success')
  } else if (completion > 60) {
    fillColor = getCSSVariableValue('--bs-warning')
  }
  var options: ApexOptions = {
    chart: {
      height: 110,
      type: 'radialBar',
      width: 55,
    },
    series: [completion],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 5,
          size: '40%',
        },
        track: {
          background: currentDay
            ? getCSSVariableValue('--bs-secondary-dark')
            : getCSSVariableValue('--bs-secondary'),
        },
        dataLabels: {
          name: {
            offsetY: -4,
            show: true,
            color: labelColor,
            fontSize: '9px',
          },
          value: {
            color: strictlyUnacceptable
              ? getCSSVariableValue('--bs-danger')
              : getCSSVariableValue('--bs-gray-800'),
            offsetY: -5,
            fontSize: '11px',
            show: true,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    fill: {
      colors: [fillColor],
    },
    labels: [day],
  }

  return options
}
