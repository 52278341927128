import React, {FC, Fragment, useEffect, useState} from 'react'
import {FloatingLabel, Form, FormControlProps, FormProps} from 'react-bootstrap'
import {Hint, Typeahead} from 'react-bootstrap-typeahead'
import {TypeaheadComponentProps} from 'react-bootstrap-typeahead/types/components/Typeahead'
import {BsPrefixRefForwardingComponent} from 'react-bootstrap/esm/helpers'
import {Link} from 'react-router-dom'
// import {FdsTextInput} from './FdsTextInput'
import _ from 'lodash'
import {FdsTextInput} from './FdsTextInput'

// interface FdsSelectBoxProps {
// value: any,
// onChange: (newValue: any) => void,
// options: any[],
// }

interface FdsSelectBoxProps extends TypeaheadComponentProps {
  label: string
  value: any
  controlId?: string
  required?: boolean
  onchange?: (newValue: any) => void
}

const FdsSelectBox: FC<FdsSelectBoxProps> = ({
  label,
  className,
  value,
  options,
  controlId,
  required,
  onChange,
  ...otherProps
}) => {
  const [selectedItem, setSelectedItem] = useState([])
  const [selectedItemValue, setSelectedItemValue] = useState([])

  useEffect(() => {
    if (value && options.filter((o: any) => o.value === value).length > 0) {
      const selected = options.filter((o: any) => o.value === value)
      setSelectedItem(selected as any)
      setSelectedItemValue((selected[0] as any).value)
    }
  }, [value])

  return (
    <Fragment>
      <Typeahead
        id={`${controlId || 'floatingInput'}`}
        // style={{zIndex: 100}}
        filterBy={(option: any, props) => {
          if (props.selected.length) {
            // Display all the options if there's a selection.
            return true
          }
          // Otherwise filter on some criteria.
          return option.text.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
        }}
        align='justify'
        onChange={(e) => {
          setSelectedItem(e as any)
          // setSelectedItemValue if length of e is more than 1
          if (e.length > 0) {
            setSelectedItemValue((e as any)[0].value)
            onChange?.((e as any)[0].value)
          } else {
            setSelectedItemValue(null as any)
            onChange?.(null as any)
          }
        }}
        options={options}
        className={`${className ? className : ''}`}
        placeholder={`${label} ${required ? '*' : ''}`}
        renderInput={({inputRef, referenceElementRef, inputClassName, ...inputProps}) => {
          return (
            <FdsTextInput
              label={`${label} ${required ? '*' : ''}`}
              type='text'
              style={{cursor: 'pointer'}}
              value={selectedItem ? (selectedItem as any).text : ''}
              className={`${inputClassName ? inputClassName : 'form-control '}`}
              {...inputProps}
              ref={(input: any) => {
                inputRef(input)
                referenceElementRef(input)
              }}
            />
          )
        }}
        {...otherProps}
        selected={selectedItem}
      />
    </Fragment>
  )
}

export {FdsSelectBox}
