import {useMemo, useRef, useState, useEffect, useContext} from 'react'

import {Week} from './views/Week'
import {Navigation} from '../components/nav/Navigation'
import Editor from './views/Editor'
import {Day} from './views/Day'
import {Table, Wrapper} from '../styles/styles'
import {useStore} from '../store'
import {Timeline} from './views/Timeline'
import {ChartSummary} from '../../../../_fds/partials/widgets'
import {BaseEmployeeModel} from '../core/_models'
import {KTSVG} from '../../../../_fds/helpers'
import {convertBaseEmployeesToResources, toTitleCase} from '../helpers/generals'
import {EmployeesWeek} from './views/EmployeesWeek'
// import scheduleService from '../../services/scheduleService';

const SchedulerComponent = () => {
  const {
    loading,
    view,
    events,
    dialog,
    resources,
    resourceViewMode,
    handleState,
    availablePositions,
    employees,
    selectedPosition,
    selectedDate,
    searchString,
  } = useStore()

  const containerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState<number | null>(null) // Specify the type of the state

  useEffect(() => {
    // console.log('ALOHAHOLAHAAA')
    const resizeObserver = new ResizeObserver((entries) => {
      const {width, height} = entries[0].contentRect
      setContainerWidth(width)
    })

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    // console.log('RENDERING RESOURCE FILTERING', selectedPosition, searchString)
    // filter by position
    const filteredByPositionEmployees = employees.filter((employee: BaseEmployeeModel) => {
      if (selectedPosition === 'all') {
        return true
      }
      return employee.position === selectedPosition
    })

    if (searchString === '') {
      // console.log('filteredByPositionEmployees', filteredByPositionEmployees)
      handleState(convertBaseEmployeesToResources(filteredByPositionEmployees) || [], 'resources')
      return
    }
    const filteredEmployees = filteredByPositionEmployees.filter((employee: BaseEmployeeModel) => {
      const fullName = `${employee.first_name.toLowerCase()} ${employee.last_name.toLowerCase()}`
      return fullName.toLowerCase().includes(searchString.toLowerCase())
    })
    handleState(convertBaseEmployeesToResources(filteredEmployees) || [], 'resources')
  }, [searchString, selectedPosition, employees])

  const chartSummary = useMemo(() => {
    return <ChartSummary className='mb-2' />
  }, [events])

  // const [schedules, setSchedules] = useState<any[]>([]);

  const Views = useMemo(() => {
    // console.log('RENDERING VIEWS')
    // console.log(selectedPosition, searchString)
    switch (view) {
      //   case 'month':
      //     return <Month />
      case 'employeesWeek':
        return <EmployeesWeek />
      case 'week':
        return <Week />
      // case 'day':
      //   return <Day />
      case 'timeline':
        return <Timeline containerWidth={containerWidth} />
      default:
        return ''
    }
  }, [view, selectedDate, selectedPosition, containerWidth])

  const renderContent = () => {
    // console.log('RENDERING SCHEDULER COMPONENT')
    return (
      <Wrapper dialog={dialog ? 1 : 0} data-testid='rs-wrapper' className=''>
        {loading && (
          <div className='rs__table_loading'>
            <span className='indicator-progress text-gray-900 bg-gray-200 bg-opacity-50'>
              Please wait...{' '}
              <span className='spinner-border spinner-border-lg spinner-primary align-middle ms-2'></span>
            </span>
          </div>
        )}
        {/* <Navigation /> */}

        {view === 'timeline' && chartSummary}
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <h3 className='fw-semibold my-2'>
            Schedule
            <span className='fs-6 text-gray-400 fw-bold ms-1 '>{toTitleCase(view)} view</span>
          </h3>

          <div className='d-flex my-2'>
            <div className='d-flex align-items-center position-relative me-4'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-3 position-absolute ms-3'
              />
              <input
                type='text'
                id='fds_filter_search'
                className='form-control form-control-white form-control-sm w-150px ps-9'
                value={searchString}
                onChange={(e) => {
                  handleState(e.target.value, 'searchString')
                }}
                placeholder='Search member'
              />
            </div>

            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm w-125px'
              value={selectedPosition}
              onChange={(e) => handleState(e.target.value, 'selectedPosition')}
            >
              <option key='all' value='all'>
                All
              </option>
              {availablePositions
                .filter((pos) => pos.position !== null && pos.position !== '')
                .map((pos) => (
                  <option key={pos.position} value={pos.position}>
                    {toTitleCase(pos.position)}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <Table
          ref={containerRef}
          resource_count={resourceViewMode === 'tabs' ? 1 : resources.length}
          // Temp resources/default `sticky` wontfix
          sx={{
            overflowX: resourceViewMode === 'default' && resources.length > 1 ? 'auto' : undefined,
          }}
          data-testid='grid'
        >
          {Views}
        </Table>
        {/* {dialog && <Editor />} */}
      </Wrapper>
    )
  }
  return renderContent()
}

export {SchedulerComponent}
