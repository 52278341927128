import {useEffect} from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
import {useAuth} from './core/Auth'

export function Logout() {
  const {logout} = useAuth()

  useEffect(() => {
    logout()
    window.location.replace(process.env.REACT_APP_OLD_FLASK_APP_URL + '/logout')
  }, [logout])

  return <Navigate to='/auth/login' />
}
