import React from 'react'

export interface FdsModalSettings {
  cancelButtonText: string
  saveButtonText: string
  saveButtonCallback: () => void
  closeButtonCallback?: () => void
  titleText: string
  content: React.ReactNode
  show: boolean
}

interface FdsModalContextProps {
  settings: FdsModalSettings
  setSettings: (newSettings: FdsModalSettings) => void
}

const FdsModalContext = React.createContext<FdsModalContextProps>({
  settings: {
    cancelButtonText: '',
    saveButtonText: '',
    saveButtonCallback: () => {},
    closeButtonCallback: () => {},
    titleText: '',
    content: '',
    show: false,
  },
  setSettings: () => {},
})

export const FdsModalContextProvider = FdsModalContext.Provider
export default FdsModalContext
