import {FC, useContext, useEffect, useState} from 'react'
import {
  clearLaborSchedule,
  copyFromPreviousWeek,
  getPDFContent,
  updateLaborScheduleStatus,
  uploadFromFile,
  validateSchedule,
} from '../core/_requests'
import LoadingStateContext from '../context/LoadingContext'
import {useStore} from '../store'
import {useAuth} from '../../auth'
import FdsModalContext from '../../../../_fds/layout/ModalContext'
import {useToast} from '../../../../_fds/layout/components/toast-messages'
import ReactDatePicker from 'react-datepicker'
import {LaborScheduleActualModel} from '../core/_models'
import {addDays, lastDayOfWeek} from 'date-fns'
import {ReactJSXElement} from '@emotion/react/types/jsx-namespace'

type SchedulerDropdownProps = {
  laborScheduleActual: LaborScheduleActualModel | null
  setLaborScheduleActual: (laborScheduleActual: LaborScheduleActualModel | null) => void
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export const SchedulerDropdown: FC<SchedulerDropdownProps> = ({
  laborScheduleActual,
  setLaborScheduleActual,
}) => {
  const {setLoadingState} = useContext(LoadingStateContext)
  const {
    selectedLocation,
    selectedLocationId,
    handleState,
    week,
    selectedDate,
    strictlyWrongSchedule,
  } = useStore()
  const {currentUser} = useAuth()
  const {setSettings} = useContext(FdsModalContext)
  const [rejectReason, setRejectReason] = useState<string>('')
  const {showToast} = useToast()
  const [copyFromDate, setCopyFromDate] = useState<Date>(addDays(selectedDate, -7))
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [uploadFileName, setUploadFileName] = useState('')
  const [selectedAction, setSelectedAction] = useState('0')
  const [selectedStatus, setSelectedStatus] = useState('0')
  const [statusAction, setStatusAction] = useState('0')

  const {weekStartOn} = week!
  const isAdmin = ['Admin', 'Creator'].includes(currentUser?.role?.name || '')

  // const {laborScheduleActual, setLaborScheduleActual} = useContext(LaborScheduleActualContext)

  const setLaborScheduleActualStatus = async (status: string) => {
    if (laborScheduleActual) {
      setLoadingState(true)
      try {
        await updateLaborScheduleStatus(laborScheduleActual.id, status, '')
        showToast('Schedule Updated', 'Schedule updated successfully!', 'success')
      } catch (e) {
        showToast('Error', 'Error updating schedule status!', 'danger')
      }

      setLaborScheduleActual({...laborScheduleActual, ...{workflow_status: status}})
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const rejectLaborScheduleActual = async (reason: string) => {
    if (laborScheduleActual) {
      setLoadingState(true)
      let status = 'awaiting_approval'
      if (laborScheduleActual.workflow_status === 'awaiting_approval') {
        status = 'in_progress'
      }
      try {
        await updateLaborScheduleStatus(laborScheduleActual.id, status, reason)
        showToast('Schedule Rejected', 'Schedule rejected successfully!', 'success')
      } catch (e) {
        showToast('Error', 'Error rejecting schedule status!', 'danger')
      }
      setLaborScheduleActual({...laborScheduleActual, ...{workflow_status: status}})
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const clearSchedule = async () => {
    if (laborScheduleActual) {
      setLoadingState(true)
      try {
        await clearLaborSchedule(selectedLocationId, laborScheduleActual?.week_start)
        showToast('Schedule Cleared', 'Schedule cleared successfully!', 'success')
      } catch (e) {
        showToast('Error', 'Error clearing schedule!', 'danger')
      }
      handleState([], 'events')
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const getPDF = async () => {
    if (laborScheduleActual) {
      setLoadingState(true)
      // get pdf content from api and download
      try {
        await getPDFContent(selectedLocationId, laborScheduleActual?.week_start)
        showToast('PDF Downloaded', 'PDF downloaded successfully!', 'success')
      } catch (e) {
        showToast('Error', 'Error downloading pdf!', 'danger')
      }
      // download pdf content
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const copyFromPreviousWeeks = async () => {
    if (laborScheduleActual && selectedLocation) {
      setLoadingState(true)
      try {
        await copyFromPreviousWeek(
          selectedLocationId,
          laborScheduleActual?.week_start,
          copyFromDate
        ).then((res) => {
          const currentLocationId = selectedLocationId
          handleState(null, 'selectedLaborScheduleId')
        })

        showToast('Schedule Copied', 'Schedule copied successfully!', 'success')
      } catch (e) {
        showToast('Error', 'Error copying schedule!', 'danger')
      }
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const validateCurrentSchedule = async () => {
    if (laborScheduleActual && selectedLocation) {
      setLoadingState(true)
      try {
        await validateSchedule(selectedLocationId, laborScheduleActual.week_start).then(
          (res: Array<{rule: string; description: string; check: boolean}>) => {
            showToast(
              'Schedule Validation Completed',
              'Schedule validation completed successfully!',
              'success'
            )
            setSettings({
              cancelButtonText: 'Close',
              saveButtonText: '',
              saveButtonCallback: () => {},
              closeButtonCallback: () => {
                setDefaults()
              },
              titleText: 'Validate Schedule Result',
              // show each result in res as a list
              content: (
                <div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                          <thead>
                            <tr>
                              <th>Rule</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {res.map((value) => (
                              <tr key={value.rule}>
                                <td>{value.rule}</td>
                                <td>{value.description}</td>
                                <td>
                                  {value.check ? (
                                    <i className='fa fa-times-circle text-danger'></i>
                                  ) : (
                                    <i className='fa fa-check-circle text-success'></i>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ),
              show: true,
            })
          }
        )
      } catch (e) {
        showToast('Error', 'Error validating schedule!', 'danger')
        setDefaults()
      }
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const validateModal = async (status: string, actionText: string) => {
    if (status === '0') {
      setDefaults()
      return
    }
    if (actionText === 'Send back to progress' || actionText === 'Reject') {
      handleScheduleStatus(status, actionText, false)
      return
    }
    setSettings({
      cancelButtonText: 'Close',
      saveButtonText: 'Validate Schedule',
      saveButtonCallback: () => {
        validateCurrentScheduleForStatusChange(status, actionText)
      },
      closeButtonCallback: () => {
        setDefaults()
      },
      titleText: 'Validate Schedule',
      content: (
        <div>
          <div className='text-gray-600 fs-6 fw-bold'>
            Please validate the schedule before applying action {actionText}.
          </div>
        </div>
      ),
      show: true,
    })
  }

  const validateCurrentScheduleForStatusChange = async (status: string, actionText: string) => {
    if (laborScheduleActual && selectedLocation) {
      setLoadingState(true)
      try {
        await validateSchedule(selectedLocationId, laborScheduleActual.week_start).then(
          (res: Array<{rule: string; description: string; check: boolean}>) => {
            showToast(
              'Schedule Validation Completed',
              'Schedule validation completed successfully!',
              'success'
            )
            if (res.some((value) => value.check)) {
              const validationTable = (
                <div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                          <thead>
                            <tr>
                              <th>Rule</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {res.map((value) => (
                              <tr key={value.rule}>
                                <td>{value.rule}</td>
                                <td>{value.description}</td>
                                <td>
                                  {value.check ? (
                                    <i className='fa fa-times-circle text-danger'></i>
                                  ) : (
                                    <i className='fa fa-check-circle text-success'></i>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )

              if (isAdmin && actionText !== 'Validate') {
                handleScheduleStatus(status, actionText, true, true, validationTable)
              } else {
                setSettings({
                  cancelButtonText: 'Close',
                  saveButtonText: '',
                  saveButtonCallback: () => {},
                  closeButtonCallback: () => {
                    setDefaults()
                  },
                  titleText: 'Validate Schedule Result',
                  // show each result in res as a list
                  content: validationTable,
                  show: true,
                })
              }
            } else {
              handleScheduleStatus(status, actionText, true, false)
            }
          }
        )
      } catch (e) {
        showToast('Error', 'Error validating schedule!', 'danger')
        setDefaults()
      }
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  const uploadScheduleFromFile = async () => {
    if (laborScheduleActual && selectedLocation) {
      setLoadingState(true)
      try {
        await uploadFromFile(selectedLocationId, laborScheduleActual?.week_start, uploadFile)
        showToast('Schedule Uploaded', 'Schedule uploaded successfully!', 'success')
      } catch (e) {
        showToast('Error', 'Error uploading schedule!', 'danger')
      }
      setTimeout(() => {
        setLoadingState(false)
      }, 10)
    }
  }

  useEffect(() => {}, [selectedAction, strictlyWrongSchedule])

  let options = <></>
  let show_options = false
  if (isAdmin) {
    options = (
      <>
        <option value='0'>Select a new status</option>
        <option value='in_progress'>Send back to progress</option>
        <option value='awaiting_approval'>Send to DM Approval</option>
        <option value='awaiting_admin_approval'>Send to Admin Approval</option>
        <option value='approved'>Approve</option>
        <option value='validate'>Validate</option>
        {/* if status is awaiting_admin_approval, show reject option */}
        {laborScheduleActual?.workflow_status === 'awaiting_admin_approval' && (
          <option value='awaiting_approval'>Reject</option>
        )}
        {laborScheduleActual?.workflow_status === 'awaiting_approval' && (
          <option value='in_progress'>Reject</option>
        )}
      </>
    )
    show_options = true
  } else if (
    currentUser?.permissions.includes('labor_scheduling.send_to_approval') &&
    laborScheduleActual?.workflow_status === 'in_progress'
  ) {
    options = (
      <>
        <option value='0'>Select an action</option>
        <option value='awaiting_approval'>Send to DM Approval</option>
      </>
    )
    show_options = true
  } else if (
    currentUser?.permissions.includes('labor_scheduling.approve') &&
    laborScheduleActual?.workflow_status === 'awaiting_approval'
  ) {
    options = (
      <>
        <option value='0'>Select an action</option>
        <option value='in_progress'>Reject</option>
        {!strictlyWrongSchedule ? (
            <option value='awaiting_admin_approval'>Send to Admin Approval</option>
        ) : null}
      </>
    )
    show_options = true
  } else if (
    isAdmin &&
    currentUser?.permissions.includes('labor_scheduling.approve') &&
    laborScheduleActual?.workflow_status === 'awaiting_admin_approval'
  ) {
    options = (
      <>
        <option value='0'>Select an action</option>
        <option value='approved'>Approve</option>
        <option value='awaiting_approval'>Reject</option>
      </>
    )
    show_options = true
  } else if (isAdmin && laborScheduleActual?.workflow_status === 'approved') {
    options = (
      <>
        <option value='0'>Select an action</option>
        <option value='in_progress'>Send back to progress</option>
      </>
    )
    show_options = true
  }

  const setDefaults = () => {
    setSelectedAction('0')
    setSelectedStatus('0')
    setStatusAction('')
    setRejectReason('')
  }

  const setStatusDefaults = () => {
    setSelectedStatus('0')
    setStatusAction('')
    setRejectReason('')
  }

  const handleActions = () => {
    if (statusAction === 'Reject') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Reject',
        saveButtonCallback: () => {
          rejectLaborScheduleActual(rejectReason)
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Reject',
        content: (
          <div>
            <div className='text-gray-600 fs-6 fw-bold'>
              Are you sure you want to reject the schedule?
            </div>
          </div>
        ),
        show: true,
      })
    }
    if (selectedAction === 'clear') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Clear Schedule',
        saveButtonCallback: () => {
          clearSchedule()
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Clear Schedule',
        content: (
          <div>
            <div className='text-gray-600 fs-6 fw-bold'>
              Are you sure you want to clear the schedule?
            </div>
            <div className='text-danger fs-7 fw-bold'>This action cannot be undone.</div>
          </div>
        ),
        show: true,
      })
    } else if (selectedAction === 'copy_from') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Copy from previous weeks',
        saveButtonCallback: () => {
          copyFromPreviousWeeks()
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Copy from previous weeks',
        content: (
          <div>
            <div className='text-gray-600 fs-6 fw-bold'>
              This operation will remove old shifts. Are you sure you want to copy from previous?
            </div>
            <div className='text-gray-400 fs-7 fw-bold'>This action cannot be undone.</div>
          </div>
        ),
        show: true,
      })
    } else if (selectedAction === 'pdf') {
      // get pdf content from api and download
      getPDF()
      setDefaults()
    } else if (selectedAction === 'upload_file') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Upload schedule from file',
        saveButtonCallback: () => {
          uploadScheduleFromFile()
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Upload schedule from file',
        content: (
          <div>
            <div className='text-gray-600 fs-6 fw-bold'>
              This operation will remove old shifts. Are you sure you want to upload schedule from
              file?
            </div>
            <div className='text-gray-400 fs-7 fw-bold'>This action cannot be undone.</div>
          </div>
        ),
        show: true,
      })
    }
  }

  const handleScheduleStatus = (
    status: string,
    actionText: string,
    fromValidation: boolean,
    validationFailed?: boolean,
    validationTable?: ReactJSXElement
  ) => {
    let success = <div className='text-green-600 fs-6 fw-bold'></div>
    if (fromValidation) {
      if (validationFailed === false) {
        success = (
          <div className='text-green-600 fs-6 fw-bold'>
            All labor scheduling validation rules have passed successfully.
          </div>
        )
      } else if (validationFailed === true) {
        success = (
          <div className='text-red-600 fs-6 fw-bold'>
            Some labor scheduling validation rules have failed.
          </div>
        )
      }
    }
    if (actionText === 'Send to DM Approval') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Send to DM Approval',
        saveButtonCallback: () => {
          setLaborScheduleActualStatus(status)
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Send to DM Approval',
        content: (
          <div>
            {validationTable !== undefined ? validationTable : null}
            {success}
            <div className='text-gray-600 fs-6 fw-bold'>
              Are you sure you want to send the schedule to DM Approval?
            </div>
          </div>
        ),
        show: true,
      })
    } else if (actionText === 'Send to Admin Approval') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Send to Admin Approval',
        saveButtonCallback: () => {
          setLaborScheduleActualStatus(status)
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Send to Admin Approval',
        content: (
          <div>
            {validationTable !== undefined ? validationTable : null}
            {success}
            <div className='text-gray-600 fs-6 fw-bold'>
              Are you sure you want to send the schedule to Admin Approval?
            </div>
          </div>
        ),
        show: true,
      })
    } else if (actionText === 'Reject') {
      setStatusAction('Reject')
      setSelectedAction('0')
    } else if (actionText === 'Send back to progress') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Send back to progress',
        saveButtonCallback: () => {
          setLaborScheduleActualStatus(status)
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Send back to progress',
        content: (
          <div>
            {validationTable !== undefined ? validationTable : null}
            {success}
            <div className='text-gray-600 fs-6 fw-bold'>
              Are you sure you want to send the schedule back to progress?
            </div>
          </div>
        ),
        show: true,
      })
    } else if (actionText === 'Approve') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: 'Approve',
        saveButtonCallback: () => {
          setLaborScheduleActualStatus(status)
          setDefaults()
        },
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Approve',
        content: (
          <div>
            {validationTable !== undefined ? validationTable : null}
            {success}
            <div className='text-gray-600 fs-6 fw-bold'>
              Are you sure you want to approve the schedule?
            </div>
          </div>
        ),
        show: true,
      })
    } else if (actionText === 'Validate') {
      setSettings({
        cancelButtonText: 'Close',
        saveButtonText: '',
        saveButtonCallback: () => {},
        closeButtonCallback: () => {
          setDefaults()
        },
        titleText: 'Validate Schedule',
        content: (
          <div>
            {validationTable !== undefined ? validationTable : null}
            {success}
          </div>
        ),
        show: true,
      })
    }
    // setDefaults()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
      data-fds-menu='true'
      id='actions_menu'
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Scheduling Options</div>
      </div>

      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        {show_options && (
          <div className='mb-10'>
            <label className={'form-label fw-bold ' + (strictlyWrongSchedule ? 'text-muted' : '')}>
              Update Status:
            </label>

            <div>
              <select
                className='form-select form-select-solid gray-800-bg'
                data-fds-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                disabled={strictlyWrongSchedule && !isAdmin && 
                  !(currentUser?.permissions.includes('labor_scheduling.approve') && laborScheduleActual?.workflow_status === 'awaiting_approval')}
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value)
                  validateModal(e.target.value, e.target.selectedOptions[0].text)
                }}
              >
                {options}
              </select>
            </div>
            {strictlyWrongSchedule && (
              <div className='text-danger mt-2'>
                <span className='fw-bold'>Warning:</span> The schedule has strictly unacceptable
                number of employees for some time slots.{' '}
                {!isAdmin && <span>Please fix it before changing schedule status.</span>}
              </div>
            )}
          </div>
        )}
        {statusAction === 'Reject' && (
          <div className='mb-10'>
            <label className='form-label fw-bold'>Reason:</label>
            <textarea
              className='form-control'
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </div>
        )}
        <div className='mb-10'>
          <label className='form-label fw-bold'>Actions:</label>

          <div className='d-flex'>
            <select
              className='form-select form-select-solid'
              id='schedule_actions'
              data-fds-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={selectedAction}
              onChange={(e) => {
                setStatusDefaults()
                setSelectedAction(e.target.value)
              }}
            >
              <option value='0'>Select an action</option>
              {laborScheduleActual?.workflow_status === 'in_progress' && (
                <option value='clear'>Clear Schedule</option>
              )}
              {laborScheduleActual?.workflow_status === 'in_progress' && (
                <option value='copy_from'>Copy from previous weeks</option>
              )}
              <option value='pdf'>Download PDF of schedule</option>
              {laborScheduleActual?.workflow_status === 'in_progress' && (
                <option value='upload_file'>Upload schedule from file</option>
              )}
            </select>
          </div>
        </div>

        {selectedAction === 'copy_from' && (
          <div className='mb-10'>
            <label className='form-label fw-bold'>Copy from previous weeks:</label>
            <ReactDatePicker
              showPopperArrow={true}
              selected={copyFromDate}
              calendarStartDay={weekStartOn}
              // maxDate is minimum of (addDays(lastDayOfWeek(new Date(), {weekStartsOn: weekStartOn}), -7), selectedDate)
              maxDate={addDays(lastDayOfWeek(selectedDate, {weekStartsOn: weekStartOn}), -7)}
              onChange={(date: Date) => {
                setCopyFromDate(date)
              }}
            />
          </div>
        )}

        {selectedAction === 'upload_file' && (
          <div className='mb-10'>
            <label className='form-label fw-bold'>Upload File:</label>
            <input
              type='file'
              className='form-control'
              value={uploadFileName}
              onChange={(e) => {
                setUploadFileName(e.target.value)
                setUploadFile(e.target.files && e.target.files[0])
              }}
            />
          </div>
        )}

        <div className='mb-10'>
          <label className='form-label fw-bold'>Auto Sync:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={true}
              disabled={true}
            />
            <label className='form-check-label'>Enabled</label>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-fds-menu-dismiss='true'
            onClick={() => setDefaults()}
          >
            Close
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            data-fds-menu-dismiss='true'
            onClick={() => handleActions()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
