import {Fragment, useMemo, useState, useEffect, useRef, useLayoutEffect} from 'react'
import {Popover, useTheme, Paper} from '@mui/material'
import {differenceInMinutes, format, formatDistance} from 'date-fns'
import {ProcessedEvent} from '../../types'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded'
import WatchLater from '@mui/icons-material/WatchLater'
import {PopperInner} from '../../styles/styles'
import EventActions from './Actions'
import {differenceInDaysOmitTime, getBusinessDay, toTitleCase} from '../../helpers/generals'
import {useStore} from '../../store'
import {KTSVG} from '../../../../../_fds/helpers'
import {Button, Card, Popover as Popover2} from 'react-bootstrap'
import {display, style} from '@mui/system'
import ResizeObserver from 'rc-resize-observer'
import {updateShift} from '../../core/_requests'
import {useAuth} from '../../../auth'
import {useToast} from '../../../../../_fds/layout/components/toast-messages'

interface EventItemProps {
  event: ProcessedEvent
  minuteHeight: number
  multiday: boolean
  step: number
  hasPrev?: boolean
  hasNext?: boolean
  showdate?: boolean
  resize?: any
  height?: number | string
  width?: number | string
  onResize?: any
}

const EventItem = ({
  event,
  multiday,
  hasPrev,
  hasNext,
  showdate,
  resize,
  width,
  height,
  onResize,
  minuteHeight,
  step,
}: EventItemProps) => {
  const {
    triggerDialog,
    onDelete,
    events,
    handleState,
    triggerLoading,
    viewerExtraComponent,
    fields,
    direction,
    resources,
    resourceFields,
    locale,
    viewerTitleComponent,
    editable,
    deletable,
    hourFormat,
    eventRenderer,
    onEventClick,
    view,
    draggable,
    translations,
    selectedLaborScheduleId,
    selectedLocationId,
    hoursOfOperations,
  } = useStore()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const theme = useTheme()
  const hFormat = hourFormat === '12' ? 'h:mm a' : 'H:mm'

  const NextArrow = direction === 'rtl' ? ArrowLeftRoundedIcon : ArrowRightRoundedIcon
  const PrevArrow = direction === 'rtl' ? ArrowRightRoundedIcon : ArrowLeftRoundedIcon
  const hideDates = differenceInDaysOmitTime(event.start, event.end) <= 0 && event.allDay
  const [mouseUp, setMouseUp] = useState(false)
  const [resizing, setResizing] = useState(false)
  const [calcHeight, setCalcHeight] = useState(height)
  const [calcWidth, setCalcWidth] = useState(width)
  const targetRef = useRef<any>()

  const triggerViewer = (el?: Element) => {
    if (!el && deleteConfirm) {
      setDeleteConfirm(false)
    }
    setAnchorEl(el || null)
    // set document scrollable to hidden
    if (el) {
      document.body.style.overflow = 'hidden'
      // add padding right to body
      const scrollBarWidth = 15
      document.body.style.paddingRight = `${scrollBarWidth}px`
    } else {
      document.body.style.overflow = 'auto'
      // remove padding right from body
      document.body.style.paddingRight = '0px'
    }
  }
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }
  useEffect(() => {
    setCalcHeight(height)
  }, [height])
  useEffect(() => {
    const handleMouseUp = (e: any) => {
      if (resizing) {
        setMouseUp(true)
        e.stopPropagation()
        e.preventDefault()
      }
    }

    document.body.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.body.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  const resizeVertically = (
    start: Date,
    calcHeight: string | number | undefined,
    newHeight: any,
    newWidth: any,
    minuteHeight: number,
    step: number,
    setCalcHeight: any
  ) => {
    const newStart = new Date(start)
    newStart.setSeconds(0)
    const newCalcHeight = newHeight
    const newCalcWidth = newWidth
    const newMinutes = Math.round(newCalcHeight / minuteHeight)

    // new height will be rounded to the nearest divisor of step
    const newMinutesRounded = Math.round(newMinutes / step) * step
    const newHeightRounded = newMinutesRounded * minuteHeight
    setCalcHeight(newHeightRounded)
    const newEnd = new Date(newStart.getTime() + newMinutesRounded * 60000)
    newEnd.setSeconds(0)
    return {newHeightRounded, newStart, newEnd}
  }

  const resizeHorizontally = (
    start: Date,
    calcWidth: string | number | undefined,
    newWidth: any,
    minuteHeight: number,
    step: number,
    setCalcWidth: any
  ) => {
    const newStart = new Date(start)
    newStart.setSeconds(0)
    const newCalcWidth = newWidth
    const newMinutes = Math.round(newCalcWidth / minuteHeight)

    // new height will be rounded to the nearest divisor of step
    const newMinutesRounded = Math.round(newMinutes / step) * step
    const newWidthRounded = newMinutesRounded * minuteHeight
    setCalcWidth(newWidthRounded)
    const newEnd = new Date(newStart.getTime() + newMinutesRounded * 60000)
    newEnd.setSeconds(0)
    return {newWidthRounded, newStart, newEnd}
  }

  const {showToast} = useToast()

  const handleResize = async (e: any) => {
    try {
      // sleep 1 sec
      await timeout(50)
      const {newWidth, newHeight, offsetWidth, offsetHeight} = e
      const {event_id, start, end} = event
      if (mouseUp && resizing) {
        triggerLoading(true)
        await timeout(500)
        if (view === 'week') {
          const {newHeightRounded, newStart, newEnd} = resizeVertically(
            start,
            calcHeight,
            newHeight,
            newWidth,
            minuteHeight,
            step,
            setCalcHeight
          )

          if (targetRef.current && mouseUp) {
            targetRef.current.style.height = `${newHeightRounded}px`
          }

          const updatedEvent = {
            event_id,
            start: newStart,
            end: newEnd,
            employee_id: event.employee_id,
            selectedLocationId: selectedLocationId,
            selectedLaborScheduleId: selectedLaborScheduleId,
            position: event.position,
            businessDay: getBusinessDay(newStart, hoursOfOperations),
          }

          try {
            await updateShift(updatedEvent, updatedEvent.businessDay)

            if (onResize) {
              const remoteEvent = await onResize(updatedEvent)
              if (remoteEvent) {
                updatedEvent.start = remoteEvent.start
                updatedEvent.end = remoteEvent.end
              }
            }
            const updatedEvents = events.map((e) =>
              e.event_id === event_id ? {...e, ...updatedEvent} : e
            )
            handleState(updatedEvents, 'events')
            showToast('Shift Updated', 'Shift has been updated!', 'success')
          } catch (error) {
            // set to old height
            if (targetRef.current) {
              targetRef.current.style.height = `${height}px`
            }
            showToast('Shift Update Failed', 'Unable to resize shift!', 'danger')
          }

          // Trigger custom/remote when provided
        } else if (view === 'timeline') {
          const {newWidthRounded, newStart, newEnd} = resizeHorizontally(
            start,
            calcWidth,
            newWidth,
            minuteHeight,
            step,
            setCalcWidth
          )

          if (targetRef.current && mouseUp) {
            targetRef.current.style.width = `${newWidthRounded}px`
          }

          const updatedEvent = {
            event_id,
            start: newStart,
            end: newEnd,
            employee_id: event.employee_id,
            selectedLocationId: selectedLocationId,
            selectedLaborScheduleId: selectedLaborScheduleId,
            position: event.position,
            businessDay: getBusinessDay(newStart, hoursOfOperations),
          }

          try {
            await updateShift(updatedEvent, updatedEvent.businessDay)
            // Trigger custom/remote when provided
            // if (onResize) {
            //   const remoteEvent = await onResize(updatedEvent)
            //   if (remoteEvent) {
            //     updatedEvent.start = remoteEvent.start
            //     updatedEvent.end = remoteEvent.end
            //   }
            // }
            const updatedEvents = events.map((e) =>
              e.event_id === event_id ? {...e, ...updatedEvent} : e
            )
            handleState(updatedEvents, 'events')
            showToast('Shift Updated', 'Shift has been updated!', 'success')
          } catch (error) {
            // set to old width
            if (targetRef.current) {
              targetRef.current.style.width = `${width}px`
            }
            showToast('Shift Update Failed', 'Unable to resize shift!', 'danger')
          }
        }

        // await timeout(1000) //for 1 sec delay
      }
    } catch (error) {
      console.error(error)
    } finally {
      triggerLoading(false)
      setResizing(false)
    }
  }

  useEffect(() => {
    if (resize) {
      handleResize({
        newWidth: targetRef.current.offsetWidth,
        newHeight: targetRef.current.offsetHeight,
        offsetWidth: targetRef.current.offsetWidth,
        offsetHeight: targetRef.current.offsetHeight,
      })
    }
  }, [mouseUp]) // Check resize on mouse up as well
  const handleDelete = async () => {
    try {
      triggerLoading(true)
      let deletedId = event.event_id
      // Trigger custom/remote when provided
      if (onDelete) {
        const remoteId = await onDelete(deletedId)
        if (remoteId) {
          deletedId = remoteId
        } else {
          deletedId = ''
        }
      }
      if (deletedId) {
        triggerViewer()
        const updatedEvents = events.filter((e) => e.event_id !== deletedId)
        handleState(updatedEvents, 'events')
      }
    } catch (error) {
      console.error(error)
    } finally {
      triggerLoading(false)
    }
  }

  const renderViewer = () => {
    const idKey = resourceFields.idField
    const hasResource = resources.filter((res) =>
      Array.isArray(event[idKey]) ? event[idKey].includes(res[idKey]) : res[idKey] === event[idKey]
    )

    return (
      <Popover2 id={`event_popover_${event[idKey]}`}>
        <Popover2.Header
          style={{
            background: event.color || theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
          className=''
        >
          <div className='d-flex flex-wrap align-items-center'>
            <div className=''>{toTitleCase(event.position)}</div>
            <div className='ms-auto'>
              <EventActions
                event={event}
                onDelete={handleDelete}
                onEdit={() => {
                  triggerViewer()
                  triggerDialog(true, event)
                }}
                direction={direction}
                deletable={deletable}
                editable={editable}
              />
            </div>
          </div>
        </Popover2.Header>
        <Popover2.Body>
          <div style={{gap: 8}} className='d-flex align-items-center fs-9 text-gray-900'>
            <EventNoteRoundedIcon />
            {hideDates
              ? translations.event.allDay
              : `${format(event.start, `M/d/Y, ${hFormat}`, {
                  locale: locale,
                })} - ${format(event.end, `M/d/Y, ${hFormat}`, {
                  locale: locale,
                })}`}
          </div>
          {hasResource.length > 0 && (
            <div
              style={{display: 'flex', alignItems: 'center', gap: 8}}
              className='fs-9 text-gray-900'
            >
              <SupervisorAccountRoundedIcon />
              {hasResource.map((res) => res[resourceFields.textField]).join(', ')}
            </div>
          )}

          <div
            style={{display: 'flex', alignItems: 'center', gap: 8}}
            className='fs-9 text-gray-900'
          >
            <WatchLater />
            {`${new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 1,
            }).format(differenceInMinutes(event.end, event.start) / 60)} hrs`}
          </div>
          {viewerExtraComponent instanceof Function
            ? viewerExtraComponent(fields, event)
            : viewerExtraComponent}
        </Popover2.Body>
      </Popover2>
    )
  }
  const {currentUser} = useAuth()

  const isDraggable = useMemo(() => {
    // if Disabled
    if (event.disabled) return false

    if (
      event.start < new Date() &&
      !['Creator', 'Admin', 'DM'].includes(currentUser?.role?.name || '')
    ) {
      return false
    }

    if (view === 'employeesWeek') {
      return false
    }

    // global-wise isDraggable
    let canDrag = typeof draggable !== 'undefined' ? draggable : true
    // Override by event-wise
    if (typeof event.draggable !== 'undefined') {
      canDrag = event.draggable
    }

    return canDrag
  }, [draggable, event.disabled, event.draggable])

  const renderEvent = useMemo(() => {
    // Check if has custom render event method
    // only applicable to non-multiday events and not in month-view
    if (typeof eventRenderer === 'function' && !multiday) {
      // && view !== 'month') {
      const custom = eventRenderer(event)
      if (custom) {
        return custom
      }
    }

    let item = (
      <div className='fw-bolder' style={{padding: 2}}>
        <div className='text-gray-900 fs-9'>{toTitleCase(event.position)}</div>
        {showdate && (
          <div className='text-gray-900 fs-9'>
            {`${format(event.start, hFormat, {
              locale,
            })} - ${format(event.end, hFormat, {locale})}`}
          </div>
        )}
        {showdate && (
          <div className='text-gray-900 fs-9'>
            {`${new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(
              differenceInMinutes(event.end, event.start) / 60
            )} hrs`}
          </div>
        )}
      </div>
    )
    // if (multiday) {
    //   item = (
    //     <div
    //       style={{
    //         padding: 2,
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'space-between',
    //       }}
    //     >
    //       <span className='fs-8'>
    //         {hasPrev ? (
    //           <i className={'bi bi-caret-left-fill d-flex text-gray-900'}></i>
    //         ) : (
    //           // <PrevArrow fontSize='small' sx={{display: 'flex'}} />
    //           showdate && !hideDates && format(event.start, hFormat, {locale})
    //         )}
    //       </span>
    //       <div className='fs-8'>{event.position}</div>
    //       <div className='fs-8'>
    //         {hasNext ? (
    //           <i className={'bi bi-caret-right-fill d-flex text-gray-900'}></i>
    //         ) : (
    //           showdate && !hideDates && format(event.end, hFormat, {locale})
    //         )}
    //       </div>
    //     </div>
    //   )
    // }
    return item
    // eslint-disable-next-line
  }, [hasPrev, hasNext, event, isDraggable, locale, theme.palette])

  return (
    <Fragment>
      <ResizeObserver
        onResize={(e) => {
          setMouseUp(false)
          setResizing(true)
        }}
      >
        <Card
          ref={targetRef}
          key={`${event.start.getTime()}_${event.end.getTime()}_${event.event_id}`}
          style={{
            width: width,
            height: calcHeight,
            display: 'block',
            background: event.disabled ? '#d0d0d0' : event.color || theme.palette.primary.main,
            color: event.disabled ? '#808080' : theme.palette.primary.contrastText,
            cursor: event.disabled ? 'not-allowed' : 'pointer',
            overflow: 'hidden',
            resize: isDraggable && resize,
          }}
          onMouseUp={() => {
            setMouseUp(true)
          }}
          onTouchEnd={() => {
            setMouseUp(true)
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              triggerViewer(e.currentTarget)
              if (typeof onEventClick === 'function') {
                onEventClick(event)
              }
            }}
            className={`btn btn-sm btn-icon fs-10 me-2`}
            disabled={event.disabled}
            style={{
              width: '100%',
              height: '100%',
              display: 'block',
            }}
          >
            <div
              style={{
                height: '100%',
              }}
              className='d-flex align-items-center justify-content-center mx-auto my-auto'
              draggable={isDraggable}
              onDragStart={(e) => {
                e.stopPropagation()
                e.dataTransfer.setData('text/plain', `${event.event_id}`)
                e.currentTarget.style.backgroundColor = theme.palette.error.main
              }}
              onDragEnd={(e) => {
                e.currentTarget.style.backgroundColor = event.color || theme.palette.primary.main
              }}
              onDragOver={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              onDragEnter={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {renderEvent}
            </div>
          </button>
        </Card>
      </ResizeObserver>
      {/* Viewer */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          // make body scrollable again
          document.body.style.overflow = 'auto'
          triggerViewer()
        }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {renderViewer()}
      </Popover>
    </Fragment>
  )
}

EventItem.defaultProps = {
  multiday: false,
  showdate: true,
}

export default EventItem
