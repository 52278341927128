/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useState, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {LoadingStateProvider} from '../modules/scheduler/context/LoadingContext'
import {Wrapper} from '../modules/scheduler/styles/styles'
import {SingleSignOn} from '../modules/auth/SingleSignOn'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false)

  const {currentUser, saveAuth, setCurrentUser} = useAuth()

  const urlParams = new URLSearchParams(window.location.search)
  const sso = urlParams.get('sso')

  return (
    <LoadingStateProvider value={{loadingState: loadingState, setLoadingState: setLoadingState}}>
      <Wrapper dialog={0} data-testid='rs-wrapper' className=''>
        {loadingState && (
          <div className='rs__table_loading'>
            <span className='indicator-progress text-gray-900 bg-gray-200 bg-opacity-50'>
              Please wait...{' '}
              <span className='spinner-border spinner-border-lg spinner-primary align-middle ms-2'></span>
            </span>
          </div>
        )}
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />

              {sso ? (
                <Route path='*' element={<SingleSignOn sso={sso} />} />
              ) : currentUser ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/labor-scheduling/scheduling/overview' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </Wrapper>
    </LoadingStateProvider>
  )
}

export {AppRoutes}
