import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL + '/users'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_JWT_TOKEN = `${API_URL}/get_jwt_token`

// Server should return AuthModel
export function login(email: string, password: string) {
  var formData = new FormData()
  formData.append('email', email)
  formData.append('password', password)

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return axios.post<AuthModel>(LOGIN_URL, formData, {headers: headers})
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstname: firstname,
    lastname: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  var formData = new FormData()
  formData.append('api_token', token)

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, formData, {headers: headers})
}

export function getJwtToken(token: string) {
  var formData = new FormData()
  formData.append('api_token', token)

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return axios.post<AuthModel>(GET_JWT_TOKEN, formData, {headers: headers})
}
