/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {useStore} from '../../../../app/modules/scheduler/store'
import {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  endOfDay,
  format,
  formatDuration,
  isBefore,
  startOfDay,
  startOfWeek,
} from 'date-fns'
import {
  filterEventsBetween,
  getHoursOfOperationOnDay,
} from '../../../../app/modules/scheduler/helpers/generals'

type Props = {
  className: string
}

const ChartSummary: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {week, selectedDate, events, locale, hourFormat, hoursOfOperations, scheduleDaySlotStats} =
    useStore()
  const [chartCollapsed, setChartCollapsed] = useState(true)
  const {weekStartOn, weekDays, startHour, endHour, step, cellRenderer} = week!

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    // use integer part of startHour for hour and decimal part for minutes
    // get decimal part of startHour
    const [dayStart, dayEnd] = getHoursOfOperationOnDay(selectedDate, hoursOfOperations)
    const START_TIME = dayStart //setMinutes(setHours(selectedDate, startHour), startHourDecimal * 60)
    // use integer part of endHour for hour and decimal part for minutes
    // get decimal part of endHour
    const END_TIME = addMinutes(dayEnd, -30) //setMinutes(setHours(selectedDate, endHour), endHourDecimal * 60)

    // if END_TIME is before START_TIME, it means that the day is over midnight
    // so we need to add 24 hours to the end time
    if (isBefore(END_TIME, START_TIME)) {
      END_TIME.setHours(END_TIME.getHours() + 24)
    }

    const hours = eachMinuteOfInterval(
      {
        start: START_TIME,
        end: END_TIME,
      },
      {step: step}
    )
    const hFormat = hourFormat === '12' ? 'h:mm a' : 'H:mm'

    const assignedEmployees = hours.map((h, i) => {
      const headCountsUsed = filterEventsBetween(
        events.filter((x) => x.position !== 'rgm' && x.position !== 'sl'),
        h,
        addMinutes(h, step)
      ).length
      return headCountsUsed
    })
    const assignedSl = hours.map((h, i) => {
      const headCountsUsed = filterEventsBetween(
        events.filter((x) => x.position === 'sl' || x.position === 'Shift Leader'),
        h,
        addMinutes(h, step)
      ).length
      return headCountsUsed
    })
    const assignedGm = hours.map((h, i) => {
      const headCountsUsed = filterEventsBetween(
        events.filter((x) => x.position === 'rgm'),
        h,
        addMinutes(h, step)
      ).length
      return headCountsUsed
    })

    const forecastValues: number[] = [] // Array to store forecast values
    const forecastValuesPerManHour: number[] = []
    const trailingSixWeeksSos: number[] = []

    hours.forEach((hour, i) => {
      const hourKey = format(hour, 'yyyy-MM-dd HH:mm')
      const employeeCount = assignedEmployees[i] + assignedSl[i] + assignedGm[i]
      if (scheduleDaySlotStats) {
        forecastValues.push(scheduleDaySlotStats.forecast_values[hourKey] || 0)
        if (employeeCount === 0) {
          forecastValuesPerManHour.push(0)
        } else {
          forecastValuesPerManHour.push(
            scheduleDaySlotStats.forecast_values[hourKey] / (employeeCount / 2) || 0
          )
        }
        trailingSixWeeksSos.push(scheduleDaySlotStats.trailing_six_weeks_sos[hourKey] || 0)
      } else {
        forecastValues.push(0)
        forecastValuesPerManHour.push(0)
        trailingSixWeeksSos.push(0)
      }
    })

    const hoursStr = hours.map((h) => format(h, hFormat, {locale}))
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        hoursStr,
        assignedEmployees,
        assignedSl,
        assignedGm,
        forecastValues,
        forecastValuesPerManHour,
        trailingSixWeeksSos
      )
    )
    if (chart) {
      chart.render()
      chart.hideSeries('Forecasted Sales')
      chart.hideSeries('Forecasted Sales per Manhour')
      chart.hideSeries('Trailing 6 Week SOS')
    }

    return chart
  }
  const {mode} = useThemeMode()

  useEffect(() => {
    // if scheduleDaySlotStats's forecast_values' any key date is not equal to selectedDate, then wait for the scheduleDaySlotStats to be updated
    if (chartCollapsed) {
      return
    }
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [events, selectedDate, mode, scheduleDaySlotStats, chartCollapsed])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Summary Plot</span>

          <span className='text-muted fw-semibold fs-7'>
            Scheduled Labor on {format(selectedDate, 'M/d/yyyy')}
          </span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-fds-buttons='true'>
          <button
            className='btn btn-icon btn-secondary'
            type='button'
            onClick={() => {
              setChartCollapsed(!chartCollapsed)
            }}
            data-bs-toggle='collapse'
            data-bs-target='#fds-chart-body'
            aria-expanded='false'
            aria-controls='fds-chart-body'
          >
            <i className={`fas ${chartCollapsed ? 'fa-arrow-down' : 'fa-arrow-up'}`}></i>
          </button>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body collapse' id='fds-chart-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='fds_charts_widget_summary_chart'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartSummary}

function getChartOptions(
  height: number,
  hours: string[],
  assignedEmployees: number[],
  assignedSl: number[],
  assignedGm: number[],
  forecastValues: number[],
  forecastValuesPerManHour: number[],
  trailingSixWeeksSos: number[]
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-900')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue('--bs-orange')
  const baseLightColor = getCSSVariableValue('--bs-green')
  const secondaryColor = getCSSVariableValue('--bs-blue')
  const thirdColor = getCSSVariableValue('--bs-purple')

  function round(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  return {
    series: [
      {
        name: 'Assigned Employees',
        type: 'column',
        data: assignedEmployees,
      },
      {
        name: 'Assigned SL',
        type: 'column',
        data: assignedSl,
      },
      {
        name: 'Assigned GM',
        type: 'column',
        data: assignedGm,
      },
      {
        name: 'Forecasted Sales',
        type: 'line',
        data: forecastValues,
      },
      {
        name: 'Forecasted Sales per Manhour',
        type: 'line',
        data: forecastValuesPerManHour,
      },
      {
        name: 'Trailing 6 Week SOS',
        type: 'line',
        data: trailingSixWeeksSos,
      },
    ],
    chart: {
      height: 275,
      type: 'line',
      stacked: true,
      foreColor: labelColor,
      events: {
        legendClick: function (chartContext, seriesIndex, config) {
          let options = chartContext.w.globals.lastYAxis
          if (seriesIndex && seriesIndex >= 3) {
            options[seriesIndex].show = !options[seriesIndex].show
            chartContext.updateOptions({
              yaxis: options,
            })
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#008ffb', '#49bd52', '#fd7f5a', '#E91E63', '#004F2D', '#980000'],
    stroke: {
      width: [1, 1, 1, 3, 3, 3],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      categories: hours,
    },
    yaxis: [
      {
        seriesName: 'Assigned Employees',
        min: 0,
        max:
          Math.max(...Object.values(assignedEmployees).flat()) +
          Math.max(...Object.values(assignedGm).flat()) +
          Math.max(...Object.values(assignedSl).flat()) +
          1,
        tickAmount: 2,
        title: {
          text: 'Assigned Employees',
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          },
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      {
        seriesName: 'Assigned GM',
        show: false,
        min: 0,
        max:
          Math.max(...Object.values(assignedEmployees).flat()) +
          Math.max(...Object.values(assignedGm).flat()) +
          Math.max(...Object.values(assignedSl).flat()) +
          1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          },
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      {
        seriesName: 'Assigned SL',
        show: false,
        min: 0,
        max:
          Math.max(...Object.values(assignedEmployees).flat()) +
          Math.max(...Object.values(assignedGm).flat()) +
          Math.max(...Object.values(assignedSl).flat()) +
          1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          },
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      {
        min: 0,
        max: Math.max(Math.max(...Object.values(forecastValues).flat())),
        tickAmount: 5,
        opposite: true,
        show: false,
        title: {
          text: 'Forecasted Sales',
        },
        labels: {
          formatter: function (val) {
            return (
              '$' +
              round(val, 2)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            ).replace('$-', '-$')
          },
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      {
        min: 0,
        max: Math.max(Math.max(...Object.values(forecastValuesPerManHour).flat())),
        tickAmount: 5,
        opposite: true,
        show: false,
        title: {
          text: 'Sales per Manhour',
        },
        labels: {
          formatter: function (val) {
            return (
              '$' +
              round(val, 2)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            ).replace('$-', '-$')
          },
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      {
        min: 0,
        max: Math.max(...Object.values(trailingSixWeeksSos).flat()),
        tickAmount: 5,
        opposite: true,
        show: false,
        title: {
          text: 'Trailing 6 Week SOS',
        },
        labels: {
          formatter: function (val) {
            // convert val to milliseconds as duration and format H:mm usind date-fns
            const dur = new Date(0, 0, 0, 0, val || 0)
            return format(dur, 'H:mm')
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      markers: {
        strokeColor: '#fff',
      },
    },
  }
}
