import {useState, useEffect} from 'react'
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
  useTheme,
} from '@mui/material'
import {FdsSelectBox} from '../../../../../_fds/partials/form/FdsSelectBox'
import {ExpandMoreOutlined} from '@mui/icons-material'

export type SelectOption = {
  id: string | number
  text: string
  value: any
}
interface EditorSelectProps {
  options: Array<SelectOption>
  value: string
  name: string
  onChange(name: string, value: string, isValid: boolean): void
  variant?: 'standard' | 'filled' | 'outlined'
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  touched?: boolean
  loading?: boolean
  multiple?: 'default' | 'chips'
  errMsg?: string
}

const EditorSelect = ({
  options,
  value,
  name,
  required,
  onChange,
  label,
  disabled,
  touched,
  variant,
  loading,
  multiple,
  placeholder,
  errMsg,
}: EditorSelectProps) => {
  const theme = useTheme()

  const [state, setState] = useState({
    touched: false,
    valid: !!value,
    errorMsg: errMsg ? errMsg : required ? 'Required' : undefined,
  })

  useEffect(() => {
    if (touched) {
      handleChange(value)
    }
    // eslint-disable-next-line
  }, [touched])
  const handleTouched = () => {
    if (!state.touched) {
      setState((prev) => {
        return {...prev, touched: true, errorMsg: errMsg || prev.errorMsg}
      })
    }
  }
  const handleChange = (value: string | any) => {
    const val = value
    let isValid = true
    let errorMsg = errMsg
    if (required && (multiple ? !val.length : !val)) {
      isValid = false
      errorMsg = 'Required'
    }
    setState((prev) => {
      return {...prev, touched: true, valid: isValid, errorMsg: errorMsg}
    })
    onChange(name, val, isValid)
  }
  return (
    <>
      <FdsSelectBox
        label={`${label} ${required ? '*' : ''}`}
        labelKey='text'
        controlId={name}
        value={value as any}
        onBlur={handleTouched}
        onChange={(e) => handleChange(e)}
        options={options}
        // multiple={!!multiple}
      />
    </>
  )
}

EditorSelect.defaultProps = {
  variant: 'outlined',
}

export {EditorSelect}
