import {useState} from 'react'
import DateProvider from '../hoc/DateProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {endOfWeek, format, startOfWeek, addDays} from 'date-fns'
import {WeekProps} from '../views/Week'
import {LocaleArrow} from '../common/LocaleArrow'
import {useStore} from '../../store'
import ReactDatePicker from 'react-datepicker'

interface WeekDateBtnProps {
  selectedDate: Date
  onChange(value: Date, key: 'selectedDate'): void
  weekProps: WeekProps
}

const WeekDateBtn = ({selectedDate, onChange, weekProps}: WeekDateBtnProps) => {
  const {locale, navigationPickerProps} = useStore()
  const [open, setOpen] = useState(false)
  const {weekStartOn} = weekProps
  const weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
  const weekEnd = endOfWeek(selectedDate, {weekStartsOn: weekStartOn})

  const toggleDialog = () => setOpen(!open)

  const handleChange = (e: Date | null) => {
    onChange(e || new Date(), 'selectedDate')
    toggleDialog()
  }

  const handlePrev = () => {
    const ladtDayPrevWeek = addDays(weekStart, -1)
    onChange(ladtDayPrevWeek, 'selectedDate')
  }
  const handleNext = () => {
    const firstDayNextWeek = addDays(weekEnd, 1)
    onChange(firstDayNextWeek, 'selectedDate')
  }
  return (
    <>
      <LocaleArrow type='prev' onClick={handlePrev} />
      <DateProvider>
        <span
          className='btn text-gray-800 text-hover-primary fw-bolder hover-cursor-pointer'
          style={{padding: 4}}
          onClick={toggleDialog}
        >{`${format(weekStart, 'dd', {locale})} - ${format(weekEnd, 'dd MMMM yyyy', {
          locale,
        })}`}</span>

        {open && (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 'calc(var(--bs-app-header-height) - 20px)',
              zIndex: 999,
            }}
          >
            <ReactDatePicker
              // onClose={toggleDialog}
              showPopperArrow={true}
              selected={selectedDate}
              calendarStartDay={weekStartOn}
              onChange={handleChange}
              onClickOutside={toggleDialog}
              popperProps={{strategy: 'fixed'}}
              showWeekNumbers
              inline
            />
          </div>
        )}
        {/* <DatePicker
          {...navigationPickerProps}
          open={open}
          onClose={toggleDialog}
          openTo='day'
          views={['year', 'month', 'day']}
          value={selectedDate}
          onChange={handleChange}
          showDaysOutsideCurrentMonth={true}
          renderInput={(params) => (
            <span
              className='btn text-gray-800 text-hover-primary fw-bolder hover-cursor-pointer'
              ref={params.inputRef}
              style={{padding: 4}}
              onClick={toggleDialog}
            >{`${format(weekStart, 'dd', {locale})} - ${format(weekEnd, 'dd MMMM yyyy', {
              locale,
            })}`}</span>
          )}
        /> */}

        <LocaleArrow type='next' onClick={handleNext} />
      </DateProvider>
    </>
  )
}

export {WeekDateBtn}
