import React from 'react'
import {LaborScheduleActualModel} from '../core/_models'

interface LaborScheduleActualContextProps {
  laborScheduleActual: LaborScheduleActualModel | null
  setLaborScheduleActual: (laborScheduleActual: LaborScheduleActualModel) => void
}

const LaborScheduleActualContext = React.createContext<LaborScheduleActualContextProps>({
  laborScheduleActual: null,
  setLaborScheduleActual: () => {},
})

export const LaborScheduleActualProvider = LaborScheduleActualContext.Provider
export default LaborScheduleActualContext
