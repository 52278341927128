import axios from 'axios'
import {format} from 'date-fns'

const SCHEDULES_API_URL = process.env.REACT_APP_API_URL + '/schedules'
const LOCATIONS_API_URL = process.env.REACT_APP_API_URL + '/locations'
const EMPLOYEES_API_URL = process.env.REACT_APP_API_URL + '/employees'

export async function getLocations() {
  try {
    const response = await axios.get(`${LOCATIONS_API_URL}/`)
    const groups = response.data

    if (groups && groups.length > 0) {
      return groups
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function getLocationByLocationId(location_id: number | null) {
  try {
    if (!location_id) return undefined
    const response = await axios.get(`${LOCATIONS_API_URL}/location/${location_id}`)

    const group = response.data

    if (group) {
      return group
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function getLocationIdByGroupId(group_id: number | null) {
  try {
    if (!group_id) return undefined
    const response = await axios.get(`${LOCATIONS_API_URL}/location/${group_id}`)

    const group = response.data

    if (group) {
      return group
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function getLaborScheduleActual(location_id: number | null, scheduleDate: Date) {
  try {
    if (!location_id) return undefined

    const response = await axios.get(`${SCHEDULES_API_URL}/labor_schedule_actual`, {
      params: {
        location_id: location_id,
        schedule_date: format(scheduleDate, 'yyyy-MM-dd'),
      },
    })
    const schedules = response.data

    if (schedules) {
      return schedules
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function getLaborPositions(selectedLocationId: number | null) {
  try {
    if (!selectedLocationId) return undefined

    const response = await axios.get(`${EMPLOYEES_API_URL}/positions/${selectedLocationId}`)
    const positions = response.data['positions']

    if (positions) {
      return positions
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function getWeeklyScheduleSummary(
  selectedLocationId: number | null,
  scheduleDate: Date
) {
  try {
    if (!selectedLocationId) return undefined

    const response = await axios.get(`${SCHEDULES_API_URL}/weekly_scheduled_summary`, {
      params: {location_id: selectedLocationId, schedule_date: format(scheduleDate, 'yyyy-MM-dd')},
    })
    const schedules = response.data

    if (schedules) {
      return schedules
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function getEmployees(location_id: number | null, position: string) {
  try {
    const url: string = `${EMPLOYEES_API_URL}/${location_id}/${position}`
    const response = await axios.get(url)
    const employees = response.data

    if (employees) {
      // sort employees first by postion then by first name and last name
      // position rgm > sl > any others
      employees.sort((a: any, b: any) => {
        if (a.position === 'rgm' && b.position !== 'rgm') {
          return -1
        } else if (a.position !== 'rgm' && b.position === 'rgm') {
          return 1
        } else if (a.position === 'sl' && b.position !== 'sl') {
          return -1
        } else if (a.position !== 'sl' && b.position === 'sl') {
          return 1
        } else if (a.position < b.position) {
          return -1
        } else if (a.position > b.position) {
          return 1
        } else if (a.first_name < b.first_name) {
          return -1
        } else if (a.first_name > b.first_name) {
          return 1
        } else if (a.last_name < b.last_name) {
          return -1
        } else if (a.last_name > b.last_name) {
          return 1
        } else {
          return 0
        }
      })

      return employees
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function setEmployeeHideStatus(employee_id: number, hide_in_schedule: boolean){
  try {
    const url = `${EMPLOYEES_API_URL}/update-hide-status`

    const request = {
      employee_id: employee_id,
      hide_in_schedule: hide_in_schedule
    }

    const response = await axios.post(url, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export async function updateLaborScheduleStatus(
  schedule_id: number,
  status: string,
  reason: string
) {
  try {
    if (!status) return undefined

    const url = `${SCHEDULES_API_URL}/labor_schedule_actual/${schedule_id}/status/${status}`
    const response = await axios.put(url, {reason: reason})

    if (response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }

  return undefined
}

export const createShift = async (shift: any, business_date: Date) => {
  try {
    // console.log(shift)
    if (!shift.selectedLaborScheduleId || !shift.selectedLocationId) return undefined
    if (shift.event_id) {
      return updateShift(shift, business_date)
    }
    const payload = {
      location_id: shift.selectedLocationId,
      schedule_id: shift.selectedLaborScheduleId,
      date: format(business_date, 'yyyy-MM-dd'),
      employee_id: shift.employee_id,
      start_time: format(shift.start, 'yyyy-MM-dd HH:mm:ss'),
      end_time: format(shift.end, 'yyyy-MM-dd HH:mm:ss'),
      shift_position: shift.position,
    }

    const response = await axios.post(`${SCHEDULES_API_URL}/labor_shift_actual`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteShift = async (shift_id: number) => {
  try {
    const response = await axios.delete(`${SCHEDULES_API_URL}/labor_shift_actual/${shift_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const updateShift = async (shift: any, business_date: Date) => {
  try {
    if (!shift.selectedLaborScheduleId || !shift.selectedLocationId) return undefined
    const payload = {
      id: shift.event_id,
      location_id: shift.selectedLocationId,
      schedule_id: shift.selectedLaborScheduleId,
      date: format(business_date, 'yyyy-MM-dd'),
      employee_id: shift.employee_id,
      start_time: format(shift.start, 'yyyy-MM-dd HH:mm:ss'),
      end_time: format(shift.end, 'yyyy-MM-dd HH:mm:ss'),
      shift_position: shift.position,
    }

    const response = await axios.put(`${SCHEDULES_API_URL}/labor_shift_actual`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const getShifts = async (start_date: Date, location_id: number) => {
  try {
    const response = await axios.get(`${SCHEDULES_API_URL}/daily_shifts_actual`, {
      params: {
        schedule_date: format(start_date, 'yyyy-MM-dd'),
        location_id: location_id,
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getWeatherForecast = async (location_id: number | null, schedule_date: Date) => {
  try {
    const response = await axios.get(`${SCHEDULES_API_URL}/weather_forecast`, {
      params: {
        location_id: location_id,
        schedule_date: format(schedule_date, 'yyyy-MM-dd'),
      },
    })
    return response.data.weather
  } catch (error) {
    console.log(error)
  }
}

export const getWeeklyShifts = async (start_date: Date, location_id: number | null) => {
  try {
    const response = await axios.get(`${SCHEDULES_API_URL}/weekly_shifts_actual`, {
      params: {
        schedule_date: format(start_date, 'yyyy-MM-dd'),
        location_id: location_id,
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getHoursOfOperations = async (location_id: number | null) => {
  try {
    const response = await axios.get(`${LOCATIONS_API_URL}/hours_of_operations/${location_id}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getAllowableHoursDaily = async (location_id: number | null) => {
  try {
    const response = await axios.get(
      `${SCHEDULES_API_URL}/get_hourly_allowed_employee_count/${location_id}`
    )
    return response.data['allowable_hours_by_day']
  } catch (error) {
    console.log(error)
  }
}

export const getScheduleDaySlotStats = async (location_id: number | null, day_date: Date) => {
  try {
    const response = await axios.get(`${SCHEDULES_API_URL}/schedule_day_slot_stats`, {
      params: {
        location_id: location_id,
        forecast_date: format(day_date, 'yyyy-MM-dd'),
        granularity: 'minutes_15',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const clearLaborSchedule = async (location_id: number | null, schedule_date: string) => {
  try {
    const response = await axios.delete(`${SCHEDULES_API_URL}/clear_schedule`, {
      params: {
        location_id: location_id,
        schedule_date: schedule_date,
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const copyFromPreviousWeek = async (
  location_id: number | null,
  schedule_date: string,
  copy_from_date: Date
) => {
  try {
    const response = await axios.post(`${SCHEDULES_API_URL}/copy_from_previous_weeks`, {
      location_id: location_id,
      schedule_date: schedule_date,
      copy_from_date: format(copy_from_date, 'yyyy-MM-dd'),
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const uploadFromFile = async (
  location_id: number | null,
  schedule_date: string,
  file: any
) => {
  try {
    const formData = new FormData()
    formData.append('location_id', location_id?.toString() || '0')
    formData.append('schedule_date', schedule_date)
    formData.append('file', file)
    const response = await axios.post(`${SCHEDULES_API_URL}/upload_from_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getPDFContent = async (location_id: number | null, schedule_date: string) => {
  try {
    axios
      .get(`${SCHEDULES_API_URL}/generate_pdf_schedule`, {
        params: {
          location_id: location_id,
          schedule_date: schedule_date,
          request_time: new Date(), // this is to prevent caching
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `LaborSchedule_${location_id}_${schedule_date}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  } catch (error) {
    console.log(error)
  }
}

export const validateSchedule = async (location_id: number | null, schedule_date: string) => {
  try {
    const response = await axios.get(`${SCHEDULES_API_URL}/validate_schedule`, {
      params: {
        location_id: location_id,
        schedule_date: schedule_date,
      },
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}
