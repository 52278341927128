/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

const MegaMenu: FC = () => {
  const intl = useIntl()
  return (
    <div className='row'>
      {/* begin:Col */}
      <div className='col-lg-12'>
        {/* begin:Row */}
        <div className='row'>
          {/* begin:Col */}
          <div className='col-lg-3 mb-3'>
            {/* begin:Heading */}
            <h4 className='fs-6 text-gray-800 fw-semibold mt-3 mb-3 ms-4'>
              {intl.formatMessage({id: 'MENU.ANALYTICS'})}
            </h4>
            {/* end:Heading */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/analytics/interactive-dashboards'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-pie-chart-fill'></i>
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.INTERACTIVE_DASHBOARDS'})}
                </span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/analytics/dataset-requests'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-filetype-csv'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.DATA_EXPORTS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/analytics/adhoc-report'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-file-arrow-down-fill'></i>
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.DOWNLOAD_REPORTS'})}
                </span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/scheduled-labor'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-compass-fill'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.FORECASTS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/admin/company-metrics'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-speedometer2'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.METRICS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/admin/input/targets'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-vinyl'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.TARGETS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            {/* <div className='menu-item p-0 m-0'> */}
            {/* begin:Menu link */}
            {/* <a onClick={() => setLayoutType('dark-header')} className='menu-link'> */}
            {/* <span className='menu-bullet'> */}
            {/* <span className='bullet bullet-dot bg-gray-300i h-6px w-6px'></span> */}
            {/* </span> */}
            {/* <span className='menu-title'>{intl.formatMessage({id: 'MENU.FDS_ANALYTICS'})}</span> */}
            {/* </a> */}
            {/* end:Menu link */}
            {/* </div> */}
            {/* end:Menu item */}
          </div>
          {/* end:Col */}
          {/* begin:Col */}
          <div className='col-lg-3 mb-3'>
            {/* begin:Heading */}
            <h4 className='fs-6 text-gray-800 fw-semibold mt-3 mb-3 ms-4'>
              {intl.formatMessage({id: 'MENU.LABOR_SCHEDULING'})}
            </h4>
            {/* end:Heading */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/labor-scheduling'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-person-fill-check'></i>
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.CREATE_SCHEDULE'})}
                </span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/scheduled-labor/tracker'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-hourglass-split'></i>
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.SCHEDULED_HOURS_BY_LOCATION'})}
                </span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/labor/view-schedules'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-calendar-event-fill'></i>
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.VIEW_WEEKLY_SCHEDULES'})}
                </span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/labor/employees'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-person-badge-fill'></i>
                </span>
                <span className='menu-title'>
                  {intl.formatMessage({id: 'MENU.EMPLOYEE_ROSTERS'})}
                </span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
          </div>
          {/* end:Col */}
          {/* begin:Col */}
          <div className='col-lg-3 mb-3'>
            {/* begin:Heading */}
            <h4 className='fs-6 text-gray-800 fw-semibold mt-3 mb-3 ms-4'>
              {intl.formatMessage({id: 'MENU.MAINTENANCE'})}
            </h4>
            {/* end:Heading */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/maintenance/tasks'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-ui-checks'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.TASKS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/maintenance/assets'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-tools'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.ASSETS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/maintenance/vendors'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-shop'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.VENDORS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
          </div>
          {/* end:Col */}
          {/* begin:Col */}
          <div className='col-lg-3 mb-3'>
            {/* begin:Heading */}
            <h4 className='fs-6 text-gray-800 fw-semibold mt-3 mb-3 ms-4'>
              {intl.formatMessage({id: 'MENU.FORMS'})}
            </h4>
            {/* end:Heading */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/forms'} className='menu-link'>
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-info-circle-fill'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.FORM_DETAILS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/general/main'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-card-text'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.GENERAL_FORMS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/audit/main'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-search'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.AUDIT_FORMS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
            {/* begin:Menu item */}
            <div className='menu-item p-0 m-0'>
              {/* begin:Menu link */}
              <a
                href={process.env.REACT_APP_OLD_FLASK_APP_URL + '/forms/past'}
                className='menu-link'
              >
                <span className='menu-icon'>
                  <i className='bi fs-3 bi-clock-history'></i>
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.PAST_FORMS'})}</span>
              </a>
              {/* end:Menu link */}
            </div>
            {/* end:Menu item */}
          </div>
          {/* end:Col */}
        </div>
        {/* end:Row */}
        {/* <div className='separator separator-dashed mx-lg-5 mt-2 mb-6'></div> */}
        {/* begin:Layout Builder */}
        {/* <div className='d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mb-5 mb-lg-0 mx-lg-5'> */}
        {/* <div className='d-flex flex-column me-5'> */}
        {/* <div className='fs-6 fw-bold text-gray-800'>Layout Builder</div> */}
        {/* <div className='fs-7 fw-semibold text-muted'>Customize view</div> */}
      </div>
      {/* <Link to='/builder' className='btn btn-sm btn-primary fw-bold'> */}
      {/* Try Builder */}
      {/* </Link> */}
      {/* </div> */}
      {/* end:Layout Builder */}
      {/* </div> */}
      {/* end:Col */}
      {/* begin:Col */}
      {/* <div className='col-lg-6 mb-3 py-lg-3 pe-lg-8 d-flex align-items-center'> */}
      {/* <img src={toAbsoluteUrl('/media/stock/900x600/45.jpg')} className='rounded mw-100' alt='' /> */}
      {/* </div> */}
      {/* end:Col */}
    </div>
  )
}

export {MegaMenu}
