/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark nav nav-dark order-2 order-md-1'>
        <a
          href='https://www.fdsreporting.com/'
          target='_blank'
          className='text-gray-800 text-hover-primary nav-link'
        >
          <span className='text-muted font-weight-bold mr-2'>
            {new Date().getFullYear().toString()}&nbsp;&copy;&nbsp;
          </span>
          FDS Reporting
        </a>
      </div>
      <div className='nav nav-dark'>
        <a
          href='https://fdsreporting.com/about-us/'
          target='_blank'
          className='text-gray-800 text-hover-primary nav-link pl-0 pr-5'
        >
          About
        </a>
        <a
          href={`mailto: support@franchisedatasolutions.com?subject=Support%20Request`}
          target='_blank'
          className='text-gray-800 text-hover-primary nav-link pl-0 pr-0'
        >
          Contact Support
        </a>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-fds-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-fds-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
