/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useContext, useRef, useMemo} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../_fds/helpers'
import {getCSS, getCSSVariableValue} from '../../../../_fds/assets/ts/_utils'
import {useThemeMode} from '../../../../_fds/partials/layout/theme-mode/ThemeModeProvider'
import WeeklySummaryContext from '../context/WeeklySummaryContext'
import {WeeklySummaryModel} from '../core/_models'
import {useStore} from '../store'

type Props = {
  className: string
}

const WeeklySummaryChart: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const weeklySummaryContext = useContext(WeeklySummaryContext)
  const {dailyLaborCost} = useStore()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, weeklySummaryContext])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(weeklySummaryContext, height, dailyLaborCost)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }
  const renderChat = () => {
    return (
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-semibold fs-3 mb-1'>Projected vs Target Labor</span>

            <span className='text-muted fw-semibold fs-7'>Projected vs Target Labor</span>
          </h3>
          {/* end::Title */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          {/* begin::Chart */}
          <div ref={chartRef} id='weekly-summary-chart' style={{height: '350px'}} />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    )
  }

  /* useEffect(() => {
    // console.log('dailyLaborCost', dailyLaborCost)
  }, [dailyLaborCost])

  useEffect(() => {
    // console.log('weeklySummaryContext', weeklySummaryContext)
  }, [weeklySummaryContext]) */

  const chart = useMemo(() => {
    // console.log('RENDERING CHARTTTTTTTTTTTTTT')
    return renderChat()
  }, [dailyLaborCost, weeklySummaryContext])
  return chart
}

export {WeeklySummaryChart}

function getChartOptions(
  weeklySummary: WeeklySummaryModel | null,
  height: number,
  dailyLaborCost: Map<string, number>
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  const projectedLabors: number[] = []
  const targetLabors: number[] = []
  const labels: string[] = []

  if (weeklySummary) {
    Object.keys(weeklySummary.weekly_summary)
      .sort()
      .forEach((date) => {
        if (date !== 'week') {
          const projectedLabor =
            (dailyLaborCost.get(date) || 0) / weeklySummary.weekly_summary[date].forecasted_value
          const projectedLaborPct =
            isNaN(projectedLabor) || !isFinite(projectedLabor) ? 0 : projectedLabor * 100
          projectedLabors.push(projectedLaborPct)
          targetLabors.push(weeklySummary.weekly_summary[date].hourly_labor_target * 100)
          labels.push(weeklySummary.weekly_summary[date].weekday)
        }
      })
  }

  return {
    series: [
      {
        name: 'Projected Labor',
        data: projectedLabors,
      },
      {
        name: 'Target Labor',
        data: targetLabors,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          return val.toFixed(1) + '%'
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toFixed(1) + ' %'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
