import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useAuth} from '../../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const isAdmin = ['Admin', 'Creator'].includes(currentUser?.role?.name || '')

  return (
    <>
      <MenuInnerWithSub
        isMega={true}
        title={intl.formatMessage({id: 'MENU.MODULES'})}
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
        hasArrow={true}
      >
        <MegaMenu />
      </MenuInnerWithSub>
      <MenuItem
        title={intl.formatMessage({id: 'MENU.DASHBOARDS'})}
        to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/analytics/interactive-dashboards'}
      />
      {isAdmin && (
        <MenuInnerWithSub
          title={intl.formatMessage({id: 'MENU.ADMIN'})}
          to='/crafted'
          menuPlacement='bottom-start'
          menuTrigger='click'
          hasArrow={true}
        >
          <MenuItem
            to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/admin/users'}
            fontIcon='bi-person-fill'
            title={intl.formatMessage({id: 'MENU.USERS'})}
          />
          <MenuItem
            to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/admin/hierarchy'}
            fontIcon='bi-send-fill'
            title={intl.formatMessage({id: 'MENU.LOCATIONS'})}
          />
          <MenuItem
            to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/admin/inputs/targets'}
            fontIcon='bi-envelope-fill'
            title={intl.formatMessage({id: 'MENU.INTERNAL_ANNOUNCEMENTS'})}
          />
          <MenuItem
            to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/admin/commentary'}
            fontIcon='bi-journal-text'
            title={intl.formatMessage({id: 'MENU.COMMENTARY'})}
          />
          <MenuItem
            to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/labor/hours-of-operation'}
            fontIcon='bi-clock-fill'
            title={intl.formatMessage({id: 'MENU.HOURS_OF_OPERATION'})}
          />
          <MenuItem
            to={process.env.REACT_APP_OLD_FLASK_APP_URL + '/ops/calendar-event'}
            fontIcon='bi-calendar2-range-fill'
            title={intl.formatMessage({id: 'MENU.CALENDAR_EVENTS'})}
          />
        </MenuInnerWithSub>
      )}
      {/* <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.FDS_INTERNAL'})}
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/crafted/pages/profile/overview' fontIcon='bi-archive' title={intl.formatMessage({id: 'MENU.EMAIL_SCHEDULING'})} />
        <MenuItem to='/crafted/pages/profile/overview' fontIcon='bi-archive' title={intl.formatMessage({id: 'MENU.EMAIL_RECIPIENTS'})} />
        <MenuItem to='/crafted/pages/profile/overview' fontIcon='bi-archive' title={intl.formatMessage({id: 'MENU.MODULE_LICENSING'})} />
        <MenuItem to='/crafted/pages/profile/overview' fontIcon='bi-archive' title={intl.formatMessage({id: 'MENU.COMPANY_CREDENTIALS'})} />
        <MenuItem to='/crafted/pages/profile/overview' fontIcon='bi-archive' title={intl.formatMessage({id: 'MENU.COMPANY_CONFIGS'})} />
      </MenuInnerWithSub> */}
    </>
  )
}
