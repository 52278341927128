import {useEffect, useCallback, Fragment, useMemo, useRef} from 'react'
import {
  startOfWeek,
  addDays,
  format,
  eachMinuteOfInterval,
  isSameDay,
  isBefore,
  isToday,
  setMinutes,
  setHours,
  isWithinInterval,
  isAfter,
  endOfDay,
  startOfDay,
  addMinutes,
  isEqual,
} from 'date-fns'
import TodayTypo from '../common/TodayTypo'
import EventItem from '../events/EventItem'
import {CellRenderedProps, DayHours, DefaultRecourse, ProcessedEvent} from '../../types'
import {
  calcCellHeight,
  calcMinuteHeight,
  differenceInDaysOmitTime,
  filterEventsBetween,
  filterMultiDaySlot,
  filterTodayEvents,
  filterTodayEventsForResource,
  getHoursOfOperationOnDay,
  getResourcedEvents,
  getTimeZonedDate,
  getAllowableValues,
  WeekDays,
  getOverTimeHours,
} from '../../helpers/generals'
import {WithResources} from '../common/WithResources'
import Cell from '../common/Cell'
import TodayEvents from '../events/TodayEvents'
import {TableGrid, TableGridTimeline} from '../../styles/styles'
import {MULTI_DAY_EVENT_HEIGHT} from '../../helpers/constants'
import {useStore} from '../../store'
import useSyncScroll from '../../hooks/useSyncScroll'
import {useState} from 'react'
import {alpha, Avatar, useTheme} from '@mui/material'
import {Nav, Tab} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_fds/helpers'
import {ChartSummary} from '../../../../../_fds/partials/widgets'

export interface WeekProps {
  weekDays: WeekDays[]
  weekStartOn: WeekDays
  startHour: DayHours
  endHour: DayHours
  step: number
  cellRenderer?(props: CellRenderedProps): JSX.Element
  navigation?: boolean
  disableGoToDay?: boolean
  containerWidth?: number | null
}

export interface TimelineProps {
  containerWidth?: number | null
}

const Timeline = ({containerWidth, ...rest}: TimelineProps) => {
  const {
    week,
    selectedDate,
    height,
    events,
    handleGotoDay,
    getRemoteEvents,
    triggerLoading,
    handleState,
    resources,
    resourceFields,
    resourceViewMode,
    fields,
    direction,
    locale,
    hourFormat,
    timeZone,
    hoursOfOperations,
    draggable,
    allowableHoursDaily,
    selectedPosition,
    availablePositions,
  } = useStore()
  const {weekStartOn, weekDays, startHour, endHour, step, cellRenderer, disableGoToDay} = week!
  const _weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
  const daysList = weekDays.map((d) => addDays(_weekStart, d))
  const weekStart = startOfDay(daysList[0])
  const weekEnd = endOfDay(daysList[daysList.length - 1])
  // use integer part of startHour for hour and decimal part for minutes
  // get decimal part of startHour
  const [dayStart, dayEnd] = getHoursOfOperationOnDay(selectedDate, hoursOfOperations)
  const startHourDecimal = startHour - Math.floor(startHour)
  const START_TIME = dayStart //setMinutes(setHours(selectedDate, startHour), startHourDecimal * 60)
  // use integer part of endHour for hour and decimal part for minutes
  // get decimal part of endHour
  const endHourDecimal = endHour - Math.floor(endHour)
  const END_TIME = addMinutes(dayEnd, -30) //setMinutes(setHours(selectedDate, endHour), endHourDecimal * 60)

  // if END_TIME is before START_TIME, it means that the day is over midnight
  // so we need to add 24 hours to the end time
  if (isBefore(END_TIME, START_TIME) || isEqual(END_TIME, START_TIME)) {
    END_TIME.setHours(END_TIME.getHours() + 24)
  }

  const hours = eachMinuteOfInterval(
    {
      start: START_TIME,
      end: END_TIME,
    },
    {step: step}
  )
  const CELL_HEIGHT = calcCellHeight(height, hours.length)
  const CELL_WIDTH = ((containerWidth || 200) - 200) / hours.length
  const MINUTE_WIDTH = calcMinuteHeight(CELL_WIDTH, step) // todo: needs to calculate this 66 somehow
  const MINUTE_HEIGHT = calcMinuteHeight(CELL_HEIGHT, step)
  const MULTI_SPACE = MULTI_DAY_EVENT_HEIGHT
  const hFormat = hourFormat === '12' ? 'hh:mm a' : 'HH:mm'
  const {headersRef, bodyRef} = useSyncScroll()
  const {triggerDialog, onDrop} = useStore()

  // const [cellMouseDown, setCellMouseDown] = useState<HTMLElement | null>(null)
  // const [cellMouseUp, setCellMouseUp] = useState<HTMLElement | null>(null)
  // const [selectedStartCell, setSelectedStartCell] = useState<HTMLElement | null>(null)
  // const [selectedEndCell, setSelectedEndCell] = useState<HTMLElement | null>(null)

  const cellMouseDownRef = useRef<any>(null)
  const cellMouseUpRef = useRef<any>(null)
  const selectedStartCellRef = useRef<HTMLElement | null>(null)
  const selectedEndCellRef = useRef<HTMLElement | null>(null)

  const theme = useTheme()
  const colorCells = (cellsToColor: Array<HTMLElement>, colorValue: string) => {
    cellsToColor.forEach((cell) => {
      cell.style.backgroundColor = colorValue
    })
  }

  const sortedResources = resources.sort((a, b) => {
    const employeeIdA = a.employee_id
    const employeeIdB = b.employee_id
    const occurrenceA = +(
      events
        .filter((x) => x.start.getDate() === selectedDate.getDate())
        .findIndex((obj) => obj.employee_id === employeeIdA) > -1
    )
    const occurrenceB = +(
      events
        .filter((x) => x.start.getDate() === selectedDate.getDate())
        .findIndex((obj) => obj.employee_id === employeeIdB) > -1
    )

    if (occurrenceA === occurrenceB) {
      if (a.position === 'rgm' && b.position !== 'rgm') {
        return -1
      } else if (a.position !== 'rgm' && b.position === 'rgm') {
        return 1
      } else if (a.position === 'sl' && b.position !== 'sl') {
        return -1
      } else if (a.position !== 'sl' && b.position === 'sl') {
        return 1
      } else if (a.position < b.position) {
        return -1
      } else if (a.position > b.position) {
        return 1
      } else if (a.first_name < b.first_name) {
        return -1
      } else if (a.first_name > b.first_name) {
        return 1
      } else if (a.last_name < b.last_name) {
        return -1
      } else if (a.last_name > b.last_name) {
        return 1
      } else {
        return 0
      }
    }
    return occurrenceB - occurrenceA
  })

  const calculateCellsToSelect = (
    cells: Array<HTMLElement>,
    startCell: HTMLElement,
    endCell: HTMLElement
  ) => {
    const startCellIndex = cells.indexOf(startCell)
    const endCellIndex = cells.indexOf(endCell)

    // get all cells between selectedStartCell and selectedEndCell in the same column where it needs to be incremented by 7
    const cellsToSelect = cells.filter((cell: HTMLElement, index: number) => {
      if (startCellIndex < endCellIndex) {
        return index >= startCellIndex && index <= endCellIndex
      } else {
        return index <= startCellIndex && index >= endCellIndex
      }
    })
    return cellsToSelect
  }

  // useEffect(() => {
  const colorSelectedCells = (clear?: boolean) => {
    const cells = document.querySelectorAll<HTMLElement>('.rs__cell > button')
    const cellsArray = Array.from(cells)
    if (selectedStartCellRef.current === null || clear) {
      colorCells(cellsArray, '')
      return
    }
    const endCell = selectedEndCellRef.current || selectedStartCellRef.current

    const cellsToSelect = calculateCellsToSelect(cellsArray, selectedStartCellRef.current, endCell)

    colorCells(cellsToSelect, alpha(theme.palette.secondary.main, 0.3))

    const cellsToDeselect = cellsArray.filter((cell, index) => {
      return !cellsToSelect.includes(cell)
    })
    colorCells(cellsToDeselect, '')
  } //, [selectedStartCellRef.current, selectedEndCellRef.current])

  const handleMouseLeave = (e: any, props: any) => {
    // if (e.button !== 0) return
  }

  const handleMouseDown = (e: any, props: any) => {
    if (e.button !== 0) return
    // setCellMouseDown(props)
    // setSelectedStartCell(e.currentTarget)
    // setSelectedEndCell(e.currentTarget)
    cellMouseDownRef.current = props
    selectedStartCellRef.current = e.currentTarget
    selectedEndCellRef.current = e.currentTarget
    colorSelectedCells()
  }

  const handleMouseUp = (e: any, props: any) => {
    if (e.button !== 0) return
    if (cellMouseDownRef.current && selectedEndCellRef.current) {
      // debugger

      // setCellMouseUp(props)
      cellMouseUpRef.current = props
      const cells = document.querySelectorAll<HTMLElement>('.rs__cell > button')
      const cellsArray = Array.from(cells)
      // colorCells(cellsArray, '')
      const minStart = new Date(Math.min(cellMouseDownRef.current.start, props.start))
      const maxEnd = new Date(Math.max(cellMouseDownRef.current.end, props.end))
      const cellMouseTemp = {
        start: minStart,
        end: maxEnd,
        businessDay: cellMouseDownRef.current.day,
        [cellMouseDownRef.current.resourceKey]: cellMouseDownRef.current.resourceVal,
        [cellMouseDownRef.current.positionKey]: cellMouseDownRef.current.positionVal,
      }
      // setCellMouseDown(null)
      // setCellMouseUp(null)
      cellMouseDownRef.current = null
      cellMouseUpRef.current = null
      colorSelectedCells(true)
      triggerDialog(true, cellMouseTemp)
    } else {
      // setSelectedStartCell(null)
      selectedStartCellRef.current = null
      colorSelectedCells(true)
    }
  }

  const handleMouseEnter = (e: any, props: any) => {
    if (e.button !== 0) return
    if (cellMouseDownRef.current) {
      if (
        cellMouseDownRef.current.resourceVal !== props.resourceVal ||
        cellMouseDownRef.current.day.getDate() !== props.day.getDate()
      ) {
        // setSelectedEndCell(null)
        selectedEndCellRef.current = null

        colorSelectedCells(true)
      } else {
        // setSelectedEndCell(e.currentTarget)
        selectedEndCellRef.current = e.currentTarget
        colorSelectedCells(false)
      }
    }
  }
  const multiSelectProps = {
    handleMouseDown,
    handleMouseUp,
    handleMouseEnter,
    handleMouseLeave,
  }

  const renderTable = (resource?: DefaultRecourse) => {
    let recousedEvents =
      selectedPosition === 'all'
        ? events
        : events.filter((e) =>
            resources
              .filter((emp) => emp.position === selectedPosition)
              .some((emp) => emp.employee_id === e.employee_id)
          )
    if (resource) {
      recousedEvents = getResourcedEvents(
        selectedPosition === 'all'
          ? events
          : events.filter((e) =>
              resources
                .filter((emp) => emp.position === selectedPosition)
                .some((emp) => emp.employee_id === e.employee_id)
            ),
        resource,
        resourceFields,
        fields
      )
    }

    // Equalizing multi-day section height except in resource/tabs mode
    const shouldEqualize = resources.length && resourceViewMode !== 'tabs'
    const allWeekMulti = filterMultiDaySlot(
      shouldEqualize ? events : recousedEvents,
      daysList,
      timeZone
    )

    const headerHeight = MULTI_SPACE * allWeekMulti.length + 45

    const allowableValues = getAllowableValues(
      allowableHoursDaily,
      selectedDate,
      selectedPosition,
      hours
    )

    const otHours = getOverTimeHours(events)
    // get sum of values in object
    const totalOtHours = Object.values(otHours).reduce((a, b) => a + b, 0)
    // get count of the keys in object
    const totalOtEmp = Object.keys(otHours).length

    return (
      <>
        {/* Time Cells */}
        <div
          style={{
            position: 'sticky',
            top: 'calc(var(--bs-app-header-height) + var(--bs-app-toolbar-height) + 89px)',
            zIndex: 13,
          }}
        >
          <TableGridTimeline hours={hours.length} ref={headersRef} sticky='0'>
            <div className='rs__cell rs__header'>
              {totalOtHours ? (
                <span
                  style={{height: 100, width: 200}}
                  className='rs__cell rs__header rs__time bg-light'
                >
                  OT:{' '}
                  {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(totalOtHours)}{' '}
                  hrs <br />
                  OT Emp: {totalOtEmp}
                </span>
              ) : (
                <span
                  style={{height: 100, width: 200}}
                  className='rs__cell rs__header rs__time bg-light'
                ></span>
              )}
            </div>
            {hours.map((h, i) => (
              <div key={i} className='rs__cell rs__header'>
                <span
                  style={{height: 100}}
                  className='rs__cell rs__header rs__time rs__rotated bg-light'
                >
                  <span className='fs-7 m-0 text-gray-900'>{format(h, hFormat, {locale})}</span>
                </span>
              </div>
            ))}
          </TableGridTimeline>
          {/* Max Row */}
          <TableGridTimeline hours={hours.length} ref={headersRef} sticky='0'>
            <div className='rs__cell rs__header'>
              <span
                style={{height: 25, width: 200}}
                className='rs__cell rs__header rs__time bg-light'
              >
                <span className='fs-7 m-0 text-gray-900'>Max headcount</span>
              </span>
            </div>
            {hours.map((h, i) => (
              <div key={i} className='rs__cell rs__header'>
                <span
                  style={{height: 25}}
                  className={`rs__cell rs__header rs__time ${
                    allowableValues[i].is_strict ? 'bg-primary' : 'bg-light'
                  }`}
                >
                  <span
                    className={`fs-7 m-0 text-gray-900 ${
                      allowableValues[i].is_strict ? 'fw-bold' : ''
                    }`}
                  >
                    {allowableValues[i].number_of_employees}
                  </span>
                </span>
              </div>
            ))}
          </TableGridTimeline>
          {/* Min Row */}
          <TableGridTimeline hours={hours.length} ref={headersRef} sticky='0'>
            <div className='rs__cell rs__header'>
              <span
                style={{height: 25, width: 200}}
                className='rs__cell rs__header rs__time bg-light'
              >
                <span className='fs-7 m-0 text-gray-900'>Min headcount</span>
              </span>
            </div>
            {hours.map((h, i) => (
              <div key={i} className='rs__cell rs__header'>
                <span
                  style={{height: 25}}
                  className={`rs__cell rs__header rs__time ${
                    allowableValues[i].is_strict ? 'bg-primary' : 'bg-light'
                  }`}
                >
                  <span
                    className={`fs-7 m-0 text-gray-900 ${
                      allowableValues[i].is_strict ? 'fw-bold' : ''
                    }`}
                  >
                    {allowableValues[i].number_of_employees}
                  </span>
                </span>
              </div>
            ))}
          </TableGridTimeline>
          {/* Used Row */}
          <TableGridTimeline hours={hours.length} ref={headersRef} sticky='0'>
            <div className='rs__cell rs__header'>
              <span
                style={{height: 25, width: 200}}
                className='rs__cell rs__header rs__time bg-light'
              >
                <span className='fs-7 m-0 text-gray-900'>Headcount used</span>
              </span>
            </div>
            {hours.map((h, i) => {
              const headCountsUsed = filterEventsBetween(
                recousedEvents,
                h,
                addMinutes(h, step),
                undefined,
                true,
                availablePositions
              ).length
              const minHeadCounts = allowableValues[i].number_of_employees
              const maxHeadCounts = allowableValues[i].number_of_employees

              return (
                <div key={i} className='rs__cell rs__header'>
                  <span
                    style={{height: 25}}
                    className={`rs__cell rs__header rs__time ${
                      headCountsUsed < minHeadCounts ? 'bg-warning' : ''
                    } ${headCountsUsed > maxHeadCounts ? 'bg-danger' : ''} ${
                      headCountsUsed <= maxHeadCounts && headCountsUsed >= minHeadCounts
                        ? 'bg-success'
                        : ''
                    }`}
                  >
                    <span className={`fs-7 m-0 `}>{headCountsUsed}</span>
                  </span>
                </div>
              )
            })}
          </TableGridTimeline>
        </div>
        {/* Resource Rows */}
        {sortedResources.map((res, i) => {
          const text = res[resourceFields.textField]
          const subtext = res[resourceFields.subTextField || '']
          const avatar = res[resourceFields.avatarField || '']
          const color = res[resourceFields.colorField || '']
          const positionField = 'position'

          return (
            <TableGridTimeline key={i} hours={hours.length} ref={bodyRef}>
              <div>
                <span
                  style={{height: CELL_HEIGHT, width: 200}}
                  className='rs__cell rs__header rs__resource bg-light'
                >
                  <span
                    className='fs-7 m-0 text-gray-900 rs__hover__op'
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                  >
                    {/*<Avatar
                      style={{background: color, marginRight: 5}}
                      alt={text}
                      src={toAbsoluteUrl(avatar)}
                    />*/}
                    <span
                      className='fs-7 m-0 text-gray-900'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          marginRight: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleState('week', 'view')
                          handleState(res[resourceFields.idField], 'selectedResource')
                        }}
                      >
                        {text.toUpperCase()}
                      </span>
                      <span
                        className='fw-bold'
                        style={{
                          marginRight: 'auto',
                          color: color,
                        }}
                      >
                        {subtext.toUpperCase()}
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              {hours.map((h, i) => {
                const start = new Date(
                  `${format(selectedDate, 'yyyy/MM/dd')} ${format(h, hFormat)}`
                )
                const end = new Date(
                  `${format(selectedDate, 'yyyy/MM/dd')} ${format(addMinutes(h, step), hFormat)}`
                )
                // if hour of start is before the hour of the first element in hours add 24 hours to start
                if (h.getHours() < hours[0].getHours()) {
                  start.setDate(start.getDate() + 1)
                }
                // if hour of end is before the hour of the first element in hours add 24 hours to end
                if (addMinutes(h, step).getHours() < hours[0].getHours()) {
                  end.setDate(end.getDate() + 1)
                }
                return (
                  <div key={i} className='rs__cell rs__header'>
                    {/* Events of this day - run once on the first hour column */}
                    {i === 0 && (
                      <TodayEvents
                        todayEvents={filterTodayEventsForResource(
                          recousedEvents,
                          selectedDate,
                          res,
                          resourceFields,
                          fields,
                          timeZone
                        )}
                        today={START_TIME}
                        minuteHeight={MINUTE_WIDTH}
                        startHour={START_TIME.getHours()}
                        height={CELL_HEIGHT}
                        step={step}
                        direction={direction}
                        view='timeline'
                      />
                    )}
                    <Cell
                      start={start}
                      end={end}
                      day={selectedDate}
                      hoursOfOperations={hoursOfOperations}
                      draggable={draggable || true}
                      height={CELL_HEIGHT}
                      resourceKey={resourceFields.idField}
                      resourceVal={res[resourceFields.idField]}
                      positionKey={positionField}
                      positionVal={res[positionField]}
                      cellRenderer={cellRenderer}
                      {...multiSelectProps}
                    />
                  </div>
                )
              })}
            </TableGridTimeline>
          )
        })}
      </>
    )
  }
  const tableContent = useMemo(() => {
    return renderTable()
  }, [resources, selectedPosition, events, selectedDate, containerWidth])

  return tableContent
}

export {Timeline}
