import {WeekDateBtn} from './WeekDateBtn'
import {DayDateBtn} from './DayDateBtn'
import {useStore} from '../../store'

export type View = 'week' | 'timeline' | 'employeesWeek'

const Navigation = () => {
  const {selectedDate, view, week, handleState, navigation, day} = useStore()

  const renderDateSelector = () => {
    switch (view) {
      case 'week':
        return (
          week?.navigation && (
            <WeekDateBtn selectedDate={selectedDate} onChange={handleState} weekProps={week!} />
          )
        )
      case 'employeesWeek':
        return (
          week?.navigation && (
            <WeekDateBtn selectedDate={selectedDate} onChange={handleState} weekProps={week!} />
          )
        )
      // case 'day':
      //   return day?.navigation && <DayDateBtn selectedDate={selectedDate} onChange={handleState} />
      case 'timeline':
        return day?.navigation && <DayDateBtn selectedDate={selectedDate} onChange={handleState} />
      default:
        return ''
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div data-testid='date-navigator'>{navigation && renderDateSelector()}</div>
    </div>
  )
}

export {Navigation}
