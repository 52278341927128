/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useMemo} from 'react'
import WeeklySummaryContext from '../context/WeeklySummaryContext'
import {useStore} from '../store'

type Props = {
  className: string
}

const TableSummary: React.FC<Props> = ({className}) => {
  const weeklySummaryContext = useContext(WeeklySummaryContext)
  const {dailyHours, dailyLaborCost} = useStore()

  const wrapper = useMemo(() => {
    return (
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-semibold fs-3 mb-1'>Summary Table</span>
            <span className='text-muted fw-semibold fs-7'>Weekly Summary</span>
          </h3>
          <div className='card-toolbar'></div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed align-middle text-center gs-0 gy-5'>
              {/* begin::Table head */}
              <thead>
                <tr>
                  <th className='p-1'></th>
                  {
                    /* iterate over weekly_summary keys and put weekday field of each day here */
                    weeklySummaryContext &&
                      Object.keys(weeklySummaryContext.weekly_summary).map((key) => (
                        <th className='p-1' key={key}>
                          {weeklySummaryContext.weekly_summary[key].weekday}
                        </th>
                      ))
                  }
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <th>Forecasted Sales</th>
                  {
                    /* iterate over weekly_summary keys and put weekday field of each day here */
                    weeklySummaryContext &&
                      Object.keys(weeklySummaryContext.weekly_summary).map((key) => (
                        <th className='p-1' key={key}>
                          $
                          {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(
                            weeklySummaryContext.weekly_summary[key].forecasted_value
                          )}
                        </th>
                      ))
                  }
                </tr>
                <tr>
                  <th>Scheduled Hours</th>
                  {
                    /* iterate over weekly_summary keys and put weekday field of each day here */
                    weeklySummaryContext &&
                      Object.keys(weeklySummaryContext.weekly_summary).map((key) => (
                        <th className='p-1' key={key}>
                          {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(
                            dailyHours.get(key) || 0
                          )}
                        </th>
                      ))
                  }
                </tr>
                <tr>
                  <th>Allowable Hours</th>
                  {
                    /* iterate over weekly_summary keys and put weekday field of each day here */
                    weeklySummaryContext &&
                      Object.keys(weeklySummaryContext.weekly_summary).map((key) => (
                        <th className='p-1' key={key}>
                          {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(
                            weeklySummaryContext.weekly_summary[key].allowable_hours
                          )}
                        </th>
                      ))
                  }
                </tr>
                <tr>
                  <th>Projected Labor</th>
                  {
                    /* iterate over weekly_summary keys and put weekday field of each day here */
                    weeklySummaryContext &&
                      Object.keys(weeklySummaryContext.weekly_summary).map((key) => {
                        const projectedLabor =
                          ((dailyLaborCost.get(key) || 0) /
                            weeklySummaryContext.weekly_summary[key].forecasted_value) *
                          100
                        return (
                          <th className='p-1' key={key}>
                            {`${
                              isNaN(projectedLabor) || !isFinite(projectedLabor)
                                ? '-'
                                : new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(
                                    projectedLabor
                                  ) + '%'
                            }`}
                          </th>
                        )
                      })
                  }
                </tr>
                <tr>
                  <th>Target Labor</th>
                  {
                    /* iterate over weekly_summary keys and put weekday field of each day here */
                    weeklySummaryContext &&
                      Object.keys(weeklySummaryContext.weekly_summary).map((key) => (
                        <th className='p-1' key={key}>
                          {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(
                            weeklySummaryContext.weekly_summary[key].hourly_labor_target * 100
                          )}
                          %
                        </th>
                      ))
                  }
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    )
  }, [weeklySummaryContext, dailyHours, dailyLaborCost])

  return wrapper
}

export {TableSummary}
