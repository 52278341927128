/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_fds/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    window.location.replace(process.env.REACT_APP_OLD_FLASK_APP_URL + '/login')
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return <div className='d-flex flex-column flex-lg-row flex-column-fluid h-700px'></div>
}

export {AuthLayout}
