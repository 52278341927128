import React from 'react'

interface LoadingStateContextProps {
  loadingState: boolean | null
  setLoadingState: (LoadingState: boolean) => void
}

const LoadingStateContext = React.createContext<LoadingStateContextProps>({
  loadingState: null,
  setLoadingState: () => {},
})

export const LoadingStateProvider = LoadingStateContext.Provider
export default LoadingStateContext
