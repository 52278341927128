import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import {IconButton} from '@mui/material'
import {MouseEvent} from 'react'
import {useStore} from '../../store'

interface LocaleArrowProps {
  type: 'prev' | 'next'
  onClick?(e?: MouseEvent): void
}
const LocaleArrow = ({type, onClick}: LocaleArrowProps) => {
  const {direction} = useStore()

  let icon = 'bi-caret-right-fill'
  if (type === 'prev') {
    icon = direction === 'rtl' ? 'bi-caret-right-fill' : 'bi-caret-left-fill'
  } else if (type === 'next') {
    icon = direction === 'rtl' ? 'bi-caret-left-fill' : 'bi-caret-right-fill'
  }

  return (
    <i
      className={'btn bi ' + icon}
      style={{padding: 2}}
      onClick={onClick}
      onDragOver={(e) => {
        e.preventDefault()
        if (onClick) {
          onClick()
        }
      }}
    ></i>
  )
}

export {LocaleArrow}
