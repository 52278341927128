/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const TableSummary: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-semibold fs-3 mb-1'>Summary Table</span>
          <span className='text-muted fw-semibold fs-7'>Weekly Summary</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-1'></th>
                <th className='p-1'>Week</th>
                <th className='p-1'>Monday</th>
                <th className='p-1'>Tuesday</th>
                <th className='p-1'>Wednesday</th>
                <th className='p-1'>Thursday</th>
                <th className='p-1'>Friday</th>
                <th className='p-1'>Saturday</th>
                <th className='p-1'>Sunday</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <th>Forecasted Sales</th>
                <td>$695</td>
                <td>$106</td>
                <td>$110</td>
                <td>$91</td>
                <td>$56</td>
                <td>$90</td>
                <td>$101</td>
                <td>$141</td>
              </tr>
              <tr>
                <th>Scheduled Hours</th>
                <td>40</td>
                <td>8</td>
                <td>8</td>
                <td>8</td>
                <td>8</td>
                <td>8</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Allowable Hours</th>
                <td>44</td>
                <td>9</td>
                <td>9</td>
                <td>9</td>
                <td>9</td>
                <td>8</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Projected Labor</th>
                <td>80%</td>
                <td>75%</td>
                <td>80%</td>
                <td>90%</td>
                <td>70%</td>
                <td>80%</td>
                <td>70%</td>
                <td>85%</td>
              </tr>
              <tr>
                <th>Target Labor</th>
                <td>85%</td>
                <td>80%</td>
                <td>85%</td>
                <td>95%</td>
                <td>75%</td>
                <td>85%</td>
                <td>75%</td>
                <td>90%</td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {TableSummary}
