/* eslint-disable jsx-a11y/anchor-is-valid */
import {bgcolor} from '@mui/system'
import {FC, useState} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../../_fds/helpers'
import {useStore} from '../store'
import {
  convertBaseEmployeesToAssigneeOptions,
  convertBaseEmployeesToResources,
  getHoursOfOperationOnDay,
  toTitleCase,
} from '../helpers/generals'
import {addMinutes} from 'date-fns'
import {min} from 'lodash'
import {setEmployeeHideStatus} from '../core/_requests'
import {useToast} from '../../../../_fds/layout/components/toast-messages'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  hours?: number
  employee_id: number
  default_position: string
  firstname: string
  lastname: string
  hide_in_schedule: boolean
  job: string
  pay_rate: number
}

const CardTeamMember: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  hours,
  employee_id,
  default_position,
  firstname,
  lastname,
  hide_in_schedule,
  job,
  pay_rate,
}) => {
  const {triggerDialog, selectedDate, hoursOfOperations, employees, handleMultipleStates} =
    useStore()
  const {showToast} = useToast()

  const [isHidden, setIsHidden] = useState(hide_in_schedule || false)

  const handleSwitchChange = async () => {
    const newHiddenStatus = !isHidden
    const status = await changeEmployeeHideStatus(employee_id, newHiddenStatus)
    if (status) {
      setIsHidden(newHiddenStatus)
      // update the employee list in the store
      const newEmployees = employees.map((employee) => {
        if (employee.id === employee_id) {
          return {
            ...employee,
            hide_in_schedule: newHiddenStatus,
          }
        }
        return employee
      })
      const newResources = convertBaseEmployeesToResources(newEmployees) || []
      const newAssigneeOptions = convertBaseEmployeesToAssigneeOptions(newEmployees) || []

      const states = new Map<string, any>([
        ['employees', newEmployees],
        ['resources', newResources],
        ['resourceOptions', newAssigneeOptions],
      ])

      handleMultipleStates(states)

      showToast('Employee Updated', 'Employee hidden status updated successfully!', 'success')
    } else {
      setIsHidden(!newHiddenStatus)
      showToast('Employee Update Failed', 'Employee hidden status update failed!', 'danger')
    }
  }

  const changeEmployeeHideStatus = async (employee_id: number, hide_in_schedule: boolean) => {
    const response = await setEmployeeHideStatus(employee_id, hide_in_schedule)
    if (response?.status === 200) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className='card' key={employee_id}>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle'>
            {/* {color ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                {firstname.charAt(0)}
                {lastname.charAt(0)}
              </span>
            ) : (
              <img alt='Pic' src={toAbsoluteUrl(avatar)} />
            )} */}

            <span
              className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}
              style={{backgroundColor: color}}
            >
              {firstname.charAt(0).toUpperCase()}
              {lastname.charAt(0).toUpperCase()}
            </span>
            {online && (
              <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
            )}
          </div>
        </div>

        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-semibold mb-0'>
          {toTitleCase(firstname)} {toTitleCase(lastname)}
        </a>

        <div className='fw-bold text-gray-400 mb-6'>{job}</div>

        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>
              ${new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(pay_rate)}
            </div>
            <div className='fw-bold text-gray-400'>Rate</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>
              {new Intl.NumberFormat('en-US', {maximumFractionDigits: 1}).format(hours || 0)} hrs
            </div>
            <div className='fw-bold text-gray-400'>Week Total</div>
          </div>
        </div>
        <div className='d-flex flex-center flex-wrap mb-5'>
          <button
            className='btn btn-sm btn-light mx-3'
            onClick={() => {
              const minTime = getHoursOfOperationOnDay(selectedDate, hoursOfOperations)[0]
              const endMinDate = addMinutes(minTime, 30)
              triggerDialog(true, {
                start: minTime,
                end: endMinDate,
                employee_id: employee_id,
                position: default_position,
              })
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add Shift
          </button>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id={`flexSwitchCheck-${employee_id}`}
              checked={isHidden}
              onChange={handleSwitchChange}
            />
            <label className='form-check-label'>Hidden in Schedule</label>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CardTeamMember}
