import React, {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_fds/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RedirectToOldPage} from '../pages/old-pages/OldPagesRedirecter'
import {getCSSVariableValue} from '../../_fds/assets/ts/_utils'
import {WithChildren} from '../../_fds/helpers'
import {LaborSchedulingWrapper} from '../modules/scheduler/LaborSchedulingWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/labor-scheduling/scheduling/overview' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}

        {/* Labor Scheduling */}
        <Route
          path='/labor-scheduling/scheduled-labor'
          element={<RedirectToOldPage url={'/ops/scheduled-labor'} />}
        />
        <Route
          path='/labor-scheduling/scheduled-labor/tracker'
          element={<RedirectToOldPage url={'/ops/scheduled-labor/tracker'} />}
        />
        <Route
          path='/labor-scheduling/labor/view-schedule'
          element={<RedirectToOldPage url={'/ops/labor/view-schedule'} />}
        />
        <Route
          path='/labor-scheduling/labor/employees'
          element={<RedirectToOldPage url={'/ops/labor/employees'} />}
        />
        <Route
          path='/labor-scheduling/labor/hours-of-operation'
          element={<RedirectToOldPage url={'/ops/labor/hours-of-operation'} />}
        />
        <Route path='/labor-scheduling/scheduling/*' element={<LaborSchedulingWrapper />} />
        {/* Old Pages */}

        <Route
          path='/labor-scheduling-old'
          element={<RedirectToOldPage url={'/ops/labor-scheduling'} />}
        />
        {/* Dashboards */}
        <Route
          path='analytics/main-dashboard'
          element={<RedirectToOldPage url={'/analytics/main-dashboard'} />}
        />
        <Route
          path='/analytics/details/sales'
          element={<RedirectToOldPage url={'/analytics/details/sales'} />}
        />
        <Route
          path='/analytics/historical/details/sales'
          element={<RedirectToOldPage url={'/analytics/historical/details/sales'} />}
        />
        <Route
          path='/analytics/location-breakdown/sales'
          element={<RedirectToOldPage url={'/analytics/location-breakdown/sales'} />}
        />
        <Route
          path='/analytics/rankings'
          element={<RedirectToOldPage url={'/analytics/rankings'} />}
        />
        <Route path='/comments/report' element={<RedirectToOldPage url={'/comments/report'} />} />
        <Route
          path='/ops/scorecard/level0'
          element={<RedirectToOldPage url={'/ops/scorecard/level0'} />}
        />
        <Route
          path='/ops/edit/scorecard'
          element={<RedirectToOldPage url={'/ops/edit/scorecard'} />}
        />
        {/* Custom Reports */}
        <Route
          path='/analytics/adhoc-report'
          element={<RedirectToOldPage url={'/analytics/adhoc-report'} />}
        />
        <Route
          path='/analytics/interactive-report'
          element={<RedirectToOldPage url={'/analytics/interactive-report'} />}
        />
        <Route
          path='/analytics/dataset-requests'
          element={<RedirectToOldPage url={'/analytics/dataset-requests'} />}
        />
        {/* Maintenance */}
        <Route
          path='/maintenance/tasks'
          element={<RedirectToOldPage url={'/maintenance/tasks'} />}
        />
        <Route
          path='/maintenance/assets'
          element={<RedirectToOldPage url={'/maintenance/assets'} />}
        />
        <Route
          path='/maintenance/vendors'
          element={<RedirectToOldPage url={'/maintenance/vendors'} />}
        />
        {/* OPS Routines */}
        <Route path='/ops/audit/main' element={<RedirectToOldPage url={'/ops/audit/main'} />} />
        <Route path='/forms/audit/new' element={<RedirectToOldPage url={'/forms/audit/new'} />} />
        <Route path='/routines/to-do' element={<RedirectToOldPage url={'/routines/to-do'} />} />
        <Route
          path='/routines/create-new'
          element={<RedirectToOldPage url={'/routines/create-new'} />}
        />
        <Route
          path='/routines/view-tasks'
          element={<RedirectToOldPage url={'/routines/view-tasks'} />}
        />
        <Route
          path='/routines/view-routines'
          element={<RedirectToOldPage url={'/routines/view-routines'} />}
        />
        {/* Forms */}
        <Route path='/forms/create-new' element={<RedirectToOldPage url={'/forms/create-new'} />} />
        <Route path='/forms/' element={<RedirectToOldPage url={'/forms/'} />} />
        <Route path='/forms/past' element={<RedirectToOldPage url={'/forms/past'} />} />

        {/* Admin */}
        <Route path='/admin/users' element={<RedirectToOldPage url={'/admin/users'} />} />
        <Route path='/admin/hierarchy' element={<RedirectToOldPage url={'/admin/hierarchy'} />} />
        <Route
          path='/admin/inputs/targets'
          element={<RedirectToOldPage url={'/admin/inputs/targets'} />}
        />
        <Route
          path='/admin/announcements'
          element={<RedirectToOldPage url={'/admin/announcements'} />}
        />
        <Route path='/admin/commentary' element={<RedirectToOldPage url={'/admin/commentary'} />} />
        <Route
          path='/admin/email-schedule'
          element={<RedirectToOldPage url={'/admin/email-schedule'} />}
        />

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
