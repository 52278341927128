import React, {useContext, useEffect, useRef, useState} from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'

type ToastMessage = {
  title: string
  message: string
  type: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger' | 'light' | 'dark'
  id: number
}

type ToastContextType = {
  showToast: (
    title: string,
    message: string,
    type: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger' | 'light' | 'dark'
  ) => void
}

const ToastContext = React.createContext<ToastContextType | undefined>(undefined)

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

interface ToastMessagesProps {
  messages: ToastMessage[]
  setMessages: React.Dispatch<React.SetStateAction<ToastMessage[]>>
}

const ToastMessages: React.FC<ToastMessagesProps> = ({messages, setMessages}) => {
  const messagesRef = useRef<ToastMessage[]>([])

  useEffect(() => {
    messagesRef.current = messages
  }, [messages])

  const removeToast = (id: number) => {
    setMessages((prevState) => {
      const messages = messagesRef.current
      const index = messages.findIndex((message) => message.id === id)
      if (index >= 0) {
        const newMessages = [...messages.slice(0, index), ...messages.slice(index + 1)]
        messagesRef.current = newMessages
        return newMessages
      }
      return prevState
    })
  }

  return (
    <ToastContainer
      // position='top-end'
      className='p-2'
      style={{
        position: 'fixed',
        top: 'calc(var(--bs-app-header-height) + var(--bs-app-toolbar-height) - 35px)',
        right: '20px',
      }}
    >
      {messages.map((message, index) => {
        return (
          <Toast
            key={index}
            bg={message.type}
            onClose={() => removeToast(message.id)}
            delay={3000}
            autohide={true}
          >
            <Toast.Header className={`bg-light-${message.type} text-gray-900`}>
              <strong className='me-auto'>{message.title}</strong>
              {/* <small className='text-muted'>just now</small> */}
            </Toast.Header>
            <Toast.Body className='text-gray-100'>{message.message}</Toast.Body>
          </Toast>
        )
      })}
    </ToastContainer>
  )
}

interface ToastProviderProps {
  children: React.ReactNode
}

export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const showToast = (
    title: string,
    message: string,
    type: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger' | 'light' | 'dark'
  ) => {
    const id = new Date().getTime()
    setMessages((prevState) => [...prevState, {title, message, type, id}])
  }

  return (
    <ToastContext.Provider value={{showToast}}>
      {children}
      <ToastMessages messages={messages} setMessages={setMessages} />
    </ToastContext.Provider>
  )
}
