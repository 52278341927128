import React, {FC, Fragment} from 'react'
import {FloatingLabel, Form, FormControlProps, FormProps} from 'react-bootstrap'
import {BsPrefixRefForwardingComponent} from 'react-bootstrap/esm/helpers'
import {Link} from 'react-router-dom'

interface FdsTextInputProps extends FormControlProps {
  label?: string
  type?: string
  rows?: number
  className?: string
  controlId?: string
  required?: boolean
  // ref?: any
  value?: any
}

// label,
// type,
// rows,
// className,
// controlId,
// required,
// ref,
// ...otherProps

// const FdsTextInput = React.forwardRef((props: FdsTextInputProps, ref) => {
//   const {
//     label,
//     type,
//     rows,
//     value,
//     className,
//     controlId,
//     required,

//     ...otherProps
//   } = props
// const FdsTextInput: FC<FdsTextInputProps> = ({
//   label,
//   type,
//   rows,
//   value,
//   className,
//   controlId,
//   required,
//   ref,
//   ...otherProps
// }) => {
const FdsTextInput = React.forwardRef((props: FdsTextInputProps, ref) => {
  const {
    label,
    type,
    rows,
    value,
    className,
    controlId,
    required,

    ...otherProps
  } = props
  return (
    <Fragment>
      <FloatingLabel
        controlId={`${controlId || 'floatingInput'}`}
        className='form-label'
        label={`${label} ${required ? '*' : ''}`}
      >
        <Form.Control
          as={type === 'textarea' ? 'textarea' : undefined}
          type={type === 'textarea' ? undefined : type}
          required={required}
          label={`${label} ${required ? '*' : ''}`}
          ref={ref}
          value={value}
          className={`${className ? className : 'form-control'}`}
          rows={`${rows || 3}`}
          {...otherProps}
        />
      </FloatingLabel>
    </Fragment>
  )

  // return (
  //   <Fragment>
  //     <FloatingLabel
  //       controlId='floatingLabel'
  //       className='form-label'
  //       label={`${label} ${required ? '*' : ''}`}
  //     >
  //       <Form.Control
  //         className={`${className ? className : 'form-control '}`}
  //         {...otherProps}
  //         // ref={ref}
  //       />
  //     </FloatingLabel>
  //   </Fragment>
  // )

  return (
    <Fragment>
      <FloatingLabel
        controlId='floatingLabel'
        className='form-label'
        label={`${label} ${required ? '*' : ''}`}
      >
        <Form.Control
          className={`${className ? className : 'form-control '}`}
          type='text'
          value={value}
          // role='combobox'
          {...otherProps}
          ref={ref}
        />
      </FloatingLabel>
    </Fragment>
  )
})
// }

export {FdsTextInput}
