import {FC, useState} from 'react'
import {ScheduleCompletion} from './ScheduleCompletion'
import {useStore} from '../../store'
import {
  filterEventsBetween,
  getAllowableValues,
  getBusinessDay,
  getHoursOfOperationOnDay,
} from '../../helpers/generals'
import {addDays, addMinutes, eachMinuteOfInterval, format, startOfWeek} from 'date-fns'
import {KTSVG} from '../../../../../_fds/helpers'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

interface FdsModalProps {
  cancelButtonText: string
  saveButtonText: string
  saveButtonCallback: () => void
  closeButtonCallback: () => void
  titleText: string
  content: React.ReactNode
  show: boolean
  setShow: (show: boolean) => void
}

const FdsModal: FC<FdsModalProps> = ({
  cancelButtonText,
  saveButtonText,
  saveButtonCallback,
  closeButtonCallback,
  titleText,
  content,
  show,
  setShow,
}) => {
  const handleClose = () => {
    closeButtonCallback()
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const handleSave = () => {
    saveButtonCallback()
    setShow(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          {cancelButtonText.length > 0 && (
            <Button variant='secondary' onClick={handleClose}>
              {cancelButtonText}
            </Button>
          )}
          {saveButtonText.length > 0 && (
            <Button variant='primary' onClick={handleSave}>
              {saveButtonText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {FdsModal}
