import {useState} from 'react'
import DateProvider from '../hoc/DateProvider'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {Button} from '@mui/material'
import {format, addDays} from 'date-fns'
import {LocaleArrow} from '../common/LocaleArrow'
import {useStore} from '../../store'
import ReactDatePicker from 'react-datepicker'

interface DayDateBtnProps {
  selectedDate: Date
  onChange(value: Date, key: 'selectedDate'): void
}

const DayDateBtn = ({selectedDate, onChange}: DayDateBtnProps) => {
  const {locale, navigationPickerProps, week} = useStore()
  const [open, setOpen] = useState(false)
  const toggleDialog = () => setOpen(!open)
  const {weekStartOn} = week!

  const handleChange = (e: Date | null) => {
    onChange(e || new Date(), 'selectedDate')
  }

  const handlePrev = () => {
    const prevDay = addDays(selectedDate, -1)
    onChange(prevDay, 'selectedDate')
  }
  const handleNext = () => {
    const nexDay = addDays(selectedDate, 1)
    onChange(nexDay, 'selectedDate')
  }
  return (
    <>
      <LocaleArrow type='prev' onClick={handlePrev} />
      <DateProvider>
        <span
          className='btn text-gray-800 text-hover-primary fw-bolder hover-cursor-pointer'
          style={{padding: 4}}
          onClick={toggleDialog}
        >{`${format(selectedDate, 'dd MMMM yyyy, EEE', {
          locale: locale,
        })}`}</span>

        {open && (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 'calc(var(--bs-app-header-height) - 20px)',
              zIndex: 999,
            }}
          >
            <ReactDatePicker
              // onClose={toggleDialog}
              showPopperArrow={true}
              selected={selectedDate}
              calendarStartDay={weekStartOn}
              onChange={handleChange}
              onClickOutside={toggleDialog}
              popperProps={{strategy: 'fixed'}}
              showWeekNumbers
              inline
            />
          </div>
        )}
        {/* <DatePicker
          {...navigationPickerProps}
          open={open}
          onClose={toggleDialog}
          openTo='day'
          views={['month', 'day']}
          value={selectedDate}
          onChange={handleChange}
          renderInput={(params) => (
            <span
              className='btn text-gray-800 text-hover-primary fw-bolder hover-cursor-pointer'
              ref={params.inputRef}
              style={{padding: 4}}
              onClick={toggleDialog}
            >{`${format(selectedDate, 'dd MMMM yyyy', {
              locale: locale,
            })}`}</span>
          )}
        /> */}
      </DateProvider>
      <LocaleArrow type='next' onClick={handleNext} />
    </>
  )
}

export {DayDateBtn}
