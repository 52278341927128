import React, {useState, useEffect} from 'react'
import {TableSummary} from './TableSummary'
import {WeeklySummaryChart} from './WeeklySummaryChart'

export function Overview() {
  return (
    <>
      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-7'>
          <TableSummary className='mb-5 mb-xxl-8' />
        </div>

        <div className='col-xl-5'>
          <WeeklySummaryChart className='mb-5 mb-xxl-8' />
        </div>
      </div>
    </>
  )
}
