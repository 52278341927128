import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography} from '@mui/material'
import {useWindowResize} from '../../hooks/useWindowResize'
import {DefaultRecourse} from '../../types'
import {useStore} from '../../store'
import {toAbsoluteUrl} from '../../../../../_fds/helpers'

interface ResourceHeaderProps {
  resource: DefaultRecourse
}
const ResourceHeader = ({resource}: ResourceHeaderProps) => {
  const {recourseHeaderComponent, resourceFields, resources, direction, resourceViewMode} =
    useStore()
  const {width} = useWindowResize()

  const text = resource[resourceFields.textField]
  const subtext = resource[resourceFields.subTextField || '']
  const avatar = resource[resourceFields.avatarField || '']
  const color = resource[resourceFields.colorField || '']

  if (recourseHeaderComponent instanceof Function) {
    return recourseHeaderComponent(resource)
  }

  const headerBorders =
    resourceViewMode === 'tabs'
      ? {}
      : {
          borderColor: '#eee',
          borderStyle: 'solid',
          borderWidth: '1px 1px 0 1px',
        }
  return (
    <ListItem
      sx={{
        padding: '0px 0px',
        textAlign: direction === 'rtl' ? 'right' : 'left',
        ...headerBorders,
      }}
      component='span'
    >
      {/*<ListItemAvatar>
        <Avatar style={{background: color}} alt={text} src={toAbsoluteUrl(avatar)} />
      </ListItemAvatar>*/}
      <ListItemText
        primary={<span className='text-gray-900'>{text}</span>}
        secondary={
          <span className='fw-bold' style={{color: color}}>
            {subtext}
          </span>
        }
        style={{width: width / (resources.length + 1)}}
      />
    </ListItem>
  )
}

export {ResourceHeader}
