import {FC, useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {ToastProvider} from './components/toast-messages'
import {FdsModal} from '../../app/modules/scheduler/components/common/FdsModal'
import {FdsModalContextProvider, FdsModalSettings} from './ModalContext'

const MasterLayout: FC = () => {
  const location = useLocation()
  const [modalSettings, setModalSettings] = useState<FdsModalSettings>({
    cancelButtonText: '',
    saveButtonText: '',
    saveButtonCallback: () => {},
    closeButtonCallback: () => {},
    titleText: '',
    content: '',
    show: false,
  })

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const handleChangeShow = (show: boolean) => {
    setModalSettings({...modalSettings, show})
  }

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <ToastProvider>
          <FdsModalContextProvider
            value={{
              settings: modalSettings,
              setSettings: setModalSettings,
            }}
          >
            <FdsModal
              cancelButtonText={modalSettings.cancelButtonText}
              saveButtonText={modalSettings.saveButtonText}
              saveButtonCallback={modalSettings.saveButtonCallback}
              closeButtonCallback={modalSettings.closeButtonCallback || (() => {})}
              titleText={modalSettings.titleText}
              content={modalSettings.content}
              show={modalSettings.show}
              setShow={handleChangeShow}
            />
            <div className='d-flex flex-column flex-root app-root' id='fds_app_root'>
              <div className='app-page flex-column flex-column-fluid' id='fds_app_page'>
                <HeaderWrapper />
                <div className='app-wrapper flex-column flex-row-fluid' id='fds_app_wrapper'>
                  {/* <Sidebar /> */}
                  <div className='app-main flex-column flex-row-fluid' id='fds_app_main'>
                    <div className='d-flex flex-column flex-column-fluid'>
                      <ToolbarWrapper />
                      <Content>
                        <Outlet />
                      </Content>
                    </div>
                    <FooterWrapper />
                  </div>
                </div>
              </div>
            </div>
          </FdsModalContextProvider>

          {/* begin:: Drawers */}
          {/* <ActivityDrawer /> */}
          {/* <RightToolbar /> */}
          {/* <DrawerMessenger /> */}
          {/* end:: Drawers */}

          {/* begin:: Modals */}
          {/* <InviteUsers /> */}
          {/* <UpgradePlan /> */}
          {/* end:: Modals */}
          <ScrollTop />
        </ToastProvider>
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
