import {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {ILayout} from '../../core'

type SidebarLogoProps = {
  config: ILayout
}

const SidebarLogo: FC<SidebarLogoProps> = ({config}) => {
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  return (
    <div className='app-sidebar-logo px-1' id='fds_app_sidebar_logo'>
      <a href={process.env.REACT_APP_OLD_FLASK_APP_URL || '#'}>
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/fds-logo.png?v=2024.4')}
            className='h-49px app-sidebar-logo-default'
            style={{height: '65px'}}
          />
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/fds-logo.png?v=2024.4')}
              className='h-49px app-sidebar-logo-default theme-light-show'
              style={{height: '65px'}}
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/fds-logo.png?v=2024.4')}
              className='h-49px app-sidebar-logo-default theme-dark-show'
              style={{height: '65px'}}
            />
          </>
        )}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/fds-logo-small.png')}
          className='h-30px app-sidebar-logo-minimize'
        />
      </a>

      {/* {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='fds_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-fds-toggle='true'
          data-fds-toggle-state={toggleState}
          data-fds-toggle-target='body'
          data-fds-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )} */}
    </div>
  )
}

export {SidebarLogo}
