import React, {FC, useEffect, useState} from 'react'
// import {FdsTextInput} from './FdsTextInput'
import {FdsTextInput} from './FdsTextInput'
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker'

// interface FdsSelectBoxProps {
// value: any,
// onChange: (newValue: any) => void,
// options: any[],
// }

interface FdsDateSelectProps extends ReactDatePickerProps {
  label: string
  value: any
  className?: string
  controlId?: string
  required?: boolean
  calendarStartDay?: number
}

const FdsDateSelect: FC<FdsDateSelectProps> = ({
  label,
  value,
  controlId,
  required,
  calendarStartDay,
  ...otherProps
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <FdsTextInput
        label={`${label} ${required ? '*' : ''}`}
        type='text'
        style={{cursor: 'pointer'}}
        value={selectedDate}
        className='form-control'
        {...props}
      />
    )
  })

  const handleChangeRaw = (date: any) => {
    const newRaw = new Date(date.currentTarget.value)
    if (newRaw instanceof Date && !isNaN(newRaw as any)) {
      setSelectedDate(newRaw)
    }
  }

  useEffect(() => {
    if (value) {
      setSelectedDate(value)
    }
  }, [value])

  return (
    <ReactDatePicker
      showPopperArrow={true}
      selected={selectedDate}
      calendarStartDay={calendarStartDay}
      onChangeRaw={(e) => handleChangeRaw(e)}
      customInput={<CustomInput />}
      {...otherProps}
    />
  )
}

export {FdsDateSelect}
