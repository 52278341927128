/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useContext, ReactElement} from 'react'

type CustomToolbarProps = {
  customToolbarElements?: ReactElement | null
}

const ToolbarExtended: FC<CustomToolbarProps> = ({customToolbarElements}) => {
  return (
    <>
      {/* end::Toolbar start */}
      {customToolbarElements}
      {/* begin::Toolbar end */}
    </>
  )
}

export {ToolbarExtended}
