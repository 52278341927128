import {useEffect} from 'react'

const RedirectToOldPage = (page: any) => {
  useEffect(() => {
    window.location.replace(process.env.REACT_APP_OLD_FLASK_APP_URL + page.url)
  }, [page.url])
  return null
}

export {RedirectToOldPage}
