import {useEffect, useState} from 'react'
import {defaultProps, initialStore} from './default'
import {Store} from './types'
import {
  arraytizeFieldVal,
  getAvailableViews,
  getHoursOfOperationOnDay,
  getPositionColor,
  weekEquality,
} from '../helpers/generals'
import {ProcessedEvent, Scheduler} from '../types'
import {addMinutes, differenceInMinutes, isEqual} from 'date-fns'
import {WeekProps} from '../components/views/Week'

const createEmitter = () => {
  const subscriptions = new Map<
    ReturnType<typeof Symbol>,
    React.Dispatch<React.SetStateAction<Store>>
  >()
  return {
    emit: (v: Store) => subscriptions.forEach((fn) => fn(v)),
    subscribe: (fn: React.Dispatch<React.SetStateAction<Store>>) => {
      const key = Symbol()
      subscriptions.set(key, fn)
      return () => {
        subscriptions.delete(key)
      }
    },
  }
}

const createStore = (
  init: (getter: () => Store, setter: (op: (store: Store) => Store) => void) => Store
) => {
  // create an emitter
  const emitter = createEmitter()
  let store: Store = initialStore
  const get = () => store
  const set = (op: (store: Store) => Store) => {
    store = op(store)
    // notify all subscriptions when the store updates
    emitter.emit(store)
  }
  store = init(get, set)

  const useStore = (initial?: Scheduler) => {
    // intitialize component with initial props or latest store
    const prev = get()
    const initVals = (initial ? {...prev, ...defaultProps(initial)} : prev) as Store
    const [localStore, setLocalStore] = useState(initVals)

    // update our local store when the global
    // store updates.
    //
    // emitter.subscribe returns a cleanup
    // function, so react will clean this
    // up on unmount.
    useEffect(() => emitter.subscribe(setLocalStore), [])

    useEffect(() => {
      if (initial) {
        set((s) => initVals)
      }
      // eslint-disable-next-line
    }, [])

    return localStore
  }
  return useStore
}

function areMapsEqual(map1: Map<string, number>, map2: Map<string, number>): boolean {
  if (map1.size !== map2.size) {
    return false
  }

  // Convert Maps to arrays of key-value pairs
  const array1 = Array.from(map1)
  const array2 = Array.from(map2)

  // Sort the arrays to ensure the order of key-value pairs is the same
  array1.sort()
  array2.sort()

  // Compare the arrays element-wise
  return JSON.stringify(array1) === JSON.stringify(array2)
}

function mapToObject(map: Map<string, any>) {
  const object = Object.fromEntries(map.entries())
  return object
}

export const useStore = createStore((get, set) => {
  return {
    ...get(),
    handleState: (value, name) => {
      if (name === 'dailyHours' || name === 'employeeHours' || name === 'dailyLaborCost') {
        if (areMapsEqual(value as Map<string, number>, get()[name] as Map<string, number>)) {
          return
        }
      } else if (name === 'events' || name === 'resources' || name === 'fields') {
        // if previously it was an empty array and now it is still an empty array return
        if (
          Array.isArray(value) &&
          Array.isArray(get()[name]) &&
          !value.length &&
          !get()[name].length
        ) {
          return
        }
      } else if (name === 'week') {
        if (weekEquality(value as WeekProps, get()[name])) {
          return
        }
      } else if (name === 'selectedWeekStart') {
        if (isEqual(value as Date, get()[name] || new Date())) {
          return
        }
      }

      // if (name !== 'selectedLocationId') return
      set((prev) => ({...prev, [name as string]: value}))
    },
    handleMultipleStates: (values: Map<string, any>) => {
      // loop over values and set each one
      values.forEach((value, name) => {
        // if (name !== 'selectedLocationId') values.delete(name)
        if (name === 'dailyHours' || name === 'employeeHours' || name === 'dailyLaborCost') {
          if (areMapsEqual(value as Map<string, number>, get()[name] as Map<string, number>)) {
            // remove it from keys
            values.delete(name)
          }
        } else if (name === 'events' || name === 'resources' || name === 'fields') {
          // if previously it was an empty array and now it is still an empty array return
          if (
            Array.isArray(value) &&
            Array.isArray(get()[name]) &&
            !value.length &&
            !get()[name].length
          ) {
            // remove it from keys
            values.delete(name)
          }
        } else if (name === 'week') {
          if (weekEquality(value as WeekProps, get()[name])) {
            values.delete(name)
          }
        }
      })
      set((prev) => ({...prev, ...mapToObject(values)}))
    },
    getViews: () => {
      return getAvailableViews(get())
    },
    triggerDialog: (status, selected) => {
      const isEvent = selected as ProcessedEvent
      set((prev) => ({
        ...prev,
        dialog: status,
        businessDay: isEvent?.businessDay,
        selectedRange: isEvent?.event_id
          ? undefined
          : isEvent || {
              start: new Date(),
              end: new Date(Date.now() + 60 * 60 * 1000),
            },
        selectedEvent: isEvent?.event_id ? isEvent : undefined,
      }))
    },
    triggerLoading: (status) => {
      // Trigger if not out-sourced by props
      if (typeof initialStore.loading === 'undefined') {
        set((prev) => ({...prev, loading: status}))
      }
    },
    handleGotoDay: (day: Date) => {
      const currentViews = get().getViews()
      if (currentViews.includes('timeline')) {
        set((prev) => ({...prev, view: 'timeline', selectedDate: day}))
      } else if (currentViews.includes('week')) {
        set((prev) => ({...prev, view: 'week', selectedDate: day}))
      } else {
        console.warn('No Day/Week views available')
      }
    },
    confirmEvent: (event, action) => {
      const state = get()
      let updatedEvents: ProcessedEvent[]
      if (action === 'edit') {
        if (Array.isArray(event)) {
          updatedEvents = state.events.map((e) => {
            const exist = event.find((ex) => ex.event_id === e.event_id)
            if (exist) {
              e.color = getPositionColor(exist.position || e.position)
            }
            return exist ? {...e, ...exist} : e
          })
        } else {
          updatedEvents = state.events.map((e) => {
            return e.event_id === event.event_id
              ? {...e, ...event, color: getPositionColor(event.position || e.position)}
              : e
          })
        }
      } else {
        if (Array.isArray(event)) {
          event.forEach((e) => {
            e.color = getPositionColor(e.position)
          })
        } else {
          event.color = getPositionColor(event.position)
        }
        updatedEvents = state.events.concat(event)
      }
      set((prev) => ({...prev, events: updatedEvents}))
    },
    onDrop: async (eventId, startTime, businessDay, resKey, resVal) => {
      const state = get()
      // Get dropped event
      const droppedEvent = state.events.find((e) => {
        if (typeof e.event_id === 'number') {
          return e.event_id === +eventId
        }
        return e.event_id === eventId
      }) as ProcessedEvent

      // Check if has resource and if is multiple
      const resField = state.fields.find((f) => f.name === resKey)
      const isMultiple = !!resField?.config?.multiple
      let newResource = resVal as string | number | string[] | number[]
      if (resField) {
        const eResource = droppedEvent[resKey as string]
        const currentRes = arraytizeFieldVal(resField, eResource, droppedEvent).value
        if (isMultiple) {
          // if dropped on already owned resource
          if (currentRes.includes(resVal)) {
            // Omit if dropped on same time slot for multiple event
            if (isEqual(droppedEvent.start, startTime)) {
              return
            }
            newResource = currentRes
          } else {
            // if have multiple resource ? add other : move to other
            newResource = currentRes.length > 1 ? [...currentRes, resVal] : [resVal]
          }
        }
      }

      // Omit if dropped on same time slot for non multiple events
      if (isEqual(droppedEvent.start, startTime)) {
        if (!newResource || (!isMultiple && newResource === droppedEvent[resKey as string])) {
          return
        }
      }

      // Update event time according to original duration & update resources/owners
      const diff = differenceInMinutes(droppedEvent.end, droppedEvent.start)
      const [dayStart, dayEnd] = getHoursOfOperationOnDay(businessDay, state['hoursOfOperations'])
      const newEnd = addMinutes(startTime, diff) <= dayEnd ? addMinutes(startTime, diff) : dayEnd

      // todo: if end time is after hours of operation trim it
      const updatedEvent: ProcessedEvent = {
        ...droppedEvent,
        start: startTime,
        end: newEnd,
        [resKey as string]: newResource || '',
      }

      // Local
      if (!state.onEventDrop || typeof state.onEventDrop !== 'function') {
        return state.confirmEvent(updatedEvent, 'edit')
      }
      // Remote
      try {
        state.triggerLoading(true)
        updatedEvent.selectedLaborScheduleId = state.selectedLaborScheduleId
        updatedEvent.selectedLocationId = state.selectedLocationId
        const _event = await state.onEventDrop(startTime, businessDay, updatedEvent, droppedEvent)
        if (_event) {
          state.confirmEvent(_event, 'edit')
        }
      } catch {
        console.error('Error on onEventDrop')
      } finally {
        state.triggerLoading(false)
      }
    },
  }
})
