import {Button} from 'react-bootstrap'
import {useDropAttributes} from '../../hooks/useDropAttributes'
import {CellRenderedProps, ConvertedHoursOfOperation} from '../../types'
import {Fragment, useState} from 'react'
import {alpha, useTheme} from '@mui/material'
import {getHoursOfOperationOnDay} from '../../helpers/generals'
import {useStore} from '../../store'
import {format} from 'date-fns'
import {useAuth} from '../../../auth'

interface CellProps {
  day: Date
  start: Date
  height: number
  end: Date
  hoursOfOperations: ConvertedHoursOfOperation | null
  draggable: boolean
  resourceKey: string
  resourceVal: string | number
  positionKey: string
  positionVal: string | number
  cellRenderer?(props: CellRenderedProps): JSX.Element
  children?: JSX.Element
  handleMouseDown?(e: React.MouseEvent<HTMLButtonElement>, otherProps: any): void
  handleMouseUp?(e: React.MouseEvent<HTMLButtonElement>, otherProps: any): void
  handleMouseEnter?(e: React.MouseEvent<HTMLButtonElement>, otherProps: any): void
  handleMouseLeave?(e: React.MouseEvent<HTMLButtonElement>, otherProps: any): void
}

const Cell = ({
  day,
  start,
  end,
  hoursOfOperations,
  draggable,
  resourceKey,
  resourceVal,
  cellRenderer,
  height,
  children,
  positionKey,
  positionVal,
  ...extraProps
}: CellProps) => {
  const props = useDropAttributes({
    start,
    end,
    day,
    resourceKey,
    resourceVal,
    positionKey,
    positionVal,
  })
  const {currentUser} = useAuth()
  const {handleMouseDown, handleMouseUp, handleMouseEnter, handleMouseLeave, ...otherProps} =
    extraProps

  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (handleMouseDown) {
      handleMouseDown(e, {day, start, end, resourceKey, resourceVal, positionKey, positionVal})
    }
  }

  const onMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (handleMouseUp) {
      handleMouseUp(e, {day, start, end, resourceKey, resourceVal, positionKey, positionVal})
    }
  }

  const onMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (handleMouseEnter) {
      handleMouseEnter(e, {day, start, end, resourceKey, resourceVal, positionKey, positionVal})
    }
  }

  const onMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (handleMouseLeave) {
      handleMouseLeave(e, {day, start, end, resourceKey, resourceVal, positionKey, positionVal})
    }
  }

  const multiSelectProps = {
    onMouseDown,
    onMouseUp,
    onMouseOver: onMouseEnter,
    onMouseOut: onMouseLeave,
  }

  if (cellRenderer) {
    return cellRenderer({
      day,
      start,
      end,
      height,
      ...props,
      ...otherProps,
    })
  }
  const hoursOfOperation = getHoursOfOperationOnDay(day, hoursOfOperations)
  // if start and end is not inside the hours of operation return null
  if (
    (start < hoursOfOperation[0] || start >= hoursOfOperation[1]) &&
    (end <= hoursOfOperation[0] || end > hoursOfOperation[1])
  ) {
    return (
      <button
        style={{
          backgroundColor: 'var(--bs-gray-400)',
          padding: 0,
          width: '100%',
          height: '100%',
          cursor: 'not-allowed!important',
          opacity: 'var(--bs-btn-disabled-opacity)',
        }}
        className='btn'
      >
        {children}
      </button>
    )
  }

  if (
    (!draggable || start < new Date()) &&
    !(currentUser?.role?.name && ['Creator', 'Admin', 'DM'].includes(currentUser?.role?.name))
  ) {
    return (
      <button
        style={{
          backgroundColor: 'var(--bs-secondary)',
          padding: 0,
          width: '100%',
          height: '100%',
          cursor: 'not-allowed!important',
          opacity: 'var(--bs-btn-disabled-opacity)',
        }}
        className='btn'
      >
        {children}
      </button>
    )
  }

  return (
    <button
      className='btn btn-light text-gray-700'
      style={{padding: 0}}
      title={`${format(start, 'hh:mm a')}`}
      {...props}
      {...otherProps}
      {...multiSelectProps}
    >
      {children}
    </button>
  )
}

export default Cell
