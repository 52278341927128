import {useEffect, useState} from 'react'
// import {Grid, useTheme, useMediaQuery} from '@mui/material'
import {Grid} from '@mui/material'
import {addDays, addMinutes, differenceInMinutes, isSameDay, setMinutes, setHours} from 'date-fns'
import {EditorDatePicker} from '../inputs/DatePicker'
import {EditorInput} from '../inputs/Input'
import {
  EventActions,
  FieldInputProps,
  FieldProps,
  InputTypes,
  ProcessedEvent,
  SchedulerHelpers,
} from '../../types'
import {EditorSelect} from '../inputs/SelectInput'
import {arraytizeFieldVal, getBusinessDay, getHoursOfOperationOnDay} from '../../helpers/generals'
import {useStore} from '../../store'
import {SelectedRange} from '../../store/types'
import {Card, Modal, Button} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useIntl} from 'react-intl'
import {el} from 'date-fns/locale'

export type StateItem = {
  value: any
  validity: boolean
  type: InputTypes
  config?: FieldInputProps
  options?: any[]
}

export type StateEvent = (ProcessedEvent & SelectedRange) | Record<string, any>

const modalsRoot = document.getElementById('root-modals') || document.body

const initialState = (fields: FieldProps[], event?: StateEvent): Record<string, StateItem> => {
  const customFields = {} as Record<string, StateItem>
  for (const field of fields) {
    const defVal = arraytizeFieldVal(field, field.default, event)
    const eveVal = arraytizeFieldVal(field, event?.[field.name], event)

    customFields[field.name] = {
      value: eveVal.value || defVal.value || '',
      validity: field.config?.required ? !!eveVal.validity || !!defVal.validity : true,
      type: field.type,
      config: field.config,
    }
  }

  return {
    event_id: {
      value: event?.event_id || null,
      validity: true,
      type: 'hidden',
    },
    ...customFields,
    start: {
      value: event?.start || new Date(),
      validity: true,
      type: 'date',
      config: {label: 'Shift start', sm: 6},
    },
    end: {
      value: event?.end || new Date(),
      validity: true,
      type: 'date',
      config: {label: 'Shift end', sm: 6},
    },
    // break_start: {
    //   value: event?.break_start || new Date(),
    //   validity: true,
    //   type: 'date',
    //   config: {label: 'Break start', sm: 6},
    // },
    // break_end: {
    //   value: event?.end || new Date(),
    //   validity: true,
    //   type: 'date',
    //   config: {label: 'Break end', sm: 6},
    // },
  }
}

const Editor = () => {
  const {
    fields,
    dialog,
    triggerDialog,
    selectedRange,
    selectedEvent,
    triggerLoading,
    onConfirm,
    customEditor,
    confirmEvent,
    translations,
    resourceOptions,
    selectedLaborScheduleId,
    selectedLocationId,
    hoursOfOperations,
  } = useStore()
  const [state, setState] = useState(initialState(fields, selectedEvent || selectedRange))
  const [touched, setTouched] = useState(false)
  // const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const intl = useIntl()

  const handleEditorState = (name: string, value: any, validity: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: {...prev[name], value, validity},
      }
    })
  }

  const handleClose = (clearState?: boolean) => {
    if (clearState) {
      setState(initialState(fields))
    }
    // make body scrollable
    document.body.style.overflow = 'auto'
    triggerDialog(false)
  }

  const handleConfirm = async () => {
    let body = {} as ProcessedEvent
    for (const key in state) {
      body[key] = state[key].value
      if (!customEditor && !state[key].validity) {
        return setTouched(true)
      }
    }
    try {
      triggerLoading(true)
      // Auto fix date
      body.end =
        body.start >= body.end
          ? addMinutes(body.start, differenceInMinutes(selectedRange?.end!, selectedRange?.start!))
          : body.end
      body.selectedLaborScheduleId = selectedLaborScheduleId
      body.selectedLocationId = selectedLocationId
      body.businessDay = getBusinessDay(body.start, hoursOfOperations)

      // Specify action
      const action: EventActions = selectedEvent?.event_id ? 'edit' : 'create'
      // Trigger custom/remote when provided
      var success = true
      if (onConfirm) {
        try {
          body = await onConfirm(body, action)
        } catch (error) {
          success = false
        }
      } else {
        // Create/Edit local data
        body.event_id =
          selectedEvent?.event_id || Date.now().toString(36) + Math.random().toString(36).slice(2)
      }
      if (success) {
        confirmEvent(body, action)
      }
      handleClose(true)
    } catch (error) {
      console.error(error)
    } finally {
      triggerLoading(false)

      // make body scrollable
      document.body.style.overflow = 'auto'
    }
  }

  useEffect(() => {
    // set state's end value to selectableMax or selectableMin if it's out of range
    const stateItem = state.end
    const [hoursOfOperationsStart, hoursOfOperationsEnd] = getHoursOfOperationOnDay(
      state.start.value,
      hoursOfOperations
    )
    const isFirstday = isSameDay(stateItem.value, hoursOfOperationsStart)

    const selectableMinTime = isFirstday
      ? addMinutes(state.start.value, 30)
      : setMinutes(setHours(hoursOfOperationsEnd, 0), 0)
    const selectableMaxTime =
      isFirstday && !isSameDay(hoursOfOperationsStart, hoursOfOperationsEnd)
        ? setMinutes(setHours(state.start.value, 23), 59)
        : hoursOfOperationsEnd

    const endDefaultSelect =
      stateItem.value > selectableMaxTime
        ? selectableMaxTime
        : stateItem.value < selectableMinTime
        ? selectableMinTime
        : stateItem.value

    if (state.end.value > selectableMaxTime || state.end.value < selectableMinTime) {
      setState((prev) => {
        return {
          ...prev,
          end: {...prev.end, value: endDefaultSelect},
        }
      })
    }
  }, [state.end.value, state.start.value])
  const renderInputs = (key: string) => {
    const stateItem = state[key]
    const [hoursOfOperationsStart, hoursOfOperationsEnd] = getHoursOfOperationOnDay(
      state.start.value,
      hoursOfOperations
    )

    const minTime = getHoursOfOperationOnDay(state.start.value, hoursOfOperations)[0]
    const maxTime = addMinutes(
      getHoursOfOperationOnDay(state.start.value, hoursOfOperations)[1],
      -30
    )

    const endMaxDate = addMinutes(
      getHoursOfOperationOnDay(state.start.value, hoursOfOperations)[1],
      1
    )
    const endMinDate = addMinutes(state.start.value, 30)

    const maxDate = addDays(new Date(), 28)
    const minDate = new Date()

    switch (stateItem.type) {
      case 'input':
      case 'textarea':
        return (
          <EditorInput
            value={stateItem.value}
            name={key}
            type={stateItem.type}
            onChange={handleEditorState}
            touched={touched}
            {...stateItem.config}
            label={translations.event[key] || stateItem.config?.label}
          />
        )
      case 'date':
        const isFirstday = isSameDay(stateItem.value, hoursOfOperationsStart)

        const selectableMinTime = isFirstday
          ? addMinutes(state.start.value, 30)
          : setMinutes(setHours(hoursOfOperationsEnd, 0), 0)
        const selectableMaxTime =
          isFirstday && !isSameDay(hoursOfOperationsStart, hoursOfOperationsEnd)
            ? setMinutes(setHours(state.start.value, 23), 59)
            : hoursOfOperationsEnd

        const endDefaultSelect =
          stateItem.value > selectableMaxTime
            ? selectableMaxTime
            : stateItem.value < selectableMinTime
            ? selectableMinTime
            : stateItem.value

        return (
          <EditorDatePicker
            value={key === 'end' ? endDefaultSelect : stateItem.value}
            name={key}
            onChange={(...args) => handleEditorState(...args, true)}
            {...stateItem.config}
            minTime={key === 'end' ? selectableMinTime : minTime}
            maxTime={key === 'end' ? selectableMaxTime : maxTime}
            maxDate={key === 'end' ? endMaxDate : maxDate}
            minDate={key === 'end' ? endMinDate : minDate}
            label={translations.event[key] || stateItem.config?.label}
          />
        )
      case 'select':
        const field = fields.find((f) => f.name === key)
        const options = field?.name === 'employee_id' ? resourceOptions : field?.options
        const filteredOptions = options?.filter(
          (option) =>
            option.value !== null &&
            option.value !== '' &&
            option.text !== null &&
            option.text !== ''
        )
        return (
          <EditorSelect
            value={stateItem.value}
            name={key}
            options={filteredOptions || []}
            onChange={handleEditorState}
            touched={touched}
            {...stateItem.config}
            label={translations.event[key] || stateItem.config?.label}
          />
        )
      default:
        return ''
    }
  }

  const renderEditor = () => {
    if (customEditor) {
      const schedulerHelpers: SchedulerHelpers = {
        state,
        close: () => triggerDialog(false),
        loading: (load) => triggerLoading(load),
        edited: selectedEvent,
        onConfirm: confirmEvent,
      }
      return customEditor(schedulerHelpers)
    }
    return (
      <Card>
        <Card.Header>
          <Card.Title>
            {selectedEvent ? translations.form.editTitle : translations.form.addTitle}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid container spacing={2}>
            {Object.keys(state).map((key) => {
              const item = state[key]
              return (
                <Grid item key={key} sm={item.config?.sm} xs={12}>
                  {renderInputs(key)}
                </Grid>
              )
            })}
          </Grid>
        </Card.Body>
        <Card.Footer className='d-flex flex-wrap justify-content-center'>
          <Button className='btn-secondary btn-sm ml-auto me-2' onClick={() => handleClose()}>
            {intl.formatMessage({id: 'COMMON.CANCEL'})}
          </Button>
          <Button className='btn-primary btn-sm ml-auto me-2' onClick={handleConfirm}>
            {intl.formatMessage({id: 'COMMON.CONFIRM'})}
          </Button>
        </Card.Footer>
      </Card>
    )
  }

  return createPortal(
    <Modal
      id='fds_modal_schedule_editor'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={dialog}
      onHide={handleClose}
      backdrop={true}
    >
      {renderEditor()}
    </Modal>,
    modalsRoot
  )
  // return (
  //   <Dialog open={dialog} fullScreen={isMobile} maxWidth={dialogMaxWidth}>
  //     {renderEditor()}
  //   </Dialog>
  // )
}

export default Editor
